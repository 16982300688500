<script>
  export default {
    data() {
      return {
        history: [],
      };
    },

    watch: {
      history() {
        this.syncHistoryToLocalStorage();
      }
    },

    methods: {
      add(item) {
        if (!item) {
          return;
        }

        this.history = this.history.filter(i => i != item);
        this.history.unshift(item);
        this.history = this.history.slice(0, 20);
      },

      syncHistoryFromLocalStorage() {
        let history = localStorage.getItem("qr") || "[]";
        history = JSON.parse(history);

        if (Array.isArray(history)) {
          this.history = history;
        }
      },

      syncHistoryToLocalStorage() {
        localStorage.setItem("qr", JSON.stringify(this.history));
      }
    },

    beforeMount() {
      this.syncHistoryFromLocalStorage();
    }
  }
</script>

<template>
  <div class="text-center" v-if="history.length > 0">
    <v-icon x-large>
      mdi-history
    </v-icon>

    <div
      v-for="(item, index) in history" :key="index"
      class="text-center my-2"
    >
      <v-btn outlined @click="$emit('restore', item)">
        {{ item }}
      </v-btn>
    </div>

    <v-btn icon color="error" @click="history = []">
      <v-icon>mdi-delete-outline</v-icon>
    </v-btn>
  </div>
</template>
