<script>
  export default {
    data() {
      return {
        small: [],
        big: [],
      };
    },

    computed: {
      input() {
        return []
          .concat(this.big)
          .concat(this.small)
          .filter(n => n.selected)
          .map(n => n.value);
      },
    },

    watch: {
      input() {
        this.$emit("input", this.input || []);
      }
    },

    methods: {
      reset() {
        this.small = [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10]
          .map(n => {
            return {
              value: n,
              selected: false,
            };
          });

        this.big = [25, 50, 75, 100]
          .map(n => {
            return {
              value: n,
              selected: false,
            }
          });
      },
    },

    beforeMount() {
      this.reset();
    }
  }
</script>

<template>
  <div>
    <div>
      <template v-for="(n, i) in big">
        <v-btn
          @click="n.selected = !n.selected"
          :key="'big' + i"
          class="ma-1"
          :color="n.selected ? 'primary' : 'default'"
        >
          {{ n.value }}
        </v-btn>
      </template>
    </div>

    <div>
      <template v-for="(n, i) in small">
        <v-btn
          @click="n.selected = !n.selected"
          :key="'small' + i"
          class="ma-1"
          :color="n.selected ? 'primary' : 'default'"
        >
          {{ n.value }}
        </v-btn>
      </template>
    </div>
  </div>
</template>
