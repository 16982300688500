<script>
  export default {
    components: {
      "hint": require("./Hint").default,
      "cell": require("./Cell").default,
    },

    props: {
      solution: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        cells: [],

        lastFillValue: true,
      };
    },

    computed: {
      formattedCells() {
        return this.cells;
      },

      gridWidth() {
        return Math.sqrt(this.formattedCells.length);
      },

      gridHeight() {
        return this.gridWidth;
      },

      rows() {
        return new Array(this.gridHeight)
          .fill("")
          .map((e, i) => {
            let start = i * this.gridWidth;
            let end = start + this.gridWidth;
            return this.formattedCells.slice(start, end);
          })
      },

      columns() {
        return new Array(this.gridWidth)
          .fill("")
          .map((e, i) => {
            let cells = this.formattedCells.filter((c, cellIndex) => {
              return (cellIndex - i) % this.gridWidth == 0;
            });

            return cells;
          })
      },

      solved() {
        return this.cells.filter(c => c.filled != c.solution).length == 0;
      },
    },

    watch: {
      solved() {
        if (this.solved) {
          this.cells
            .filter(c => c.blocked)
            .reduce((p, c) => {
              return p
                .then(() => {
                  this.$set(c, "blocked", false);
                })
                .then(() => new Promise(r => setTimeout(r, 50)));
            }, Promise.resolve());
        }
      }
    },

    beforeMount() {
      this.cells = (this.solution || []).map(c => {
        return {
          filled: false,
          solution: c
        };
      });
    },
  };
</script>

<template>
  <div style="position: relative;">
    <div
      :style="{
        display: 'grid',
        'grid-template-columns': `repeat(${gridWidth + 1}, 1fr)`,
        'grid-gap': '1px',
        'max-width': 'min(70vh, 100%)',
        'margin': '0 auto',
        'transition': 'all 0.2s',
      }"
    >
      <v-responsive
        :aspect-ratio="1"
        style="min-width: 2em;"
      ></v-responsive>

      <hint
        v-for="(c, i) in columns"
        :key="'c' + i"
        :cells="c"
        column
      >
      </hint>

      <template
        v-for="(c, i) in cells"
      >
        <hint
          v-if="i % gridWidth == 0"
          :key="'r' + i"
          :cells="rows[i / gridWidth]"
        >
        </hint>

        <cell
          :filled.sync="c.filled"
          :blocked.sync="c.blocked"
          :key="i"
          v-on:update:filled="lastFillValue = $event"
          v-on:update:blocked="lastFillValue = $event"
          :prefer="lastFillValue"
        ></cell>
      </template>
    </div>

    <div v-if="solved" class="text-center text-h5 ma-2"> 
      🎉 Solved 🎉
    </div>

    <v-overlay absolute :value="solved" opacity="0.2"></v-overlay>
  </div>
</template>
