<script>
  export default {
  };
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="10" rounded>
          <v-fade-transition mode="out-in">
            <router-view :key="$router.currentRoute.fullPath"></router-view>
          </v-fade-transition>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
