<script>
  /*
    things that might be cool
      - make the hands rotate around the edges instead of the center (always pointing to the center)
      - digital clock
      - "flip" clock
      - rewind / fast forward / pause
  */
  import moment from 'moment';

  export default {
    components: {
      analog: require("./analog").default,
      digital: require("./digital").default,
    },

    data() {
      return {
        timeData: {},
        interval: undefined,
      };
    },

    methods: {
      updateMoment() {
        let m = new moment();

        let timeData = {
          hour: m.format("h"),
          minute: m.format("m"),
          second: m.format("s"),
        };

        timeData = {
          ...timeData,
          hourDeg: Number(timeData.hour) / 12 * 360,
          minuteDeg: Number(timeData.minute) / 60 * 360,
          secondDeg: Number(timeData.second) / 60 * 360
        };

        this.$set(this, "timeData", timeData);
      },
    },

    beforeMount() {
      this.updateMoment();
      this.interval = setInterval(this.updateMoment, 1000);
    },

    destroyed() {
      clearInterval(this.interval);
    }
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6"  class="d-flex justify-center">
        <analog v-bind="timeData"></analog>
      </v-col>

      <v-col cols="12" md="6" class="d-flex justify-center align-center">
        <digital v-bind="timeData"></digital>
      </v-col>
    </v-row>
  </v-container>
</template>
