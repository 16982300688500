<script>
  export default {
    components: {
      "code-peg": require("./code-peg").default,
    },

    props: {
      value: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        input: 1,
      };
    },

    watch: {
      value: {
        handler() {
          this.input = this.value;
        },
        immediate: true,
      },

      input() {
        this.$emit("input", this.input);
      },
    },

    methods: {
      onKeyDown(e) {
        if (["ArrowUp", "w"].indexOf(e.key) > -1) {
          this.input--;

          if (this.input < 1) {
            this.input = 6;
          }
        }

        if (["ArrowDown", "s"].indexOf(e.key) > -1) {
          this.input++;

          if (this.input > 6) {
            this.input = 1;
          }
        }
      },
    },

    beforeMount() {
      document.addEventListener("keydown", this.onKeyDown);
    },

    destroyed() {
      document.removeEventListener("keydown", this.onKeyDown);
    },
  }
</script>

<template>
  <div
    class="d-flex flex-column"
    style="border-radius: 2px; box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.5);"
  >
    <div
      v-for="i in 6"
      :key="i"
      class="peg"
      :class="i == input ? 'peg--selected' : ''"
      @click="input = i"
    >
      <code-peg
        :value="i"
        disabled
        prominent
      ></code-peg>
    </div>
  </div>
</template>

<style scoped>
  .peg {
    cursor: pointer;
  }

  .peg--selected {
  }

  .peg > * {
    transform: scale(0.3);

    transition: 0.3s all;
  }

  .peg--selected > * {
    transform: scale(0.7);
  }
</style>
