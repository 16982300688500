import Phaser from 'phaser';

const Ship = require("./ship.js").default;
const Explosion = require("./explosion.js").default;
//const Star = require("./star.js").default;

const WIDTH = 800;
const HEIGHT = 600;

let shipGroup;
let laserGroup;

function create() {
  //create_addStars.call(this);

  shipGroup = this.physics.add.group();
  laserGroup = this.physics.add.group();

  this.physics.add.overlap(shipGroup, laserGroup, (ship, laser) => {
    if (
      ship == laser.getShip()
      || !ship.isEnabled()
    ) {
      return;
    }

    laser.destroy();

    new Explosion(this, {
      x: ship.x,
      y: ship.y,
      color: laser.strokeColor.toString(16)
    });

    ship.explode();

    laser.getShip().increaseScore();
  });

  /*
  function create_addStars() {
    [
      0.2,
      0.3,
    ]
      .forEach(alpha => {
        new Array(75)
          .fill("")
          .map(() => {
            return new Star(this, {
              alpha: alpha
            }).setRandomPosition();
          });
      });
  }
  */
}

function update() {
  this.children.list
    .forEach(loopObjectAroundWorldBounds);

  function loopObjectAroundWorldBounds(object) {
    if (object.x < 0) {
      object.x = WIDTH;
    }

    if (object.x > WIDTH) {
      object.x = 0;
    }

    if (object.y < 0) {
      object.y = HEIGHT;
    }

    if (object.y > HEIGHT) {
      object.y = 0;
    }
  }
}

export default class Asteroids extends Phaser.Game {
  #ships
  #controls

  constructor(config) {
    config = {
      type: Phaser.AUTO,
      width: WIDTH,
      height: HEIGHT,
      physics: {
        default: "arcade",
        arcade: {
          fps: 60,
          gravity: { y: 0 },
        },
      },
      scene: {
        create() {
          create.apply(this, arguments);
          config.create && config.create(this);
        },

        update() {
          update.apply(this, arguments);

          let controls = this.game.getControls();
          Object.keys(controls)
            .forEach(id => {
              this.game.applyShipControls(id, controls[id]);
            });

          config.update && config.update(this);
        },

      },
      ...config
    };

    super(config);

    this.#ships = {};
    this.#controls = {};
  }

  addShip(id, config) {
    let ship = new Ship(this.scene.scenes[0], config);
    this.#ships[id] = ship;
    shipGroup.add(ship);
  }

  setControls(id, controls) {
    this.#controls[id] = controls;
  }

  getControls() {
    return this.#controls;
  }

  applyShipControls(id, rawControls) {
    let ship = this.#ships[id];

    ship.enable();

    let controls = {
      x: 0,
      y: 0,
      a: false,
      b: false,
      ...rawControls
    };

    if (Math.abs(controls.x) > 0.4) {
      ship.body.setAngularVelocity(300 * controls.x);
    } else {
      ship.body.setAngularVelocity(0);
    }

    if (Math.abs(controls.y) > 0.4) {
      ship.scene.physics.velocityFromRotation(ship.rotation, -200 * controls.y, ship.body.acceleration);
    } else {
      ship.body.setAcceleration(0);
    }

    if (controls.b) {
      let laser = ship.shoot();
      laser && laserGroup.add(laser);
    }
  }
}
