<script>
  export default {
    props: [
      "gridSize",
      "canvasSize",
      "circles",
      "color",
      "inputSummary"
    ],

    computed: {
      styles() {
        return {
          display: "grid",
          gridTemplateColumns: `repeat(${Math.ceil(this.canvasSize / this.gridSize)}, 1fr)`,
          width: this.canvasSize + "px",
          height: this.canvasSize + "px",
        };
      },

      inputSummaryArray() {
        return Object.keys(this.inputSummary || {}).map(k => {
          return this.inputSummary[k];
        })
      }
    }
  }
</script>

<template>
  <div
    v-if="Boolean(inputSummary)"
    style="background: #000;"
    :style="styles"
    :class="{
      circles,
      grayscale: !color,
    }"
  >
    <div
      v-for="(c, i) in inputSummaryArray"
      :key="i"
      class="cell"
      :style="{
        background: `rgb(${c.red}, ${c.green}, ${c.blue})`,
        transform: `scale(${c.lumins / 255})`,
      }"
      v-anime="{
        duration: $anime.random(1000, 2000),
        translateX: [0, $anime.random(-10, 10)],
        translateY: [0, $anime.random(-10, 10)],
        loop: true,
      }"
    ></div>

  </div>
</template>

<style scoped>
  .circles .cell {
    border-radius: 100%
  }

  .grayscale {
    filter: grayscale(1);
  }
</style>
