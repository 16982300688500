<script>
  export default {
    extends: require("./User").default,

    components: {
      "analyze-set": require("./AnalyzeSet").default,
    },

    props: {
      correctSets: {
        type: Array,
        default() {
          return [];
        }
      },

      incorrectSets: {
        type: Array,
        default() {
          return [];
        }
      },

      locking: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        showNewCorrect: false,
        showNewIncorrect: false,

        historyDialog: false,
        tab: 0,
      };
    },

    computed: {
      loading() {
        if (this.locking) {
          return "primary";
        }
        return false;
      },

      numberOfCorrectSets() {
        return this.correctSets.length;
      },

      numberOfIncorrectSets() {
        return this.incorrectSets.length;
      },
    },

    watch: {
      numberOfCorrectSets() {
        this.showNewCorrect = true;
      },

      numberOfIncorrectSets() {
        this.showNewIncorrect = true;
      },

      showNewCorrect() {
        if (this.showNewCorrect) {
          this.$emit("correct", {username: this.username});
          setTimeout(() => {
            this.showNewCorrect = false;
          }, 1500);
        }
      },

      showNewIncorrect() {
        if (this.showNewIncorrect) {
          this.$emit("incorrect", {username: this.username});
          setTimeout(() => {
            this.showNewIncorrect = false;
          }, 1500);
        }
      }
    }
  };
</script>

<template>
  <v-card width="250" color="grey darken-4" :loading="loading">
    <v-card-title>
      <span :class="isMe ? 'me' : ''">
        {{ username || _id }}
      </span>

      <v-spacer></v-spacer>

      <v-fab-transition>
        <div v-if="showNewCorrect">
          <v-icon color="success">mdi-plus</v-icon>
          <v-icon color="success">mdi-numeric-1-circle</v-icon>
        </div>
      </v-fab-transition>

      <v-fab-transition>
        <div v-if="showNewIncorrect">
          <v-icon color="error">mdi-minus</v-icon>
          <v-icon color="error">mdi-numeric-1-circle</v-icon>
        </div>
      </v-fab-transition>
    </v-card-title>

    <v-card-subtitle class="d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <span class="headline mr-3 grey--text text--lighten-4">{{ numberOfCorrectSets - numberOfIncorrectSets }}</span>
        <v-icon color="success lighten-2" small>mdi-plus</v-icon>
        <span class="success--text text--lighten-2">{{ numberOfCorrectSets }}</span>

        <v-divider vertical class="my-3 mx-2"></v-divider>

        <v-icon color="error lighten-2" small>mdi-minus</v-icon>
        <span class="error--text text--lighten-2">{{ numberOfIncorrectSets }}</span>
      </div>

      <v-btn icon v-on:click="historyDialog = true">
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </v-card-subtitle>

    <v-dialog v-model="historyDialog" width="600" scrollable>
      <v-card light>
        <v-card-title>
          {{ username }}
        </v-card-title>

        <v-card-text style="position: relative;">
          <v-tabs v-model="tab">
            <v-tab>Sets ({{ correctSets.length }})</v-tab>
            <v-tab>Incorrect ({{ incorrectSets.length }})</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" light>
            <v-tab-item>
              <div v-if="correctSets.length == 0">- none -</div>

              <v-card
                v-for="s in correctSets.map(e => e).reverse()"
                :key="s.created" 
                class="ma-3 grey lighten-3"
                outlined
                elevation="2"
              >
                <analyze-set :cards="s.cards" class="ma-3"></analyze-set>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <div v-if="incorrectSets.length == 0">- none -</div>

              <v-card
                v-for="s in incorrectSets.map(e => e).reverse()"
                :key="s.created" 
                class="ma-3 grey lighten-3"
                outlined
                elevation="2"
              >
                <template v-if="s.cards.length == 3">
                  <analyze-set :cards="s.cards" class="ma-3"></analyze-set>
                </template>

                <div class="text-center my-3" v-else>
                  <v-icon large color="error">mdi-timer</v-icon>
                  <span class="error--text">
                    Time expired
                  </span>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="historyDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card> 
</template>
