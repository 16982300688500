import Phaser from 'phaser';

export default class Explosion extends Phaser.GameObjects.Ellipse {
  #ttl

  constructor(scene, options) {
    let config = {
      x: 0,
      y: 0,
      width: 10,
      height: 10,
      color: "#ffff00",
      ...options
    };

    super(
      scene,
      config.x,
      config.y,
      config.width,
      config.height,
      parseInt(config.color.replace(/^#/, ''), 16)
    );

    scene.add.existing(this);

    this.setAlpha(0);

    this.animate();

    this.#ttl = this.scene.time.now + 1000;
  }

  preUpdate() {
    if (this.scene.time.now > this.#ttl) {
      this.destroy();
    }
  }

  animate() {
    let timeline = this.scene.tweens.createTimeline();

    timeline.add({
      targets: this,
      alpha: { value: 1, duration: 200 },
      scale: { value: 5, duration: 200 },
    });

    timeline.add({
      targets: this,
      alpha: { value: 0, duration: 500 },
      scale: { value: 3, duration: 200 },
    });

    timeline.play();
  }
}
