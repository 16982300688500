<script>
  export default {
    props: {
      text: {
        type: String,
        required: true
      },

      highlight: {},
    },

    computed: {
      formattedLetters() {
        let highlight = this.highlight;

        let result = this.text
          .split("")
          .map((l, i, a) => {
            let index = highlight.indexOf(l);

            if (index > -1) {
              highlight = highlight.replace(l, " ");
            }

            return {
              letter: l,
              highlight: index > -1,
              key: i,
              sort: index > -1 ? index : a.length + i,
            };
          })
          .sort((a, b) => a.sort - b.sort);

        let atLeast1LetterHighlighted = Boolean(result.find(l => l.highlight));

        return result
          .map(l => {
            let classes = [];

            if (atLeast1LetterHighlighted) {
              if (l.highlight) {
                classes.push('primary--text grow');
              } else {
                classes.push('secondary--text shrink');
              }
            }

            return {
              ...l,
              class: classes,
            };
          });
      },
    }
  }
</script>

<template>
  <div>
    <v-slide-x-transition
      group
    >
      <div
        v-for="l in formattedLetters"
        :key="l.key"
        :class="l.class"
        class="letter"
      >
        {{ l.letter.toUpperCase() }}
      </div>
    </v-slide-x-transition>
  </div>
</template>

<style scoped>
  .letter {
    display: inline-block;
    transition: all 0.5s ease;
    padding: 0 4px;
  }

  .grow {
    transform: scale(1.2);
  }

  .shrink {
    transform: scale(0.8);
  }
</style>
