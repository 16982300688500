<script>
  export default {
    props: {
      filled: {
        type: Boolean,
        default: false,
      },

      blocked: {
        type: Boolean,
        default: false,
      },

      prefer: {
        type: Boolean
      },
    },

    computed: {
      color() {
        if (this.filled) {
          return "blue";
        }

        if (this.blocked) {
          return "grey";
        }

        return "white";
      }
    },

    watch: {
      filled() {
        if (this.filled && this.blocked) {
          this.$emit("update:blocked", false);
        }
      },

      blocked() {
        if (this.filled && this.blocked) {
          this.$emit("update:filled", false);
        }
      }
    },

    methods: {
      onClick() {
        this.$emit("update:filled", !this.filled);
      },
    },
  };
</script>

<template>
  <v-responsive
    :aspect-ratio="1"
    class="cell"
    v-on:click="onClick"
  >
    <v-card
      :color="color"
      class="fill-height brown--text d-flex align-center justify-center"
      tile
    >
    </v-card>
  </v-responsive>
</template>

<style scoped>
  .cell {
    cursor: pointer;
    user-select: none;
  }

  .cell * {
    transition: background 0.2s;
  }
</style>
