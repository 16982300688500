<script>
  export default {
    components: {
      fn: require("./fn").default,
    },

    props: {
      inputs: {
        type: Array,
      },

      outputs: {
        type: Array,
      }
    },

    data() {
      return {
        tabIndex: 0,
        presets: [
          {
            label: "Append Comma",
            functions: [
              {
                fnString: `i => i.replace(/$/gm, m => "," + m)`,
              },
            ]
          },
          {
            label: "Single Quotes + Comma",
            functions: [
              {
                fnString: `i => i.replace(/^/gm, m => "'" + m).replace(/$/gm, m => "'," + m)`,
              },
            ]
          },
          {
            label: "Uppercase",
            functions: [
              {
                fnString: `i => i.toUpperCase()`,
              },
            ]
          },
          {
            label: "Lowercase",
            functions: [
              {
                fnString: `i => i.toLowerCase()`,
              },
            ]
          },
          // TODO: some option for a custom function?
        ]
      };
    },

    watch: {
      tabIndex: {
        handler() {
          this.$emit("input", this.presets[this.tabIndex].functions);
        },
        immediate: true
      }
    }
  };
</script>

<template>
  <v-tabs 
    v-model="tabIndex"
    color="green"
    vertical
  >
    <v-tab
      v-for="(p, i) in presets"
      :key="i"
      active-class="grey lighten-5"
    >
      {{ p.label }}
    </v-tab>

    <v-tabs-items v-model="tabIndex" class="grey lighten-5" light>
      <v-tab-item
        v-for="(p, i) in presets"
        :key="'vti' + i"
      >
        <p class="pa-3 mb-0">
          <fn
            v-for="(fn, i) in p.functions"
            :key="i + JSON.stringify(fn)"
            v-model="fn.fnString"
            v-bind="{
              label: p.label,
              i: (inputs || [])[i],
              o: (outputs || [])[i]
            }"
          ></fn>
        </p>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
