<script>
  export default {
    data() {
      return {
        username: "",
      };
    },

    computed: {
      loading() {
        return !this.$store.state.me._id;
      },

      saved() {
        return Object.keys(this.$data)
          .filter(k => this.$store.state.me.hasOwnProperty(k))
          .filter(k => this[k] != this.$store.state.me[k])
          .length == 0;
      },

      validationError() {
        if (this.username.trim().length == 0 || this.username.trim().length > 20) {
          return "Invalid username";
        }

        return "";
      }
    },

    watch: {
      loading: {
        handler() {
          if (!this.loading) {
            Object.keys(this.$store.state.me)
              .filter(k => this.$data.hasOwnProperty(k))
              .forEach(k => {
                this.$set(this, k, this.$store.state.me[k]);
              });
          }
        },
        immediate: true
      }
    },

    methods: {
      save() {
        this.$store.state.socket.emit("me", {
          username: this.username
        });
      }
    },
  };
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="8" offset="2">
        <v-sheet class="pa-5" elevation="4" rounded>
          <template v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </template>

          <v-form v-on:submit.prevent="save" v-else>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="username"
                    :counter="20"
                    :rules="[
                      v => !!v || 'Required'
                    ]"
                    label="Username"
                    maxlength="20"
                    outlined
                    required
                  ></v-text-field>

                  <div class="body-2 text-center">
                    Your username may be visible to other people interacting with this website.
                  </div>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-fade-transition mode="out-in">
                    <div :key="saved + '_' + validationError">
                      <div v-if="saved" class="success--text">
                        <v-icon color="success">mdi-check-circle</v-icon>
                        Username saved
                      </div>

                      <div v-else-if="validationError" class="error--text">
                        <v-icon color="error">mdi-close-circle-outline</v-icon>
                        {{ validationError }}
                      </div>

                      <v-btn
                        type="submit"
                        color="primary"
                        x-large
                        v-else
                      >
                        Save
                      </v-btn>
                    </div>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
