<script>
  export default {
  }
</script>

<template>
  <v-container class="fill-height" fluid>
    <v-row class="fill-height">
      <v-col class="d-flex flex-column justify-space-around text-center">
        <div class="text-h5">
          Hello <router-link to="/me">{{ $store.state.me.username || 'stranger' }}</router-link>! 👋
        </div>

        <div>
          How about a game of
          <!-- TODO: is this the right way to link to a route? seems lame -->
          <v-btn
            outlined
            text
            color="primary"
            @click="$router.push({path: '/set/'})"
          >
            set
          </v-btn>

          or

          <v-btn
            outlined
            text
            color="primary"
            @click="$router.push({path: '/wordle/'})"
          >
            wordle
          </v-btn>
          ?
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
