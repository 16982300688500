<script>
  export default {
    props: {
      value: {},

      disabled: {
        type: Boolean,
        default: false
      },

      peg: {
        type: Number,
      },

      secret: {
        type: Boolean,
        default: false,
      },

      prominent: {
        type: Boolean,
        default: false
      },
    },

    data() {
      return {
        input: undefined,
      };
    },

    computed: {
      classes() {
        let classes = [];

        if (!this.secret && this.input) {
          classes.push({
            1: "red",
            2: "green",
            3: "blue",
            4: "yellow",
            5: "brown",
            6: "purple",
          }[this.input]);
        }

        if (this.disabled) {
          classes.push("peg--disabled");
        }

        if (this.prominent) {
          classes.push("peg--prominent");
        }

        return classes;
      }
    },

    watch: {
      value: {
        handler() {
          this.input = this.value;
        },
        immediate: true
      },

      input() {
        this.$emit("input", this.input);
      }
    },

    methods: {
      onClick() {
        if (this.disabled) {
          return;
        }

        if (this.peg != undefined) {
          if (this.input == this.peg) {
            this.input = undefined;
            return;
          }

          this.input = this.peg;
          return;
        }

        this.input = {
          undefined: 1,
          1: 2,
          2: 3,
          3: 4,
          4: 5,
          5: 6,
          6: undefined,
        }[this.input];
      }
    }
  }
</script>

<template>
  <v-scale-transition mode="out-in" :duration="50" origin="center center">
    <div
      class="peg"
      :class="classes"
      @click="onClick"
      :key="'_' + input + secret + prominent"
    >
      <template v-if="input == undefined">
        <div class="peg__hole"></div>
      </template>
    </div>
  </v-scale-transition>
</template>

<style scoped>
  .peg {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 40%;

    aspect-ratio: 1;
    width: 36px;

    cursor: pointer;

    transition: 0.2s all;
  }

  .peg--disabled {
    transform: scale(0.8);
    cursor: default;
  }

  .peg--prominent {
    transform: scale(1);
    box-shadow: 1px 1px 3px 1px #000;
    border-radius: 40%;
  }

  .peg__hole {
    border-radius: 40%;
    background: #fff;
    width: 50%;
    height: 50%;
    opacity: 0.2;
    position: absolute;
  }

  .peg--disabled .peg__hole {
    opacity: 0.05;
    transform: scale(0.5);
  }
</style>
