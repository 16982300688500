<script>
  /*
    TODO
    - modes
      - random winner (chwazi clone): basically what you've got now
        - betting
          - bet on a color to win (it may or may not be the color of your finger)
          - play continues for some number of rounds? or is each player betting on a color before each round?
          - points are awarded to players that guessed the correct color
      - rhythm games
        - NOTE: these consist of repeated taps. will need to keep track of finger regions
        - mimic a metronome simultaneously. most accurate wins.
          - maybe you take turns tapping to a beat...?
            - could increase tempo over time to make it more difficult
            - keep track of time deltas throughout the game - too slow or too fast counts against you
            - game over after it takes more than twice as long then expected for someone to tap?
              - seems very frantic towards the end...probs a good thing?
        - "guitar hero" type of thing?
          - this would really only work with a small, known number of players (~2)
        - some sort of "simon says" or "battle mode"
          - one player creates a pattern, other players recreate it. creator passes poker button style
          - this would likely require at least 1 round per player having the button
      - air hockey / pong
      - maze
        - connect nodes (think "android lock pattern", but bigger) - fastest wins
          - this could be as simple as ~4 nodes
            - would this make it feel like less of a "maze" or more like "simon says (w/ patterns)"?
        - pattern could be shown...or hidden and slowly revealed?
          - how could you reveal it?
            - maybe next node/edge is revealed after delay...
              ...promote guessing the next node? ...
              ...probs with some penalty for guessing wrong...
              ...but not enough of a penalty to discourage guessing...
              ...maybe reset progress to beginning for mistakes? <<<
    - sounds
    - toggle sound
    - toggle vibration
  */

  import COLORS from 'vuetify/lib/util/colors'

  export default {
    components: {
      touch: require("./touch").default,
      settings: require("./settings").default,
    },

    data() {
      return {
        mode: "random",
        config: {},

        colors: Object.keys(COLORS)
          .filter(k => k != "shades")
          .map(k => {
            return COLORS[k].base;
          }),

        touches: {},
        winner: undefined,
      };
    },

    computed: {
      formattedTouches() {
        let result = {};

        Object.keys(this.touches)
          .forEach(k => {
            result[k] = {
              ...this.touches[k],
              winner: k == this.winner,
            };
          })

        return result;
      }
    },

    methods: {
      onTouchEvent(ev) {
        let touches = {};

        for (let i = 0; i < ev.touches.length; i++) {
          let touch = ev.touches[i];
          let id = touch.identifier;
          let hex = this.touches[id]?.hex || this.colors[Math.floor(Math.random() * this.colors.length)];

          touches[id] = {
            x: touch.clientX,
            y: touch.clientY,
            hex: hex
          };
        }

        this.$set(this, 'touches', touches);
      },

      onScroll() {
        window.scrollTo(0, 0);
      },
    },

    mounted() {
      window.addEventListener("scroll", this.onScroll);
    },

    destroyed() {
      window.removeEventListener("scroll", this.onScroll);
    }
  }
</script>

<template>
  <div>
    <template v-if="$route.name == 'fingers'">
      <settings></settings>

      <template v-if="!$store.state.isMobile">
        <div class="text-center warning--text pa-5">
          <v-icon color="warning">mdi-alert</v-icon>
          You will need to use a device that supports multi-touch
        </div>
      </template>
    </template>

    <template v-else>
      <v-sheet
        @touchstart="onTouchEvent"
        @touchmove="onTouchEvent"
        @touchend="onTouchEvent"
        @touchcancel="onTouchEvent"
        style="height: calc(100vh - 50px); user-select: none; touch-action: none;"
      >
        <template v-for="id in Object.keys(touches)">
          <touch
            v-bind="formattedTouches[id]"
            :id="id"
            :key="id"
          ></touch>
        </template>

        <router-view
          :value="formattedTouches"
          v-bind="config[mode]"
          @update:winner="winner = $event"
        ></router-view>
      </v-sheet>
    </template>
  </div>
</template>
