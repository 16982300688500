<script>
  export default {
    props: {
      value: {
        type: String,
        required: true
      }
    },

    computed: {
      chartData() {
        let chars = {};

        this.value
          .split("")
          .forEach(c => {
            chars[c] = (chars[c] || 0) + 1;
          });

        return Object.keys(chars)
          .map(c => {
            return {
              label: c,
              value: chars[c]
            };
          })
          .sort((a, b) => {
            if (a.value < b.value) {
              return 1;
            }

            if (a.value > b.value) {
              return -1;
            }

            return 0;
          });
      },
    },
  };
</script>

<template>
  <div>
    <div
      style="display: grid;"
      :style="'grid-template-columns: repeat(' + chartData.length + ', 1fr)'"
    >
      <div
        class="text-center"
        style="border: 1px solid blue;"
        v-for="(b, i) in chartData"
        :key="i"
      >
        {{ b.label }}: {{ b.value }}
      </div>
    </div>
  </div>
</template>
