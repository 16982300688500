<script>
  export default {
  }
</script>

<template>
  <div class="face grey--text text--darken-3">
    <div class="number twelve">
      12
    </div>

    <div class="number three">
      3
    </div>

    <div class="number six">
      6
    </div>

    <div class="number nine">
      9
    </div>
  </div>
</template>

<style scoped>
  .face {
    width: 100%;
    height: 100%;
    position: absolute;
    background: white;
    border-radius: 100%;
    font-size: 3vw;
  }

  .number {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    padding: 2%;
  }

  .number.twelve {
    justify-content: center;
  }

  .number.three {
    justify-content: end;
    align-items: center;
  }

  .number.six {
    justify-content: center;
    align-items: end;
  }

  .number.nine {
    align-items: center;
  }
</style>
