<script>
  const Icons = require("./meta.json");

  export default {
    props: {
      value: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        input: "",

        icons: Icons.map(i => "mdi-" + i.name),
      };
    },

    watch: {
      value: {
        handler() {
          this.input = this.value;
        },
        immediate: true
      },

      input() {
        this.$emit("input", this.input);
      }
    },
  }
</script>

<template>
  <v-autocomplete
    v-model="input"
    :items="icons"
    label="Icon"
    outlined
  ></v-autocomplete>
</template>
