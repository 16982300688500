<script>
  export default {
    props: {
      keyColors: {
        type: Object,
        default() {
          return {};
        }
      },
    },

    data() {
      return {
        keys: "QWERTYUIOPASDFGHJKLZXCVBNM".split(""),
      };
    },

    methods: {
      attention(key) {
        if (key.length == 1) {
          key = key.toUpperCase();
        }

        let target = this.$refs['key:' + key];

        if (!target || target.length == 0) {
          return;
        }

        target && this.$anime
          .timeline({
            targets: [target],
            duration: 50,
            easing: 'linear',
            delay: 0,
          })
          .add({
            scale: 0.7,
          })
          .add({
            scale: 1,
            delay: 5,
          });
      },

      onKeydown(e) {
        this.$emit("key:down", e.key);

        this.attention(e.key);
      },

    },

    mounted() {
      window.addEventListener("keydown", this.onKeydown);
    },

    destroyed() {
      window.removeEventListener("keydown", this.onKeydown);
    },
  }
</script>

<template>
  <div class="d-flex justify-center">
    <div class="keyboard">
      <template v-for="(k, i) in keys">
        <div
          :key="k"
          :ref="'key:' + k"
        >
          <v-btn
            :color="keyColors[k] || 'grey'"
            class="px-2"
            block
            tile
            min-height="44px"
            @click="onKeydown({key: k})"
          >
            {{ k }}
          </v-btn>
        </div>

        <template v-if="i == 18">
          <div
            :key="i + 'backspace'"
            ref="key:Backspace"
          >
            <v-btn
              color="grey darken-2"
              class="px-2"
              block
              min-height="44px"
              @click="onKeydown({key: 'Backspace'})"
            >
              <v-icon>mdi-backspace-outline</v-icon>
            </v-btn>
          </div>
        </template>
      </template>

      <div ref="key:Enter" class="key--enter">
        <v-btn
          color="primary"
          class="px-2"
          block
          min-height="44px"
          @click="onKeydown({key: 'Enter'})"
        >
          <v-icon>mdi-keyboard-return</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .keyboard {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 2px 2px;
  }

  .key--enter {
    grid-column: span 3;
  }
</style>
