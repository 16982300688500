<script>
  export default {
    data() {
      return {
        progress: 0,
        interval: undefined,
      };
    },

    watch: {
      progress() {
        if (this.progress == 100) {
          this.$emit("confirm");
        }
      }
    },

    methods: {
      onPointerEvent(e) {
        if (e.type == "pointerout" || e.type =="pointerup") {
          this.interval = clearInterval(this.interval);
          this.progress = 0;

          return;
        }

        if (this.interval) {
          return;
        }

        if (e.buttons == 1) {
          this.interval = setInterval(() => {
            this.progress = Math.min(this.progress + 1, 100);
          }, 30);
        }
      }
    }
  }
</script>

<template>
  <v-btn 
    class="progress-button"
    @pointerdown="onPointerEvent"
    @pointerover="onPointerEvent"
    @pointerup="onPointerEvent"
    @pointerout="onPointerEvent"
  >
    <v-fade-transition mode="out-in">
      <span
        :key="progress == 0 || progress == 100"
        class="mr-2"
        style="pointer-events: none;"
      >
        <template v-if="progress == 0">
          <v-icon>mdi-gesture-tap-hold</v-icon>
        </template>

        <template v-else-if="progress == 100">
          <v-icon>mdi-check</v-icon>
        </template>

        <template v-else>
          <v-progress-circular
            :value="progress"
            :size="24"
            :width="3"
            :rotate="-90"
          ></v-progress-circular>
        </template>
      </span>
    </v-fade-transition>
    

    <slot></slot>
  </v-btn>
</template>

<style>
  .progress-button .v-progress-circular__overlay {
    transition: none;
  }
</style>
