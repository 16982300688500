<script>
  export default {
    props: ["serverTime", "expirationTime"],

    data() {
      return {
        secondsRemaining: 10,
        interval: undefined,
      };
    },

    computed: {
      color() {
        if (this.secondsRemaining <= 3) {
          return "error";
        }
        return "success";
      }
    },

    beforeMount() {
      this.secondsRemaining = Math.floor((this.expirationTime - this.serverTime) / 1000);

      this.interval = setInterval(() => {
        this.secondsRemaining = Math.max(this.secondsRemaining - 1, 0);
      }, 1000);
    },

    destroyed() {
      clearInterval(this.interval);
    }
  };
</script>

<template>
  <v-progress-circular
    :value="secondsRemaining * 10"
    :color="color"
  >
    {{ secondsRemaining }}
  </v-progress-circular>
</template>
