<script>
  export default {
    components: {
      "text-shadow": require("./text-shadow").default,
    },

    props: {
      value: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        input: []
      };
    },

    watch: {
      value: {
        handler() {
          this.input = JSON.parse(JSON.stringify(this.value));
        },
        immediate: true
      },

      input: {
        handler() {
          if (JSON.stringify(this.input) == JSON.stringify(this.value)) {
            return;
          }

          this.$emit("input", JSON.parse(JSON.stringify(this.input)));
        },
        immediate: true,
        deep: true
      },
    },
  }
</script>

<template>
  <div>
    <text-shadow
      v-for="(ts, i) in input"
      :key="i"
      v-model="input[i]"
      @delete="input.splice(i, 1)"
    ></text-shadow>

    <div class="text-center">
      <v-btn
        icon
        outlined
        color="primary"
        @click="input.push('')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>
