<script>
  export default {
    data() {
      return {
        mouseCoordinates: {},
        upperStyles: {},
        upperActive: false,

        x: 0,
        y: 0,
      };
    },

    computed: {
      inputX() {
        return Number((this.x / 50).toFixed(2));
      },

      inputY() {
        return Number((this.y / 50).toFixed(2));
      }
    },

    watch: {
      x() {
        this.updateUpperStyles();
      },

      y() {
        this.updateUpperStyles();
      },

      inputX() {
        this.emit();
      },

      inputY() {
        this.emit();
      },
    },

    methods: {
      updateUpperStyles() {
        this.upperStyles = {
          left: `calc(50% - 25px + ${this.x}px)`,
          top: `calc(50% - 25px + ${this.y}px)`,
        };
      },

      emit() {
        this.$emit("input", {
          x: this.inputX,
          y: this.inputY,
        });
      },

      onMousemove(e) {
        if (e.buttons != 1) {
          this.upperActive = false;
          this.x = 0;
          this.y = 0;

          return;
        }

        this.upperActive = true;

        let elBoundingClientRect = this.$el.getBoundingClientRect();

        let centerX = elBoundingClientRect.width / 2;
        let centerY = elBoundingClientRect.height / 2;

        this.mouseCoordinates = {
          x: e.clientX - elBoundingClientRect.x,
          y: e.clientY - elBoundingClientRect.y,
        };

        let left = this.mouseCoordinates.x - centerX;
        left = Math.min(left, 50);
        left = Math.max(left, -50);

        let top = this.mouseCoordinates.y - centerY;
        top = Math.min(top, 50);
        top = Math.max(top, -50);

        this.x = left;
        this.y = top;
      },

      onMouseup() {
        this.upperActive = false;

        this.x = 0;
        this.y = 0;
      }
    }
  };
</script>

<template>
  <div
    class="container"
    @pointerdown.prevent.stop="onMousemove"
    @pointermove.prevent.stop="onMousemove"
    @pointerup.prevent.stop="onMouseup"
    @dragstart.prevent.stop
    draggable="false"
  >
    <div class="lower"></div>
    <div
      class="upper"
      :class="upperActive ? 'upper--active' : ''"
      :style="upperStyles"
    ></div>
  </div>
</template>

<style scoped>
  .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }

  .lower {
    width: 100px;
    height: 100px;
    background: #424242;
    opacity: 0.5;
    border-radius: 100%;
  }

  .upper {
    width: 50px;
    height: 50px;
    background: #424242;
    opacity: 0.5;
    border-radius: 100%;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    transition: all 0.3s;
    box-shadow: 1px 1px 5px 3px black;
    transform: scale(1);
  }

  .upper--active {
    transition: none;
    transition: transform 0.2s;
    transform: scale(2.0);
  }
</style>
