var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[_c('div',{style:({
      display: 'grid',
      'grid-template-columns': ("repeat(" + (_vm.gridWidth + 1) + ", 1fr)"),
      'grid-gap': '1px',
      'max-width': 'min(70vh, 100%)',
      'margin': '0 auto',
      'transition': 'all 0.2s',
    })},[_c('v-responsive',{staticStyle:{"min-width":"2em"},attrs:{"aspect-ratio":1}}),_vm._l((_vm.columns),function(c,i){return _c('hint',{key:'c' + i,attrs:{"cells":c,"column":""}})}),_vm._l((_vm.cells),function(c,i){return [(i % _vm.gridWidth == 0)?_c('hint',{key:'r' + i,attrs:{"cells":_vm.rows[i / _vm.gridWidth]}}):_vm._e(),_c('cell',{key:i,attrs:{"filled":c.filled,"blocked":c.blocked,"prefer":_vm.lastFillValue},on:{"update:filled":[function($event){return _vm.$set(c, "filled", $event)},function($event){_vm.lastFillValue = $event}],"update:blocked":[function($event){return _vm.$set(c, "blocked", $event)},function($event){_vm.lastFillValue = $event}]}})]})],2),(_vm.solved)?_c('div',{staticClass:"text-center text-h5 ma-2"},[_vm._v(" 🎉 Solved 🎉 ")]):_vm._e(),_c('v-overlay',{attrs:{"absolute":"","value":_vm.solved,"opacity":"0.2"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }