<script>
  export default {
    props: ["value"],

    data() {
      return {
        x: "",
        y: "",
        blur: "",
        color: "",

        sliderConfig: {
          x: {
            min: -200,
            max: 200,
          },

          y: {
            min: -200,
            max: 200,
          },

          blur: {
            min: 0,
            max: 100
          }
        },
      };
    },

    computed: {
      input() {
        return `${this.x}px ${this.y}px ${this.blur}px ${this.color}`;
      }
    },

    watch: {
      value: {
        handler() {
          let parts = this.value.trim().split(" ");

          if (parts.length != 4) {
            return;
          }

          this.x = parseInt(parts[0]);
          this.y = parseInt(parts[1]);
          this.blur = parseInt(parts[2]);
          this.color = parts[3];
        },
        immediate: true
      },

      input() {
        this.$emit("input", this.input);
      },
    },
  }
</script>

<template>
  <v-card class="my-2" color="blue-grey darken-1">
    <v-card-text>
      <div class="d-flex">
        <v-slider
          v-for="key in ['x', 'y', 'blur']"
          v-model="$data[key]"
          :key="key"
          :label="key"
          v-bind="sliderConfig[key]"
          outlined
          dense
          hide-details
          class="mx-1"
        ></v-slider>
      </div>

      <v-color-picker
        v-model="color"
        label="Color"
        class="mt-2 align-self-center"
        hide-canvas
        hide-inputs
        width="100%"
      ></v-color-picker>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="grey"
        @click="$emit('delete')"
        text
      >
        <v-icon>mdi-delete</v-icon>
        Delete
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
