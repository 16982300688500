<script>
  export default {
    data() {
      return {
        opened: false,
      };
    },

    computed: {
      history() {
        return this.$store.state.wordle.history;
      },

      averageGuesses() {
        return this.history
          .map(h => h.guesses.length)
          .reduce((a, b) => a + b, 0) / this.history.length;
      },

      leastGuesses() {
        return this.history
          .map(h => h.guesses.length)
          .sort((a, b) => a - b)[0];
      },

      mostGuesses() {
        return this.history
          .map(h => h.guesses.length)
          .sort((a, b) => b - a)[0];
      }
    },
  }
</script>

<template>
  <div
    v-if="history.length > 0"
  >
    <v-btn
      @click="opened = true"
      color="secondary"
      icon
    >
      <v-icon>mdi-chart-bar</v-icon>
    </v-btn>

    <v-dialog
      v-model="opened"
      max-width="550px"
      scrollable
    >
      <v-card>
        <v-card-title>
          Stats
        </v-card-title>

        <v-card-text>
          <div class="d-flex justify-space-between">
            <div>
              Words Solved

              <div class="text-h5 text-center blue--text">
                {{ history.length.toLocaleString() }}
              </div>
            </div>

            <div>
              Average Guesses

              <div class="text-h5 text-center blue--text">
                {{ averageGuesses.toLocaleString() }}
              </div>
            </div>

            <div>
              Least Guesses

              <div class="text-h5 text-center blue--text">
                {{ leastGuesses.toLocaleString() }}
              </div>
            </div>

            <div>
              Most Guesses

              <div class="text-h5 text-center blue--text">
                {{ mostGuesses.toLocaleString() }}
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>

          <v-btn
            @click="opened = false"
            class="my-2"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
