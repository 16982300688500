<script>
  import User from "./User";
  import AnalyzeSet from "./AnalyzeSet";

  export default {
    filters: {
      ms(ms) {
        var seconds = ms / 1000;
        var minutes = Math.floor(seconds / 60);
        seconds = Math.floor(seconds % 60);

        seconds = ('00' + seconds).substr(-2);

        return `${minutes}:${seconds}`;
      },
    },

    components: {
      "user": User,
      "analyze-set": AnalyzeSet,
    },

    props: {
      users: {
        type: Array,
      },
    },

    computed: {
      timelineItems() {
        return this.users
          .map(u => {
            return (u.correctSets || []).map(s => {
              return {
                ...s,
                user: {_id: u._id}
              };
            })
          })
          .flat()
          .sort((a, b) => {
            return a.created - b.created;
          });
      }
    },
  }
</script>

<template>
  <v-timeline :dense="['xs', 'sm'].indexOf($vuetify.breakpoint.name) > -1">
    <v-timeline-item v-for="(item, i) in timelineItems" :key="i" small>
      <template v-slot:opposite>
        <div class="headline orange--text">
          <template v-if="i == 0">
            First Set!
          </template>

          <template v-else>
            <v-icon color="orange">mdi-timer-outline</v-icon>
            {{ Math.floor(item.created - timelineItems[i - 1].created) | ms }}
          </template>
        </div>
      </template>

      <div class="text-caption orange--text d-md-none">
        <template v-if="i == 0">
          First Set!
        </template>

        <template v-else>
          <v-icon color="orange" small>mdi-timer-outline</v-icon>
          {{ Math.floor(item.created - timelineItems[i - 1].created) | ms }}
        </template>
      </div>

      <v-card class="py-4 px-1" elevation="3" outlined>
        <div class="text-center title">
          <user v-bind="item.user"></user>
        </div>

        <analyze-set :cards="item.cards"></analyze-set>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>
