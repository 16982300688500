<script>
  export default {
    props: {
      size: {
        default: 100
      },

      fixedPosition: {
        type: Object
      }
    },

    computed: {
      styles() {
        let styles = {
          width: `${this.size}px`,
          'transition-duration': `${this.size / 300}s`,
        };

        if (this.fixedPosition) {
          let halfSize = this.size / 2;

          styles.position = "fixed";
          styles.top = `${this.fixedPosition.y - halfSize}px`;
          styles.left = `${this.fixedPosition.x - halfSize}px`;
        }

        return styles;
      }
    }
  }
</script>

<template>
  <div
    class="dot"
    :style="styles"
  ></div>
</template>

<style scoped>
  .dot {
    border-radius: 100%;
    aspect-ratio: 1/1;

    transition-property: all;
  }
</style>
