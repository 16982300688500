<script>
  export default {
    components: {
      "create-game": require("./CreateGame").default,
    },

    data() {
      return {
        code: "",
        creating: false,
      };
    },

    watch: {
      code() {
        if (this.code.toUpperCase() == this.code) {
          return;
        }

        this.code = this.code.toUpperCase();
      }
    },

    methods: {
      join() {
        if (this.code.trim().length != 4) {
          return;
        }

        this.$router.push({path: "/set/game/", query: {code: this.code}});
      },
    },
  };
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="8" offset="2" md="4" offset-md="4">
        <v-text-field
          v-model="code"
          :counter="4"
          maxlength="4"
          label="Enter Game Code"
          v-on:keyup.enter.prevent="join"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-fade-transition mode="out-in">
        <v-col cols="8" offset="2" md="4" offset-md="4" :key="Boolean(code.trim())">
          <template v-if="code.trim().length > 0">
            <v-btn
              color="success"
              elevation="2"
              :disabled="this.code.trim().length != 4"
              v-on:click="join"
              x-large
              block
            >
              Join Game
            </v-btn>
          </template>

          <template v-else>
            <v-row>
              <v-col class="text-center">
                OR
              </v-col>
            </v-row>

            <create-game></create-game>
          </template>
        </v-col>
      </v-fade-transition>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="8" offset="2" class="text-center">
        Looking for a recent game?
        <v-btn color="brown" @click="$router.push({path: '/history'})">
          <v-icon class="mr-1">mdi-history</v-icon>
          View Site History
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
