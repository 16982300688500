<script>
  /*
    TODO
      - play: randomly generated puzzles OR play puzzle from collection
      - save and share puzzles
        - share
          - code or direct link
      - link to instructions
      - hint colors (what does the switch game do?)
      - randomly fill grid
      - keep a collection of puzzles
        - submit saved puzzles to public collection
          - maybe allow for a name and/or a completion message?
        - "my puzzles"
      - better mobile touch & drag support
      - how to play
      - grid markers every 5th row
      - make sure there is only 1 solution to each puzzle
      - calculate difficulty?
      - use short codes instead of ids
  */
  export default {
    components: {
      "create": require("./Create").default,
      "play": require("./Play").default,
    },

    data() {
      return {
        tab: 1, // TODO

        id: "",
        solution: undefined,

        loading: false,
      };
    },

    methods: {
      load() {
        this.loading = true;

        this.$store.state.socket.on("picross:load", data => {
          this.$store.state.socket.off("picross:load");

          this.loading = false;

          this.solution = data.cells
            .split("")
            .map(b => b == 1);
        });

        this.$store.state.socket.emit("picross:load", {
          "_id": this.id
        });
      },
    }
  };
</script>

<template>
  <v-container class="picross">
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="10" class="py-3" rounded>
          <div class="text-h5 text-center">
            Picross
          </div>

          <v-tabs v-model="tab">
            <v-tab>Play</v-tab>
            <v-tab>Create</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item class="pa-5">
              <template v-if="solution">
                <play :solution="solution"></play>

                <v-divider class="ma-4"></v-divider>

                <div class="text-center">
                  <v-btn @click="solution = undefined">Quit</v-btn>
                </div>
              </template>

              <template v-else>
                <div class="d-flex justify-center">
                  <v-text-field
                    v-model="id"
                    label="Puzzle ID"
                    outlined
                    dense
                    style="max-width: 200px;"
                  ></v-text-field>

                  <v-btn @click="load">Load</v-btn>
                </div>
              </template>
            </v-tab-item>

            <v-tab-item class="pa-5">
              <create ref="create"></create>
            </v-tab-item>
          </v-tabs-items>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .picross, .picross * {
    user-select: none;
  }
</style>
