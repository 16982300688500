var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({
      display: 'grid',
      'grid-template-columns': ("repeat(" + _vm.gridWidth + ", 1fr)"),
      'grid-gap': '1px',
      'max-width': 'min(60vh, 100%)',
      'margin': '0 auto',
      'position': 'relative',
      'transition': 'all 0.2s',
    })},[_vm._l((_vm.cells),function(c,i){return [_c('cell',{key:i,ref:"cell",refInFor:true,attrs:{"filled":c.filled,"prefer":_vm.lastFillValue},on:{"update:filled":[function($event){return _vm.$set(c, "filled", $event)},function($event){_vm.lastFillValue = $event}]}})]}),_c('chamber',_vm._b({on:{"update:coordinates":_vm.onChamberUpdateCoordinates,"select":_vm.onChamberSelect}},'chamber',{
        orthogonal: true,
        els: _vm.selected$cells.map(function ($c) { return $c.$el; })
      },false))],2),_c('v-divider',{staticClass:"ma-4"}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{on:{"click":_vm.reset}},[_vm._v("Reset")]),(_vm.id)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"grey--text text--lighten-1"},[_vm._v("Last saved Puzzle ID:")]),_c('span',{staticClass:"mx-2",staticStyle:{"user-select":"text"}},[_vm._v(_vm._s(_vm.id))]),_c('copy',{model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1):_vm._e(),_c('div',[_c('v-btn',{attrs:{"loading":_vm.saving},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1),_c('mode',{model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }