var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"anime",rawName:"v-anime",value:({
    rotate: [_vm.$anime.random(-90, 90), 0],
    opacity: [0, 1],
    delay: _vm.animationDelay,
    duration: 50,
    scale: [0, 1],
  }),expression:"{\n    rotate: [$anime.random(-90, 90), 0],\n    opacity: [0, 1],\n    delay: animationDelay,\n    duration: 50,\n    scale: [0, 1],\n  }"}],staticClass:"letter",class:_vm.classes,attrs:{"tile":"","outlined":""}},[_c('v-responsive',{staticClass:"d-flex align-center text-center",staticStyle:{"position":"relative"},attrs:{"aspect-ratio":1}},[_c('v-card-text',{staticClass:"v-card-text",style:({
        'font-size': _vm.$vuetify.breakpoint.smAndDown ? '4vw' : '2vw',
      })},[_vm._v(" "+_vm._s(_vm.letter)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }