<script>
  /*
    TODO
      - require the use of some tokens?
  */
  export default {
    data() {
      return {
        id: "EXAMPLE_ID",
        jsonContent: JSON.stringify({foo: "bar"}),
        response: undefined
      };
    },

    methods: {
      get() {
        fetch(`/storage/${this.id}`)
          .then(this.setResponse);
      },

      post() {
        fetch(`/storage/${this.id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: this.jsonContent
        })
        .then(this.setResponse);
      },

      put() {
        fetch(`/storage/${this.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: this.jsonContent
        })
        .then(this.setResponse);
      },

      patch() {
        fetch(`/storage/${this.id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: this.jsonContent
        })
        .then(this.setResponse);
      },

      _delete() {
        fetch(`/storage/${this.id}`, {
          method: "DELETE",
        })
        .then(this.setResponse);
      },

      setResponse(r) {
        let response = {
          status: r.status,
          statusText: r.statusText,
        }

        response.body = r.json()
          .then(json => { response.body = json; })
          .catch(() => { response.body = "n/a"; });

        this.response = response;
      },
    }
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          v-model="id"
          label="ID"
          outlined
        ></v-text-field>

        <v-textarea
          v-model="jsonContent"
          label="JSON content"
          outlined
        ></v-textarea>
      </v-col>

      <v-col>
        <v-btn class="mb-2" block @click="get">
          get
        </v-btn>

        <v-btn class="mb-2" block @click="post">
          post (create)
        </v-btn>

        <v-btn class="mb-2" block @click="put">
          put (update/replace)
        </v-btn>

        <v-btn class="mb-2" block @click="patch">
          put (update/modify)
        </v-btn>

        <v-btn class="mb-2" block @click="_delete">
          delete
        </v-btn>
      </v-col>

      <v-col>
        <v-sheet class="pa-3">
          <div class="text-center text-h5">Response</div>
          <pre>{{ response }}</pre>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
