<script>
  export default {
    props: {
      cells: {
        type: Array,
        required: true
      },

      column: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      solutionDigits() {
        let result = [];

        let currentRun = 0;

        this.cells.forEach(c => {
          if (c.solution) {
            currentRun++;
          } else {
            if (currentRun > 0) {
              result.push(currentRun);
            }
            currentRun = 0;
          }
        })

        if (currentRun > 0) {
          result.push(currentRun);
        }

        return result;
      },

      filledDigits() {
        let result = [];

        let currentRun = 0;

        this.cells.forEach(c => {
          if (c.filled) {
            currentRun++;
          } else {
            if (currentRun > 0) {
              result.push(currentRun);
            }
            currentRun = 0;
          }
        })

        if (currentRun > 0) {
          result.push(currentRun);
        }

        return result;
      },

      solved() {
        return JSON.stringify(this.solutionDigits) == JSON.stringify(this.filledDigits);
      }
    },
  };
</script>

<template>
  <v-card
    class="d-flex justify-end align-center"
    :class="column ? 'flex-column' : ''"
    light
    outlined
    color="yellow lighten-3"
  >
    <div
      v-for="(d, i) in solutionDigits"
      :key="i"
      class="mx-1 grey--text"
      :class="solved ? 'text-decoration-line-through' : 'text--darken-4'"
    >
      {{ d }}
    </div>
  </v-card>
</template>
