<script>
  export default {
    data() {
      return {
        mode: "random",

        modes: [
          {label: "Random", value: "random"},
          //{label: "Group", value: "random-group"},
          {label: "Reflex", value: "reflex"},
        ],
      };
    },

    methods: {
      start() {
        this.$router.push({path: "/fingers/" + this.mode});
      }
    },
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          max-width="600"
        >
          <v-card-title>
            Select Mode
          </v-card-title>

          <v-list>
            <v-list-item-group
              v-model="mode"
              color="primary"
              mandatory
            >
              <v-list-item
                v-for="m in modes"
                :key="m.value"
                :value="m.value"
              >
                <v-list-item-icon>
                  <template v-if="mode == m.value">
                    <v-icon>mdi-check-circle</v-icon>
                  </template>

                  <template v-else>
                    <v-icon>mdi-circle-outline</v-icon>
                  </template>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ m.label }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              outlined
              @click="start"
              large
            >
              Start
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
