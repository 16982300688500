<script>
  const getSurroundingCellKeys = (key) => {
    let [x, y] = key.split(",").map(Number);

    return [
      `${x - 1},${y - 1}`,
      `${x},${y - 1}`,
      `${x + 1},${y - 1}`,
      `${x - 1},${y}`,
      `${x + 1},${y}`,
      `${x - 1},${y + 1}`,
      `${x},${y + 1}`,
      `${x + 1},${y + 1}`,
    ];
  };

  export default {
    components: {
      cell: require("./cell").default,
      "pan-zoom": require("./panzoom").default,
    },

    data() {
      return {
        grid: {}
      };
    },

    methods: {
      reset() {
        this.$set(this, "grid", {});
      },

      next() {
        let originalGrid = {...this.grid};

        const getNumberOfNeighbors = key => {
          return getSurroundingCellKeys(key)
            .filter(k => originalGrid[k])
            .length;
        };

        Object.keys(originalGrid)
          .filter(key => originalGrid[key])
          .forEach(key => {
            let neighbors = getNumberOfNeighbors(key);

            if (neighbors < 2 || neighbors > 3) {
              this.$set(this.grid, key, false);
            }
          });

        Object.keys(originalGrid)
          .filter(key => originalGrid[key])
          .map(getSurroundingCellKeys)
          .flat()
          .filter((key, index, array) => {
            return array.slice(index + 1).indexOf(key) == -1;
          })
          .filter(key => !originalGrid[key])
          .forEach(key => {
            let neighbors = getNumberOfNeighbors(key);

            if (neighbors == 3) {
              this.$set(this.grid, key, true);
            }
          });
      },
    }
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="game-container">
          <template v-for="x in 16">
            <template v-for="y in 12">
              <cell
                v-model="grid[(x -1) + ',' + (y - 1)]"
                :x="x - 1"
                :y="y - 1"
                :key="x + '_' + y"
              ></cell>
            </template>
          </template>
        </div>
      </v-col>

      <v-col class="text-center">
        <v-btn
          @click="next"
        >
          Next
        </v-btn>

        <v-btn
          @click="reset"
        >
          Reset
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <pan-zoom>
          this is some content

          <div style="height: 300px; width: 300px; background: blue;"></div>
        </pan-zoom>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .game-container {
    position: relative;
    max-width: 800px;
    height: 600px;
    background: blue;
  }
</style>
