<script>
  import { io } from 'socket.io-client';
/*
  import SystemBar from './components/SystemBar';
  import AnimatedTitle from './components/AnimatedTitle';
 */

  export default {
    components: {
      "nav-drawer": require('./components/NavigationDrawer').default,
      //"animated-title": require('./components/Title').default,
      /*
      "system-bar": SystemBar,
      "animated-title": AnimatedTitle,
       */
    },

    data() {
      return {
        navigation: false,
      };
    },

    computed: {
      connected() {
        return this.$store.state.socket.connected && this.$store.state.me._id;
      },
    },

    beforeMount() {
      // TODO: can we move this socket stuff somewhere else?
      let socket = io({
        query: {
          "_id": localStorage.getItem("_id") || ""
        }
      });

      socket.on("_id", _id => {
        localStorage.setItem("_id", _id);
      });

      this.$store.commit("setSocket", socket);

      socket.on("me", data => {
        data = JSON.parse(data);
        this.$store.commit("setMe", data);
      });

      this.$store.state.socket.emit("me");

      this.$store.state.socket.on("user", data => {
        this.$store.commit("setUser", data);
      });

      this.$router.afterEach((to, from) => {
        this.$store.commit("addToRouteHistory", from.fullPath);
        this.$store.commit("addToRouteHistory", to.fullPath);
        setTimeout(() => {
          this.navigation = false;
        }, 400);
      });

      if (window?.navigator?.userAgentData?.mobile) {
        this.$store.commit("setIsMobile", true);
      }
    },

    created() {
      this.$vuetify.theme.dark = true;
    }
  };
</script>

<template>
  <v-app>
    <template v-if="connected">
      <v-app-bar
        app
        flat
        dense
        :color="$route.matched[0].meta.color + ' darken-2'"
      >
        <v-btn icon @click="navigation = !navigation">
          <v-icon>mdi-dots-grid</v-icon>
        </v-btn>

        <v-toolbar-title
          class="display-6 d-flex justify-space-between"
          style="width: 100%;"
        >
          <div class="d-flex align-center">
            <v-icon
              large
              class="mr-3"
            >
              {{ $route.matched[0].meta.icon }}
            </v-icon>

            <span
              class="d-none d-sm-flex"
            >
              ryandeba.</span>{{ $route.name.replace(/\//g, '.') }}
          </div>

          <template v-if="!connected">
            <div
              class="
                px-3
                text-caption
                red--text
                grey darken-4
                d-flex align-center
                rounded-pill
              "
              style="opacity: 0.9;"
            >
              <v-icon
                color="red"
                class="mr-2"
              >
                mdi-signal-off
              </v-icon>

              connection lost...
            </div>
          </template>

          <v-btn
            text
            @click="$router.push({path: '/me'})"
          >
            <span class="d-none d-sm-flex">
              {{ $store.state.me.username }}
            </span>

            <v-icon>
              mdi-account
            </v-icon>
          </v-btn>
        </v-toolbar-title>
      </v-app-bar>

      <nav-drawer v-model="navigation"></nav-drawer>
    </template>

    <v-main>
      <v-fade-transition mode="out-in">
        <div :key="$route.name" class="fill-height">
          <router-view></router-view>
        </div>
      </v-fade-transition>

      <template v-if="!connected">
        <v-container style="height: 400px;">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-h4 text-center" cols="12">
              connecting...
            </v-col>

            <v-col cols="6">
              <v-progress-linear
                color="green accent-3"
                indeterminate
                rounded
                stream
                :buffer-value="60"
                height="50"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-main>

    <svg width="0" height="0">
      <pattern id="striped-red" patternUnits="userSpaceOnUse" width="2" height="10"><path d="M-1,1 H5" style="stroke:#e74c3c; stroke-width:3" /></pattern>
      <pattern id="striped-green" patternUnits="userSpaceOnUse" width="2" height="10"><path d="M-1,1 H5" style="stroke:#27ae60; stroke-width:3" /></pattern>
      <pattern id="striped-purple" patternUnits="userSpaceOnUse" width="2" height="10"><path d="M-1,1 H5" style="stroke:#8e44ad; stroke-width:3" /></pattern>
    </svg>
  </v-app>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

$body-font-family: 'Ubuntu';
$title-font-family: 'Ubuntu';
$font-family-fallback: 'sans-serif';

.v-application {
  font-family: $body-font-family, $font-family-fallback !important;

  .title {
    font-family: $title-font-family, $font-family-fallback !important;
  }

  [class*='text-'] {
    font-family: $body-font-family, $font-family-fallback !important;
  }
}
</style>
