<script>
  export default {
    props: ["value", "x", "y"],

    data() {
      return {
        input: false
      };
    },

    watch: {
      value() {
        this.input = this.value;
      },

      input() {
        this.$emit("input", this.input);
      },
    },

    computed: {
      styles() {
        return {
          left: (this.x * 50) + "px",
          top: (this.y * 50) + "px",
        }
      }
    }
  }
</script>

<template>
  <div
    class="cell"
    :class="input ? 'cell--on' : ''"
    :style="styles"
    @click="input = !!!input"
  ></div>
</template>

<style scoped>
  .cell {
    position: absolute;
    width: 50px;
    height: 50px;
    background: white;
    border: 1px solid #ccc;
    transition: background 0.1s;
  }

  .cell--on {
    background: blue;
  }
</style>
