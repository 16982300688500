<script>
  export default {
    props: {
      letter: {
        type: String,
        required: true
      },

      positionCorrect: {
        type: Boolean,
        default: false,
      },

      letterCorrect: {
        type: Boolean,
        default: false,
      },

      letterIncorrect: {
        type: Boolean,
        default: false,
      },

      animationDelay: {
        type: Number,
        default: 0,
      },

      history: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      color() {
        if (this.positionCorrect) {
          return "success";
        }

        if (this.letterCorrect) {
          return "warning";
        }

        if (this.letterIncorrect) {
          return "error";
        }

        return "";
      },

      classes() {
        let classes = [];

        if (this.letter == "") {
          classes.push("grey");
        }

        if (this.history) {
          classes.push(this.color);
        } else {
          classes.push(`${this.color}--text`);
        }

        return classes;
      },
    },
  }
</script>

<template>
  <v-card
    tile
    outlined
    class="letter"
    :class="classes"
    v-anime="{
      rotate: [$anime.random(-90, 90), 0],
      opacity: [0, 1],
      delay: animationDelay,
      duration: 50,
      scale: [0, 1],
    }"
  >
    <v-responsive
      :aspect-ratio="1"
      class="d-flex align-center text-center"
      style="position: relative;"
    >
      <v-card-text
        class="v-card-text"
        :style="{
          'font-size': $vuetify.breakpoint.smAndDown ? '4vw' : '2vw',
        }"
      >
        {{ letter }}
      </v-card-text>
    </v-responsive>
  </v-card>
</template>

<style scoped>
  .letter {
    transition: all 0.1s;
  }

  .v-card-text {
    transition: font-size 0.1s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
