<script>
  import UserCard from "./UserCard";
  import CreateGame from "./CreateGame";
  import Overview from "./Overview";
  import Timeline from "./Timeline";

  export default {
    filters: {
      ms(ms) {
        var seconds = ms / 1000;
        var minutes = Math.floor(seconds / 60);
        seconds = Math.floor(seconds % 60);

        seconds = ('00' + seconds).substr(-2);

        return `${minutes}:${seconds}`;
      },
    },

    components: {
      "user-card": UserCard,
      "overview": Overview,
      "timeline": Timeline,
      "create-game": CreateGame,
    },

    props: {
      users: {
        type: Array,
      },
    },

    data() {
      return {
        tab: 0,
      };
    },

    computed: {
      sortedUsers() {
        return this.users
          .map(u => {
            return {
              ...u,
              score: (u.correctSets || []).length - (u.incorrectSets || []).length
            };
          })
          .sort((a, b) => {
            return b.score - a.score;
          })
      },

      topScore() {
        return this.sortedUsers[0].score || 0;
      },
    },
  }
</script>

<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab>Scores</v-tab>
        <v-tab>Overview</v-tab>
        <v-tab>Timeline</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div
                v-for="(u, i) in sortedUsers"
                :key="i"
                class="d-flex flex-row align-center"
              >
                <div class="text-center mr-5">
                  <v-icon color="yellow darken-2" v-if="i == 0 || u.score == topScore">mdi-medal</v-icon>
                  <v-icon color="grey lighten-2" v-else-if="i == 1">mdi-podium-silver</v-icon>
                  <v-icon color="orange darken-2" v-else-if="i == 2">mdi-podium-bronze</v-icon>
                  <v-icon color="grey" v-else>mdi-account</v-icon>
                </div>

                <user-card v-bind="u" class="my-3"></user-card>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat class="px-3">
            <overview :users="users"></overview>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat class="px-3">
            <timeline :users="users"></timeline>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card> 

    <v-row>
      <v-col cols="8" offset="2">
        <create-game></create-game>
      </v-col>
    </v-row>
  </div>
</template>
