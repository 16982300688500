<script>
  // TODO: is this helpful? https://github.com/SortableJS/Vue.Draggable/blob/master/example/components/functional.vue
  import draggable from 'vuedraggable';

  export default {
    components: {
      draggable,
    },

    props: {
      numbers: {
        type: Array,
        default() {
          return [];
        },
      },

      target: {},
    },

    data() {
      return {
        usedNumbers: [],
        unusedNumbers: [],
        operators: [],
      };
    },

    computed: {
      friendlyFormula() {
        let str = "";

        this.usedNumbers.forEach((n, i) => {
          str += n.number + " ";

          if (i < this.usedNumbers.length - 1) {
            str += this.operators[i] + " ";
          }
        });

        return str;
      },

      result() {
        try {
          return eval(this.friendlyFormula);
        } catch (e) {
          return "Error: " + e;
        }
      },
    },

    watch: {
      numbers() {
        this.syncNumbers();
      },
    },

    methods: {
      syncNumbers() {
        this.usedNumbers = [];

        this.unusedNumbers = this.numbers.map(n => {
          return {
            id: Math.random(),
            number: n,
          };
        });

        this.operators = this.numbers.length < 1 ? [] : new Array(this.numbers.length).fill("+");
      },

      cycleOperator(index) {
        let op = this.operators[index];

        if (op == "+") {
          op = "-";
        } else if (op == "-") {
          op = "*";
        } else if (op == "*") {
          op = "/";
        } else {
          op = "+";
        }

        this.$set(this.operators, index, op);
      },
    }
  }
</script>

<template>
  <div>
    <div>
      <strong>Formula:</strong>

      <span
        v-if="usedNumbers.length > 0"
        class="pa-1 primary--text"
      >
        {{ friendlyFormula }} = {{ result }}
      </span>

      <template v-if="result == target && result && Number.isInteger(Number(result))">
        <v-icon color="success">mdi-check</v-icon>
      </template>

        <!-- TODO: this draggable stuff is screwy...probs because of the operators -->
      <draggable
        v-model="usedNumbers"
        group="numbers"
        draggable=".drag-number"
        filter=".operator"
        class="numbers mb-2"
      >
        <template v-for="(fn, i) in usedNumbers">
          <div
            :key="fn.id"
            class="drag-number primary ma-1"
            @dblclick="unusedNumbers.push(usedNumbers.splice(i, 1)[0])"
          >
            {{ fn.number }}
          </div>

          <v-btn
            v-if="i < operators.length - 1 && i < usedNumbers.length - 1"
            :key="'o' + i"
            @click="cycleOperator(i)"
            style="min-width: 30px;"
            class="operator"
            outlined
            icon
            small
          >
            <v-icon v-if="operators[i] == '+'">mdi-plus</v-icon>
            <v-icon v-if="operators[i] == '-'">mdi-minus</v-icon>
            <v-icon v-if="operators[i] == '*'">mdi-close</v-icon>
            <v-icon v-if="operators[i] == '/'">mdi-slash-forward</v-icon>
          </v-btn>
        </template>
      </draggable>
    </div>

    <div>
      <strong>Numbers:</strong>

      <draggable
        v-model="unusedNumbers"
        group="numbers"
        draggable=".drag-number"
        class="numbers"
      >
        <div
          v-for="(fn, i) in unusedNumbers"
          :key="fn.id"
          class="drag-number primary darken-4 ma-1"
          @dblclick="usedNumbers.push(unusedNumbers.splice(i, 1)[0])"
        >
          {{ fn.number }}
        </div>
      </draggable>
    </div>
  </div>
</template>

<style scoped>
  .numbers {
    height: 40px;
  }

  .drag-number {
    aspect-ratio: 1;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 100%;
    cursor: move;
  }
</style>
