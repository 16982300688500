import Phaser from 'phaser';

const Laser = require("./laser.js").default;

export default class Ship extends Phaser.GameObjects.Polygon {
  #timeShootEnabled
  #color
  #enabled
  #timeEnableable
  #score
  #onChangeScore

  constructor(scene, config) {
    config = {
      x: 400,
      y: 500,
      color: "#ffffff",
      ...config
    };

    let x = config.x;
    let y = config.y;
    let points = "30 10 0 0 4 10 0 20 30 10";
    let color = parseInt(config.color.replace(/^#/, ''), 16);

    super(scene, x, y, points, color);

    scene.add.existing(this);

    scene.physics.add.existing(this, false).body
      .setDamping(true)
      .setDrag(0.99)
      .setMaxVelocity(200);

    this
      .setAngle(-90)
      .setDepth(1);

    this.#color = config.color;
    this.#score = 0;
    this.#onChangeScore = config.onChangeScore;
    this.disable();
  }

  enable() {
    if (this.scene.time.now < this.#timeEnableable) {
      return;
    }

    this.#enabled = true;

    this.scene.tweens.add({
      targets: this,
      alpha: { value: 1, duration: 200 },
      scale: { value: 1, duration: 200 },
    });
  }

  disable() {
    this.#enabled = false;

    this.scene.tweens.add({
      targets: this,
      alpha: { value: 0.3, duration: 200 },
      scale: { value: 0.5, duration: 200 },
    });
  }

  isEnabled() {
    return this.#enabled;
  }

  shoot() {
    if (this.scene.time.now < this.#timeShootEnabled) {
      return;
    }

    this.#timeShootEnabled = this.scene.time.now + 700;

    return new Laser(this.scene, {
      x: this.x,
      y: this.y,
      angle: this.angle,
      color: this.#color,
      ship: this,
    });
  }

  explode() {
    this.disable();
    this.#timeShootEnabled = this.scene.time.now + 2500;

    setTimeout(() => {
      this.enable();
    }, 3000);
  }

  increaseScore() {
    this.#score++;
    this.#onChangeScore && this.#onChangeScore(this.#score);
  }
}
