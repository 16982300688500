<script>
  export default {
    components: {
      settings: require("./settings").default,
    },
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <template v-if="$route.name == 'mastermind'">
          <settings></settings>
        </template>

        <router-view :key="$route.name"></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>
