<script>
  export default {
    components: {
      "digital-button": require("./DigitalButton").default,
    },

    data() {
      return {
        x: 0,
        y: 0,
      };
    },

    watch: {
      x() {
        this.emit();
      },

      y() {
        this.emit();
      },
    },

    methods: {
      emit() {
        this.$emit("input", {
          x: this.x,
          y: this.y,
        });
      },
    }
  };
</script>

<template>
  <div class="dpad">
    <div></div>

    <digital-button
      class="grey"
      @input="y = $event ? -1 : 0"
    >
      <v-icon x-large>
        mdi-arrow-up-bold-circle
      </v-icon>
    </digital-button>

    <div></div>

    <digital-button
      class="grey"
      @input="x = $event ? -1 : 0"
    >
      <v-icon x-large>
        mdi-arrow-left-bold-circle
      </v-icon>
    </digital-button>

    <div></div>

    <digital-button
      class="grey"
      @input="x = $event ? 1 : 0"
    >
      <v-icon x-large>
        mdi-arrow-right-bold-circle
      </v-icon>
    </digital-button>

    <div></div>

    <digital-button
      class="grey"
      @input="y = $event ? 1 : 0"
    >
      <v-icon x-large>
        mdi-arrow-down-bold-circle
      </v-icon>
    </digital-button>
  </div>
</template>

<style scoped>
  .dpad {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    justify-items: center;
  }
</style>
