<script>
  export default {
    components: {
      "code-peg": require("./code-peg").default,
      "key-pegs": require("./key-pegs").default,
    },

    props: {
      value: {
        type: Array,
      },

      clues: {
        type: Object,
      },

      peg: {
        type: Number,
      },

      disabled: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        input: [undefined, undefined, undefined, undefined],

        pegIndex: -1,
      };
    },

    watch: {
      value: {
        handler() {
          if (this.value) {
            this.input = JSON.parse(JSON.stringify(this.value));
          }
        },
        immediate: true
      }
    },

    methods: {
      onKeyDown(e) {
        if (this.disabled) {
          return;
        }

        if (["ArrowRight", "d"].indexOf(e.key) > -1) {
          this.incrementPegIndex();
        }

        if (["ArrowLeft", "a"].indexOf(e.key) > -1) {
          this.decrementPegIndex();
        }

        if ([" "].indexOf(e.key) > -1) {
          if (this.pegIndex == -1) {
            this.pegIndex = this.input.indexOf(undefined);
          }

          if (this.pegIndex == -1) {
            return;
          }

          let value = this.input[this.pegIndex] == this.peg ? undefined : this.peg;
          this.$set(this.input, this.pegIndex, value);

          this.incrementPegIndex();
        }

        if (["Backspace"].indexOf(e.key) > -1) {
          this.decrementPegIndex();
          this.$set(this.input, this.pegIndex, undefined);
        }

        if (["Delete"].indexOf(e.key) > -1) {
          this.$set(this.input, this.pegIndex, undefined);
        }

        if (["Enter"].indexOf(e.key) > -1) {
          this.save();
        }
      },

      incrementPegIndex() {
        this.pegIndex++;

        if (this.pegIndex > 3) {
          this.pegIndex = 0;
        }
      },

      decrementPegIndex() {
        this.pegIndex--;

        if (this.pegIndex < 0) {
          this.pegIndex = 3;
        }
      },

      save() {
        if (this.input.indexOf(undefined) > -1) {
          return;
        }

        this.$emit('input', JSON.parse(JSON.stringify(this.input)));
      }
    },

    beforeMount() {
      document.addEventListener("keydown", this.onKeyDown);
    },

    destroyed() {
      document.removeEventListener("keydown", this.onKeyDown);
    },
  }
</script>

<template>
  <div>
    <code-peg
      v-for="(p, i) in input"
      v-model="input[i]"
      :key="i"
      :peg="peg"
      :disabled="disabled"
      :class="!disabled && i == pegIndex ? 'peg--selected' : ''"
    ></code-peg>

    <template v-if="Boolean(clues)">
      <key-pegs v-bind="clues"></key-pegs>
    </template>

    <template v-if="!Boolean(value) && !disabled && input.indexOf(undefined) == -1">
      <div class="d-flex justify-center">
        <v-btn
          icon
          small
          outlined
          color="blue-grey lighten-2"
          @click="save"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
    </template>
  </div>
</template>

<style scoped>
  .peg--selected {
    outline: 2px solid rgba(255, 255, 255, 0.5);
  }
</style>
