<script>
  require("aframe");

  const getRandomHex = () => {
    let chars = "0123456789ABCDEF".split("");

    let hex = "";

    while (hex.length < 6) {
      hex += chars[Math.floor(Math.random() * chars.length)];
    }

    return "#" + hex;
  }

  export default {
    data() {
      return {
        boxColor: "#FFFF00",
        sphereColor: "#00FF00",
        cylinderColor: "#0000FF",
      };
    },

    methods: {
      changeObjectColorsRandomly() {
        this.boxColor = getRandomHex();
        this.sphereColor = getRandomHex();
        this.cylinderColor = getRandomHex();
      }
    },
  }
</script>

<template>
  <div class="fill-height">
    <v-btn
      style="z-index: 99999; position: absolute; bottom: 0;"
      @click="changeObjectColorsRandomly"
    >
      TODO
    </v-btn>

    <a-scene>
      <a-box position="-1 0.5 -3" rotation="45 45 45" :color="boxColor"></a-box>
      <a-sphere position="0 1.25 -5" radius="1.25" :color="sphereColor"></a-sphere>
      <a-cylinder position="1 0.75 -3" radius="0.5" height="1.5" :color="cylinderColor"></a-cylinder>

      <a-plane position="0 0 -4" rotation="-90 0 0" width="4" height="6" color="#7BC8A4"></a-plane>

      <a-sky color="#ECECEC"></a-sky>
    </a-scene>
  </div>
</template>
