<script>
  export default {
    props: {
      value: {
        type: String,
        default: `(input) => { return input; }`,
      },

      label: {
        type: String,
        default: "Custom Function"
      },

      i: {},
      o: {},
    },
  }
</script>

<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        {{ label }}
      </v-list-item-title>

      <v-list-item-subtitle class="green--text text--darken-4">
        {{ value }}
      </v-list-item-subtitle>

      <v-list-item-content v-if="false">
        <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px;">
          <div>
            <v-textarea
              :value="i"
              label="Input"
              color="green"
              class="mb-0 pb-0"
              readonly
              outlined
              hide-details
            ></v-textarea>
          </div>

          <div>
            <v-textarea
              :value="o"
              label="Output"
              color="green"
              class="mb-0 pb-0"
              readonly
              outlined
              hide-details
            ></v-textarea>
          </div>
        </div>
      </v-list-item-content>
    </v-list-item-content>
  </v-list-item>
</template>

<style scoped>
  .drag-handle {
    cursor: move;
  }
</style>
