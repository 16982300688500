<script>
  export default {
    props: {
      value: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        winner: undefined,
        timeout: undefined,
      };
    },

    watch: {
      value: {
        handler() {
          if (this.winner != undefined && !this.value[this.winner]) {
            this.winner = undefined;
          }
        },
        deep: true
      },

      winner() {
        this.$emit("update:winner", this.winner);
      }
    },

    beforeMount() {
      this.$watch(() => { return Object.keys(this.value).join(","); }, () => {
        if (this.winner != undefined) {
          if (!this.value.hasOwnProperty(this.winner)) {
            this.winner = undefined;
          }
        }

        clearTimeout(this.timeout);

        let ids = Object.keys(this.value);

        if (ids.length < 2) {
          return;
        }

        this.timeout = setTimeout(() => {
          this.winner = ids[Math.floor(Math.random() * ids.length)];

          try {
            window.navigator.vibrate([300]);
          } catch (e) {
            console.warn("error trying to vibrate", e);
          }
        }, 3000);
      });
    }
  }
</script>

<template>
  <div>
    <v-fade-transition mode="out-in">
      <div :key="Object.keys(value).length">
        <template v-if="Object.keys(value).length >= 2">
          <div class="text-center headline pt-10">
            Don't let go
          </div>

          <div class="text-center caption">
            <v-progress-circular
              indeterminate
              color="grey"
            ></v-progress-circular>

            Randomly choosing winner...
          </div>
        </template>
      </div>
    </v-fade-transition>
  </div>
</template>
