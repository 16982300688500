<script>
  export default {
    props: ["id", "x", "y", "hex", "winner"],

    computed: {
      classes() {
        let classes = ["touch"];

        this.winner && classes.push("touch--winner");

        return classes.join(" ");
      },

      styles() {
        return {
          top: `${this.y - 50}px`,
          left: `${this.x - 50}px`,
          "background-color": this.winner ? '#fff' : this.hex,
          color: this.winner ? this.hex : '#fff',
        };
      },
    },
  }
</script>

<template>
  <div
    :class="classes"
    :style="styles"
  >
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
/*
  $color-pack: false;

  @import '~vuetify/src/styles/main.sass';
  */

  @keyframes zoom-in {
    0% {
      opacity: 0;
      transform: scale(2);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes ripple {
    0% {
      box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0);
    }

    25% {
      box-shadow: 0px 0px 3px 3px rgba(255, 255, 255, 1);
    }

    100% {
      box-shadow: 0px 0px 8px 15px rgba(255, 255, 255, 0);
    }
  }

  @keyframes attention {
    0% {
      transform: rotate(0deg);
      border-radius: 100%;
      box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.4);
    }

    20% {
      border-radius: 10px;
    }

    50% {
      border-radius: 50%;
      box-shadow: 0px 0px 3px 10px rgba(255, 255, 255, 0.8);
    }

    80% {
      border-radius: 10px;
    }

    100% {
      transform: rotate(360deg);
      border-radius: 100%;
      box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.4);
    }
  }

  @keyframes winner {
    0% {
      box-shadow: 0px 0px 0px 0px;
    }

    20% {
      box-shadow: 0px 0px 0px 300px;
    }

    40% {
      box-shadow: 0px 0px 0px 1000px;
    }

    100% {
      box-shadow: 0px 0px 0px 3000px;
    }
  }

  .touch {
    position: fixed;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    transition: background-color 0.5s, color 0.5s;
    animation: zoom-in 0.2s linear forwards, ripple 1.5s linear infinite;
  }

  .touch--winner {
    transform: scale(1.5);
    z-index: 101;

    animation: winner 2s linear forwards;
  }
</style>
