<script>
  export default {
    components: {
      "key-pegs": require("./key-pegs").default,
      guess: require("./guess").default,
    },

    props: {
      players: {
        type: Array,
        required: true
      },

      showGuesses: {
        type: Boolean,
        default: false
      },
    },

    data() {
      return {
        menus: {},
      };
    }
  }
</script>

<template>
  <div class="text-center">
    <template v-for="(p, i) in players">
      <v-menu
        :key="i"
        v-model="menus[p._id]"
        transition="scale-transition"
        absolute
      >
        <template v-slot:activator="{ on }">
          <v-chip
            v-on="on"
            class="ma-2"
            color="grey darken-4 grey--text text--lighten-3"
            :outlined="p.active === false"
          >
            <span :class="p._id == $store.state.me._id ? 'me' : ''" class="mx-1">
              {{ p.username }}
            </span>

            <template v-if="p.game && p.game.guesses">
              <v-avatar
                right
                class="darken-3"
                :class="{
                  'green': p.game && p.game.gameOver && p.game.guessClues.find(c => c && c.correctPosition == 4),
                  'red': p.game && p.game.gameOver && !p.game.guessClues.find(c => c && c.correctPosition == 4),
                  'grey darken-3': !p.game || !p.game.gameOver,
                }"
              >
                {{ p.game.guesses.filter(Boolean).length }}
              </v-avatar>
            </template>
          </v-chip>
        </template>

        <v-card min-width="200">
          <v-card-title>
            <v-icon>mdi-account-circle</v-icon>

            <span :class="p._id == $store.state.me._id ? 'me' : ''" class="mx-2">
              {{ p.username }}
            </span>
          </v-card-title>

          <v-card-text>
            <template v-if="p.game && Array.isArray(p.game.guessClues)">
              <template v-if="showGuesses">
                <div>
                  <template v-for="(g, i) in p.game.guesses">
                    <guess
                      :key="p._id + '_' + i"
                      :value="g"
                      :clues="p.game.guessClues[i]"
                      class="board__row"
                      disabled
                    ></guess>
                  </template>
                </div>
              </template>

              <template v-else>
                <div class="d-flex flex-column align-center">
                  <template v-for="(c, i) in p.game.guessClues">
                    <key-pegs
                      :key="p._id + '_' + i"
                      v-bind="c"
                      style="width: 30px;"
                      class="ma-1"
                    ></key-pegs>
                  </template>
                </div>
              </template>
            </template>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </div>
</template>

<style scoped>
  .me {
    text-decoration-style: dotted;
    text-decoration-line: underline;
  }
</style>
