<script>
  export default {
    data() {
      return {
        input: false
      };
    },
  }
</script>

<template>
  <div>
    <v-btn
      @click="input = true"
      color="secondary"
      icon
    >
      <v-icon>mdi-help</v-icon>
    </v-btn>

    <v-dialog
      v-model="input"
      max-width="550px"
      scrollable
    >
      <v-card>
        <v-card-title>
          How to Play
        </v-card-title>

        <v-card-text>
          <div>
            Guess the word in as few attempts as possible.
          </div>

          <template
            v-for="(d, i) in [
             {color: 'success', text: 'Correct letter in correct position'},
             {color: 'warning', text: 'Correct letter in incorrect position'},
             {color: 'error', text: 'Incorrect letter'},
             {color: 'grey', text: 'Unknown'},
            ]"
          >
            <div
              class="d-flex align-center my-4"
              :key="i"
            >
              <div>
                <div class="square" :class="d.color"></div>
              </div>

              <v-icon class="mx-4" x-large>
                mdi-equal
              </v-icon>

              <div class="text-h6">
                {{ d.text }}
              </div>
            </div>
          </template>
        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>

          <v-btn
            @click="input = false"
            class="my-2"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
  .square {
    width: 50px;
    height: 50px;
  }
</style>
