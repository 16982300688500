<script>
  const sounds = {
    fail: require("./fail.wav"),
  };


  export default {
    components: {
      icon: require("./icon").default,
    },

    data() {
      return {
        face: "robot",
        faces: ["robot"]
          .concat(["angry", "confused", "dead", "excited", "happy", "love"].map(s => "robot-" + s))
          .map(s => {
            return [s, s + "-outline"];
          })
          .flat(),
      };
    },

    methods: {
      randomizeFace() {
        this.face = this.faces[Math.floor(Math.random() * this.faces.length)];

      },

      playSound() {
        var audio = new Audio(sounds.fail);
        audio.play();
      }
    },
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet class="pa-5 robot">
          <icon :icon="face"></icon>
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <div class="text-center">
          <v-btn @click="randomizeFace">Beep</v-btn>

          <v-btn @click="playSound">Boop</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
