<script>
  // TODO: animated numbers
  export default {
    filters: {
      comma(i) {
        return String(i)
          .split("")
          .reverse()
          .join("")
          .replace(/.{3}/g, (match) => { return match + "," })
          .split("")
          .reverse()
          .join("")
          .replace(/^,/, "");
      },
    },

    props: {
      value: {
        type: String,
        required: true
      }
    },

    computed: {
      words() {
        return this.value
          .replace(/\s+/g, " ")
          .split(" ")
          .filter(w => w.length > 0);
      },

      lines() {
        return this.value
          .split(/\n/);
      }
    },
  };
</script>

<template>
  <div class="caption" style="display: grid; grid-gap: 5px;">
    <div class="text-h6 text-center">
      Stats
    </div>

    <div class="stat">
      <strong class="green--text">Length</strong>
      {{ value.length | comma }}
    </div>

    <div class="stat">
      <strong class="green--text">Lines</strong>
      {{ lines.length | comma }}
    </div>

    <div class="stat">
      <strong class="green--text">Words</strong>
      {{ words.length | comma }}
    </div>
  </div>
</template>

<style scoped>
  .stat {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transition: all 0.2s;
  }

  .stat:hover {
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
  }
</style>
