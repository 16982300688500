<script>
  import VueQrcode from 'vue-qrcode';

  export default {
    components: {
      qr: VueQrcode,
      history: require("./history").default,
    },

    data() {
      return {
        input: "",
        fullscreen: false,
        width: undefined,
      }
    },

    computed: {
      qrValue() {
        return this.input || "This is not the QR code you're looking for";
      }
    },

    watch: {
      fullscreen() {
        if (!this.fullscreen) {
          return;
        }

        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

        let width = Math.min(vw, vh);
        width = width - 100;
        width = Math.max(300, width);

        this.width = width;

        this.$refs.history.add(this.input);
      }
    },

    beforeMount() {
    }
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet
          class="pa-3 blue-grey darken-4"
          style="min-height: 30vh;"
          rounded
        >
          <v-text-field
            v-model="input"
            class="mb-3"
            color="white"
            hide-details
            outlined
            placeholder="Enter some text"
            autofocus
            @keydown.esc="input = ''"
            @keydown.enter="fullscreen = true"
          ></v-text-field>

          <div class="text-center my-4" style="min-height: 180px;">
            <qr :value="qrValue"></qr>
          </div>

          <div class="text-center">
            <v-btn
              fab
              @click="fullscreen = true"
            >
              <v-icon>mdi-fullscreen</v-icon>
            </v-btn>
          </div>

          <v-divider class="my-8"></v-divider>

          <history
            class="mt-8"
            @restore="input = $event"
            ref="history"
          ></history>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog
      v-model="fullscreen"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card
        tile
        class="d-flex align-center justify-center"
        v-if="fullscreen"
      >
        <v-btn
          fab
          @click="fullscreen = false"
          style="position: absolute; right: 20px; top: 20px;"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <qr :value="qrValue" :width="width"></qr>
      </v-card>
    </v-dialog>
  </v-container>
</template>
