import Vue from 'vue'
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueClipboard from 'vue-clipboard2';
import VueAnimejs from 'vue-animejs';

const store = require("./Store.js").default;
const routes = require("./routes.js").default;

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueClipboard);
Vue.use(VueAnimejs);

Vue.config.productionTip = false;

Vue.config.ignoredElements = [/a-*/]; // ignore all a-frame elements

new Vue({
  vuetify,
  store: new Vuex.Store(store),
  router: new VueRouter(routes),
  render: h => h(App)
}).$mount('#app')
