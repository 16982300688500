<script>
  const colors = "red,orange,yellow,green,blue,indigo,purple".split(",");

  export default {
    components: {
      dot: require("./dot").default,
      mouse: require("./mouse").default
    },

    data() {
      return {
        dotColors: [...colors],
        mouseX: 0,
        mouseY: 0
      };
    },

    computed: {
      formattedDots() {
        return this.dotColors
          .map((color, index, array) => {
            return {
              color: color,
              size: (array.length - index) * 50,
            };
          });
      },
    },

    methods: {
      updateMouseCoordinates(coords) {
        this.mouseX = coords.x;
        this.mouseY = coords.y;

        this.dotColors.sort(() => {
          return Math.random() < 0.5 ? -1 : 1;
        });
      }
    },

    mounted() {
      this.mouseX = window.innerWidth / 2;
      this.mouseY = window.innerHeight / 2;
    }
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <dot
          v-for="(d, i) in formattedDots"
          :key="i"
          :class="d.color"
          :size="d.size"
          :fixed-position="{x: mouseX, y: mouseY}"
        ></dot>

      </v-col>
    </v-row>

    <mouse @input="updateMouseCoordinates"></mouse>
  </v-container>
</template>
