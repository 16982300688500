<script>
  export default {
    data() {
      return {
        code: "",
        invite: undefined,
        joining: false,
        creating: false,
      };
    },

    methods: {
      create() {
        this.creating = true;
        this.$store.state.socket.emit("set:create", {from: this.code});
      },

      joinInvite() {
        this.joining = true;
        this.$router.push({path: "/set/game/", query: {code: this.invite.code}});
      }
    },

    beforeMount() {
      this.$store.state.socket.on("set:create", (data) => {
        this.$router.push({path: "/set/game/", query: {code: data.code}});
      });

      this.$store.state.socket.on("set:invite", (data) => {
        this.invite = this.invite || data;
      });

      this.code = this.$router.currentRoute.query.code
    },

    destroyed() {
      this.$store.state.socket.off("set:create");
      this.$store.state.socket.off("set:invite");
    }
  };
</script>

<template>
  <v-fade-transition mode="out-in">
    <div :key="Boolean(invite)">
      <template v-if="Boolean(invite)">
        <v-btn
          color="success"
          x-large
          block
          v-on:click="joinInvite"
          :loading="joining"
        >
          Join new game
        </v-btn>
      </template>

      <template v-else>
        <v-btn
          color="secondary"
          x-large
          block
          v-on:click="create"
          :loading="creating"
        >
          Create New Game
        </v-btn>
      </template>
    </div>
  </v-fade-transition>
</template>
