<script>
  const CARDS = require("../../cards.json");

  export default {
    components: {
      card: require("./Card").default,
    },

    props: {
      cards: {
        type: Array,
        required: true
      }
    },

    computed: {
      inflatedCards() {
        return this.cards.map(c => CARDS[c]);
      },

      isSet() {
        return this.errors.length == 0;
      },

      propertyValueCounts() {
        let props = {
          color: {},
          number: {},
          symbol: {},
          shading: {},
        };

        Object.keys(props)
          .forEach(p => {
            this.inflatedCards.forEach(c => {
              props[p][c[p]] = props[p][c[p]] || 0;
              props[p][c[p]] += 1;
            });
          });

        return props;
      },

      propertyCounts() {
        let counts = {};

        Object.keys(this.propertyValueCounts)
          .forEach(p => {
            counts[p] = Object.keys(this.propertyValueCounts[p]).length;
          });

        return counts;
      },

      complexityRating() {
        return Object.keys(this.propertyCounts)
          .filter(p => {
            return this.propertyCounts[p] == 3;
          })
          .length;
      },

      errors() {
        let errors = [];

        Object.keys(this.propertyValueCounts)
          .forEach(p => {
            Object.keys(this.propertyValueCounts[p])
              .forEach(v => {
                if (this.propertyValueCounts[p][v] == 2) {
                  errors.push(`Two "${v}" cards`);
                }
              })
          });

        return errors;
      },
    },

    watch: {
      isSet() {
        this.$emit("isSet", this.isSet)
      },
    },
  };
</script>

<template>
  <div>
    <div class="my-3 d-flex flex-row justify-center flex-wrap">
      <template v-for="c in cards">
        <card
          :index="c"
          :key="c"
          small
          class="ma-1"
        ></card>
      </template>
    </div>

    <div class="text-center primary--text" v-if="isSet">
      <v-rating
        length="4"
        :value="complexityRating"
        background-color="grey"
        :size="{
          'xs': 12,
          'sm': 16,
          'md': 22,
          'lg': 28,
          'xl': 36
        }[$vuetify.breakpoint.name]"
        readonly
      ></v-rating>
    </div>

    <div class="text-center error--text" v-if="errors.length > 0">
      <v-icon color="error" large>
        mdi-close-circle
      </v-icon>

      {{ errors.join(', ') }}
    </div>
  </div>
</template>
