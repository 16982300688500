<script>
  export default {
    components: {
      numbers: require("./numbers").default,
      words: require("./words").default,
    },
  }
</script>

<template>

  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <words></words>
      </v-col>

      <v-col cols="12" md="6">
        <numbers></numbers>
      </v-col>
    </v-row>
  </v-container>
</template>
