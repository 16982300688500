<script>
  export default {
    components: {
      emitter: require("./emitter").default,
      "led-board": require("./led-board").default,
    },

    data() {
      return {
        ledBoard: []
      };
    },

    methods: {
      randomizeLedBoard() {
        let leds = "";

        let rows = 4;
        let cols = 9;

        while (leds.length < rows * cols) {
          leds += Math.round(Math.random());
        }

        let re = new RegExp(`.{1,${cols}}`, "g");
        this.ledBoard = leds.match(re).map(r => String(r).split(""));
      }
    },

    beforeMount() {
      this.randomizeLedBoard();
    }
  }
</script>

<template>
  <div>
    <template v-if="false">
      <emitter></emitter>
    </template>

    <v-btn
      @click="randomizeLedBoard"
    >
      randomize
    </v-btn>

    <led-board
      :value="ledBoard"
    ></led-board>
  </div>
</template>
