<script>
  export default {
    inheritAttrs: false,

    props: {
      "_id": {
        required: true
      }
    },

    computed: {
      username() {
        return (this.$store.state.userIDs[this._id] || {}).username;
      },

      isMe() {
        return this._id == this.$store.state.me._id;
      }
    },

    beforeMount() {
      this.$store.state.socket.emit("user", {
        _id: this._id
      });
    },
  };
</script>

<template>
  <span :class="isMe ? 'me' : ''">
    {{ username }}
  </span>
</template>

<style scoped>
  .me {
    text-decoration-style: dotted;
    text-decoration-line: underline;
  }
</style>
