<script>
  export default {
    components: {
      presets: require("./presets").default,
      stats: require("./stats").default,
      chart: require("./chart").default,
      copy: require("../CopyButton").default,
    },

    data() {
      return {
        input: "",
        output: "",

        functions: undefined,
        functionInputs: undefined,
      };
    },

    computed: {
      functionOutputs() {
        return (this.functionInputs || []).slice(1);
      },
    },

    watch: {
      functions() {
        this.output = "";
        this.functionInputs = undefined;
      }
    },

    methods: {
      process() {
        let output = this.input;
        let inputs = [output]; // TODO: any reference issues here?

        this.functions
          .map(fn => {
            // TODO: show something if this errors
            return eval(fn.fnString);
          })
          .forEach(fn => {
            output = fn(output);
            inputs.push(output);
          });

        this.output = output;
        this.functionInputs = inputs;
      },

      onKeyDown(e) {
        if (e.key == "Enter" && e.ctrlKey) {
          e.preventDefault();
          this.process();
        }
      },
    },

    beforeMount() {
      document.addEventListener("keydown", this.onKeyDown);
    },

    destroyed() {
      document.removeEventListener("keydown", this.onKeyDown);
    },
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet class="px-5 pt-5 pb-0" style="display: grid; grid-template-columns: 1fr 300px; grid-gap: 20px; align-items: start;">
          <v-textarea
            v-model="input"
            label="Input"
            color="green"
            class="mb-0 pb-0"
            outlined
            @keydown.ctrl.enter="process"
          ></v-textarea>

          <!-- TODO -->
          <stats v-model="input" v-if="false"></stats>

          <chart v-model="input"></chart>
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <div class="mb-5 pl-16">
          <v-hover v-slot="{ hover }">
            <v-btn color="green" @click="process">
              <v-icon left>
                mdi-arrow-down
              </v-icon>

              Process

              <v-expand-x-transition mode="out-in">
                <span class="text-none text-caption" v-if="hover">
                  [ctrl + enter]
                </span>
              </v-expand-x-transition>
            </v-btn>
          </v-hover>
        </div>

        <presets
          v-model="functions"
          :inputs="functionInputs"
          :outputs="functionOutputs"
        ></presets>

        <div class="mt-5 pl-16">
          <v-icon color="green" large>
            mdi-arrow-down
          </v-icon>
        </div>
      </v-col>

      <v-col cols="12">
        <v-sheet class="px-5 pt-5 pb-0" style="display: grid; grid-template-columns: 1fr 300px; grid-gap: 20px; align-items: start;">
          <v-textarea
            v-model="output"
            label="Output"
            color="green"
            class="mb-0 pb-0"
            readonly
            outlined
          ></v-textarea>

          <div class="text-center">
            <copy v-model="output"></copy>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
  .ghost {
    opacity: 0.5 !important;
  }
</style>
