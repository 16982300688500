<script>
  const CARDS = require("../../cards.json");

  const isSet = cards => {
    let props = {};

    cards
      .map(c => CARDS[c])
      .forEach(c => {
        ["color", "number", "symbol", "shading"].forEach(p => {
          props[c[p]] =  props[c[p]] || 0;
          props[c[p]] += 1;
        })
      });

    return Object.keys(props).filter(p => props[p] == 2).length == 0;
  };

  export default {
    components: {
      "analyze-set": require("./AnalyzeSet").default,
    },

    data() {
      return {
        validSet: [],
        invalidSet: [],
        cards: [0, 1, 2],
        dialog: false,
      };
    },

    methods: {
      get3RandomCards() {
        return CARDS
          .map((c, i) => i)
          .sort(() => {
            return Math.random() < Math.random() ? 1 : -1;
          })
          .slice(0, 3);
      },

      setRandomValidSet() {
        this.validSet = this.get3RandomCards();

        if (!isSet(this.validSet)) {
          this.setRandomValidSet();
        }
      },

      setRandomInvalidSet() {
        this.invalidSet = this.get3RandomCards();

        if (isSet(this.invalidSet)) {
          this.setRandomInvalidSet();
        }
      },
    },

    mounted() {
      this.setRandomValidSet();
      this.setRandomInvalidSet();
    }
  };
</script>

<template>
  <div>
    <v-btn light small v-on:click="dialog = true">
      How to Play
    </v-btn>

    <v-dialog v-model="dialog" width="800" scrollable>
      <v-card light>
        <v-card-title>
          How To Play
        </v-card-title>

        <v-card-text>
          <h2 class="my-2">
            Cards
          </h2>

          <p>
            There are 81 cards in Set. Each card contains shapes with 4 <em>features</em>:
          </p>

          <ul>
            <li>
              <strong>Color</strong>: Green, Red, or Purple
            </li>

            <li>
              <strong>Symbol</strong>: Diamond, Oval, or Squiggle
            </li>

            <li>
              <strong>Shading</strong>: Solid, Striped, or Open
            </li>

            <li>
              <strong>Number</strong>: 1, 2, or 3 of the same shape
            </li>
          </ul>

          <v-divider class="my-4"></v-divider>

          <h2 class="my-2">
            Gameplay
          </h2>

          <p>
            When the game begins, the cards will be shuffled and 12 cards will be dealt to form the <em>table</em>.
          </p>

          <p>
            Your objective is to be the first player to find 3 cards that form a valid <em>Set</em>, choosing from the cards on the <em>table</em>.
            A <em>Set</em> is <strong>invalid</strong> if <strong>exactly 2</strong> of the cards share the same <em>feature</em>.
            All other groups for 3 cards are valid.
          </p>

          <v-card class="text-center pa-4 mb-4 grey lighten-4">
            <h3 class="success--text">Valid Sets</h3>

            <analyze-set
              :cards="validSet"
              class="ma-3"
            ></analyze-set>

            <div class="success--text">
              <v-icon color="success" large class="mr-1">
                mdi-check-circle
              </v-icon>

              <strong>Exactly 2 cards</strong> do <strong>not</strong> have the same color, symbol, shading, or number
            </div>

            <v-btn @click="setRandomValidSet" color="success" class="ma-3">
              Show me another
            </v-btn>
          </v-card>

          <v-card class="text-center pa-4 mb-4 grey lighten-4">
            <h3 class="error--text">Invalid Sets</h3>

            <analyze-set
              :cards="invalidSet"
              class="ma-3"
            ></analyze-set>

            <v-btn @click="setRandomInvalidSet" color="error" class="ma-3">
              Show me another
            </v-btn>
          </v-card>

          <p>
            When a player succesfully finds a valid <em>Set</em>, 3 more cards will be dealt to keep the total number of cards on the <em>table</em> equal to 12.
            If there are no <em>Sets</em> possible after cards have been dealt, 3 additional cards will automatically be dealt.
          </p>

          <p>
            The game ends when all cards have been dealt and there are no more <em>Sets</em> possible.
            The player with the most points wins.
          </p>

          <v-divider class="my-4"></v-divider>

          <h2 class="my-2">
            Scoring
          </h2>

          <p>
            You score 1 point for each valid <em>Set</em> found.
            You lose 1 point if you incorrectly identify a <em>Set</em>.
          </p>

          <p>
            Once you begin selecting cards, you have a limited amount of time to select a total of 3 cards.
            If you take too long, you will lose 1 point.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
