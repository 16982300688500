var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Boolean(_vm.inputSummary))?_c('div',{class:{
    circles: _vm.circles,
    grayscale: !_vm.color,
  },staticStyle:{"background":"#000"},style:(_vm.styles)},_vm._l((_vm.inputSummaryArray),function(c,i){return _c('div',{directives:[{name:"anime",rawName:"v-anime",value:({
      duration: _vm.$anime.random(1000, 2000),
      translateX: [0, _vm.$anime.random(-10, 10)],
      translateY: [0, _vm.$anime.random(-10, 10)],
      loop: true,
    }),expression:"{\n      duration: $anime.random(1000, 2000),\n      translateX: [0, $anime.random(-10, 10)],\n      translateY: [0, $anime.random(-10, 10)],\n      loop: true,\n    }"}],key:i,staticClass:"cell",style:({
      background: ("rgb(" + (c.red) + ", " + (c.green) + ", " + (c.blue) + ")"),
      transform: ("scale(" + (c.lumins / 255) + ")"),
    })})}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }