<script>
  const modes = {
    FILL: "fill",
    X: "x"
  };

  export default {
    extends: require("./Play").default,

    components: {
      chamber: require("./CellChamber").default,
      copy: require("../CopyButton").default,
      mode: require("./Mode").default,
    },

    props: {
      solution: {
        required: false
      }
    },

    data() {
      return {
        id: "",
        mode: modes.FILL,
        saving: false,
        selected$cells: [],
      };
    },

    computed: {
      formattedCells() {
        return this.cells.map(c => {
          return {
            ...c,
            solution: c.filled
          };
        });
      },

      solved() {
        return false;
      }
    },

    watch: {
      /*
      cells: {
        handler() {
          // TODO: is this expensive? maybe we should just run it every 10 seconds or so...
          localStorage.setItem("picross:lastCells", JSON.stringify(this.cells));
        },
        deep: true
      }
      */
    },

    methods: {
      reset() {
        this.cells = new Array(100)
          .fill("")
          .map(() => {
            return {
              filled: false
            };
          });
      },

      save() {
        this.saving = true;

        this.$store.state.socket.on("picross:_id", data => {
          this.$store.state.socket.off("picross:_id");

          this.saving = false;

          this.id = data;
        });

        setTimeout(() => {
          this.$store.state.socket.emit("picross:create", {
            cells: this.cells.map(c => c.filled ? 1 : 0).join("")
          });
        }, 500);
      },

      onChamberUpdateCoordinates(coords) {
        let cells = this.$refs.cell
          .filter($c => {
            let rects = $c.$el.getBoundingClientRect();

            let elCoords = {
              x1: rects.left,
              y1: rects.top,
              x2: rects.left + rects.width,
              y2: rects.top + rects.height
            };

            if (
              elCoords.x1 < coords.x2 && elCoords.x2 > coords.x1
              && elCoords.y1 < coords.y2 && elCoords.y2 > coords.y1
            ) {
              return true;
            }

            return false;
          });

        if (this.selected$cells.length != cells.length) {
          this.selected$cells = cells;
        }
      },

      onChamberSelect() {
        let trueProp = this.mode == modes.FILL ? 'filled' : 'blocked';
        let falseProp = this.mode == modes.FILL ? 'blocked' : 'filled';

        this.selected$cells
          .map($c => {
            return this.$refs.cell.indexOf($c);
          })
          .forEach(index => {
            this.cells[index][falseProp] = false;
            this.cells[index][trueProp] = true;
          });

        this.selected$cells = [];
      }
    },

    beforeMount() {
      let cells = localStorage.getItem("picross:lastCells");

      if (cells) {
        this.cells = JSON.parse(cells);
        return;
      }

      this.reset();
    },
  };
</script>

<template>
  <div>
    <div
      :style="{
        display: 'grid',
        'grid-template-columns': `repeat(${gridWidth}, 1fr)`,
        'grid-gap': '1px',
        'max-width': 'min(60vh, 100%)',
        'margin': '0 auto',
        'position': 'relative',
        'transition': 'all 0.2s',
      }"
    >
      <template
        v-for="(c, i) in cells"
      >
        <cell
          :filled.sync="c.filled"
          :key="i"
          v-on:update:filled="lastFillValue = $event"
          :prefer="lastFillValue"
          ref="cell"
        ></cell>
      </template>

      <chamber
        v-bind="{
          orthogonal: true,
          els: selected$cells.map($c => $c.$el)
        }"
        v-on:update:coordinates="onChamberUpdateCoordinates"
        v-on:select="onChamberSelect"
      ></chamber>
    </div>

    <v-divider class="ma-4"></v-divider>

    <div class="d-flex justify-space-between">
      <v-btn @click="reset">Reset</v-btn>

      <div v-if="id" class="d-flex align-center">
        <span class="grey--text text--lighten-1">Last saved Puzzle ID:</span>

        <span style="user-select: text;" class="mx-2">{{ id }}</span>

        <copy v-model="id"></copy>
      </div>

      <div>
        <v-btn @click="save" :loading="saving">Save</v-btn>
      </div>
    </div>

    <mode v-model="mode"></mode>
  </div>
</template>
