<script>
  const combinatorics = require("js-combinatorics");

  export default {
    components: {
      "analyze-set": require("./AnalyzeSet").default,
    },

    props: ["cards"],

    computed: {
      combos() {
        let combos = new combinatorics.Combination(this.cards, 3);
        combos = [...combos];
        return combos;
      },
    },
  };
</script>

<template>
  <div>
    <h1>DEBUG</h1>
    <v-card
      v-for="(s, i) in combos"
      :key="i" 
      class="pa-3 my-3 grey lighten-4"
      outlined
      elevation="2"
    >
      <analyze-set :cards="s" class="ma-3" ref="sets"></analyze-set>
    </v-card>
  </div>
</template>
