<script>
  import Card from "./Card";

  export default {
    components: {
      card: Card,
    },

    props: {
      cards: {
        type: Array,
        required: true
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      lockedSelectedIndeces: {
        type: Array,
        default() {
          return [];
        }
      }
    },

    data() {
      return {
        selectedIndeces: [],
      };
    },

    watch: {
      disabled() {
        this.selectedIndeces = [];
      },

      selectedIndeces() {
        this.$emit("update:selected", this.selectedIndeces);
      }
    },

    methods: {
      toggleCard(index) {
        let indexOf = this.selectedIndeces.indexOf(index);

        if (this.selectedIndeces.length == 3) {
          return;
        }

        if (indexOf == -1) {
          this.selectedIndeces.push(index);
        } else {
          this.selectedIndeces.splice(indexOf, 1);
        }
      },
    },
  };
</script>

<template>
  <v-container style="position: relative; min-height: 500px;">
    <v-row>
      <v-col>
        <v-scale-transition
          group
          style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px;"
          leave-absolute
        >
          <card
            v-for="(c, i) in cards"
            :key="c"
            :index="c"
            :selected="selectedIndeces.indexOf(i) > -1 || lockedSelectedIndeces.indexOf(i) > -1"
            :clickable="true"
            :style="{
              'justify-self': i % 3 == 0 ? 'flex-end' : i % 3 == 1 ? 'center' : 'flex-start'
            }"
            v-on:toggle="toggleCard(i)"
          ></card>
        </v-scale-transition>
      </v-col>
    </v-row>

    <v-overlay :value="disabled" absolute :opacity="0.7" style="z-index: 1;"></v-overlay>
  </v-container>
</template>
