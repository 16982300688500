<script>
  export default {
    components: {
      "phone-controller": require("../PhoneController/Host").default,
    },

    props: ["value", "state"],

    data() {
      return {
        input: undefined
      };
    },

    watch: {
      input() {
        this.$emit("input", this.input);
      }
    },

    methods: {
      handlePhoneControllerInput(data) {
        this.$set(this, "input", data.gamepad);
      }
    }
  };
</script>

<template>
  <div>
    <phone-controller
      v-on:input="handlePhoneControllerInput"
      :state="state"
    >
      <slot></slot>
      
    </phone-controller>
  </div>
</template>

<style scoped>
  pre {
    font-size: 12px;
  }
</style>
