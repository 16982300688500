<script>
  export default {
    props: ["value"],

    data() {
      return {
        input: "fill",
      };
    },

    watch: {
      value() {
        this.input = this.value;
      },

      input() {
        this.$emit("input", this.input);
      },
    },

    computed: {
      color () {
        // TODO
        return 'blue-grey';
      },
    },
  }
</script>

<template>
  <v-bottom-navigation
    v-model="input"
    :background-color="color"
    shift
    grow
    app
  >
    <v-btn value="fill">
      <span>Fill</span>

      <v-icon>mdi-checkbox-blank</v-icon>
    </v-btn>

    <v-btn value="x">
      <span>Block</span>

      <v-icon>mdi-close-box</v-icon>
    </v-btn>

    <!-- TODO
    <v-btn>
      <span>Clear</span>

      <v-icon>mdi-nuke</v-icon>
    </v-btn>
    -->
  </v-bottom-navigation>
</template>
