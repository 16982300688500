export default {
  modules: {
    wordle: require("./components/Wordle/store.js").default,
  },

  state: {
    socket: {},
    me: {},
    userIDs: {},
    routeHistory: JSON.parse(localStorage.getItem("routeHistory") || "{}"),

    isMobile: false,
  },

  mutations: {
    setSocket(state, socket) {
      state.socket = socket;
    },

    setMe(state, data) {
      state.me = data;
    },

    setUser(state, user) {
      let userData = {};

      userData[user._id] = user;

      state.userIDs = {
        ...state.userIDs,
        ...userData
      };
    },

    addToRouteHistory(state, fullPath) {
      let routeData = {};

      routeData[fullPath] = new Date().getTime();

      state.routeHistory = {
        ...state.routeHistory,
        ...routeData,
      };

      localStorage.setItem("routeHistory", JSON.stringify(state.routeHistory));
    },

    removeFromRouteHistory(state, fullPath) {
      let history = JSON.parse(JSON.stringify(state.routeHistory));
      delete history[fullPath];
      state.routeHistory = history;

      localStorage.setItem("routeHistory", JSON.stringify(state.routeHistory));
    },

    setIsMobile(state, value) {
      state.isMobile = value;
    },
  },
};
