<script>
  export default {
    data() {
      return {
        x: 0,
        y: 0,
      };
    },

    watch: {
      x() {
        this.emit();
      },

      y() {
        this.emit();
      }
    },

    methods: {
      onMouseEvent(e) {
        this.x = Math.floor(e.clientX);
        this.y = Math.floor(e.clientY);
      },

      emit() {
        this.$emit("input", {x: this.x, y: this.y});
      }
    },

    beforeMount() {
      window.addEventListener("mousedown", this.onMouseEvent);
    },

    destroyed() {
      window.removeEventListener("mousedown", this.onMouseEvent);
    }
  }
</script>

<template>
  <div>
  </div>
</template>
