export default {
  namespaced: true,

  state: {
    words: [],
    history: [],
  },

  getters: {
    unusedEligibleWords(state) {
      let usedWords = {};

      state.history.forEach(h => {
        usedWords[h.word] = true;
      });

      return state.words
        .filter(w => w.eligible)
        .map(w => w.word)
        .filter(w => !usedWords[w]);
    },
  },

  mutations: {
    setWords(state, words) {
      state.words = words;
    },

    setHistory(state, history) {
      state.history = history;
    },

    addToHistory(state, data) {
      state.history.push(data);
    },

    clearHistory(state) {
      state.history = [];
    }
  },
};
