<script>
  export default {
    data() {
      return {
        input: ""
      };
    },

    computed: {
      numberInput() {
        return Number(this.input) || 0;
      }
    },

    watch: {
      numberInput() {
        this.$emit("input", this.numberInput);
      }
    },

    methods: {
      random() {
        this.input = Math.floor(Math.random() * 900 + 100);
      },
    },
  }
</script>

<template>
  <div class="d-flex align-center">
    <v-text-field
      v-model="input"
      label="Target"
      outlined
      hide-details
      maxlength="3"
      :color="!numberInput ? 'error' : 'success'"
      :append-icon="numberInput ? 'mdi-check-circle' : 'mdi-alert-circle-outline'"
    ></v-text-field>

    <v-btn
      @click="random"
      color="amber"
      class="ml-1"
    >
      <v-icon>mdi-dice-multiple</v-icon>
    </v-btn>
  </div>
</template>
