<script>
  export default {
    data() {
      return {
        text: `An old silent pond
          A frog jumps into the pond—
          Splash! Silence again.
        `.split("\n").map(l => l.trim()).join("\n"),
      };
    },

    methods: {
      getConfig(options={}) {
        return {
          rotate: [this.$anime.random(-120, 120), 0],
          duration: this.$anime.random(800, 1600),
          delay: this.$anime.random(0, 400),
          opacity: [0, 1],
          scale: [this.$anime.random(0, 4), 1],
          loop: false,
          ...options
        }
      }
    },
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <v-textarea v-model="text" outlined label="Text"></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-sheet
          class="text-center pa-2"
          color="primary lighten-3 grey--text text--darken-3"
          :key="text"
        >
          <div
            v-for="(l, lineIndex) in text.split('\n')"
            :key="'line' + lineIndex"
          >
            <span
              class="char"
              v-for="(c, charIndex) in l.split('')"
              :key="'char' + charIndex"
              v-anime="getConfig({delay: (lineIndex * 2000) + 500})"
            >{{ c }}</span>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .box {
    width: 200px;
    height: 200px;
    border-top: 5px solid blue !important;
  }

  .char {
    display: inline-block;
    white-space: pre;
    font-size: 3vw;
  }
</style>
