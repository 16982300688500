<script>
  const colors = [
    "red",
    "pink",
    "purple",
    "deep-purple",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange"
  ];

  export default {
    data() {
      return {
        particles: {},
        ttl: 5000,

        gcInterval: undefined,
      };
    },

    methods: {
      addParticle() {
        let time = new Date().getTime();
        let key = time + ":" + Math.random();

        this.$set(this.particles, key, {
          classes: colors[Math.floor(Math.random() * colors.length)],
          ttl: time + this.ttl
        });
      },

      removeExpiredParticles() {
        let time = new Date().getTime();

        Object.keys(this.particles)
          .filter(k => this.particles[k].ttl < time)
          .forEach(k => {
            delete this.particles[k];
          })
      }
    },

    beforeMount() {
      clearInterval(this.gcInterval);
      this.gcInterval = setInterval(this.removeExpiredParticles, this.ttl);

      setInterval(this.addParticle, 500);
    },

    destroyed() {
      clearInterval(this.gcInterval);
    }
  }
</script>

<template>
  <div class="root">
    <div
      v-for="k in Object.keys(particles)"
      :key="k"
      class="particle"
      :class="particles[k].classes"
      v-anime="{
        scale: [1, $anime.random(0,4)],
        opacity: [1, 0],
        rotate: [$anime.random(-360, 360), $anime.random(-360, 360)],
        translateX: [0, $anime.random(-50, 50) + 'vw'],
        translateY: [0, $anime.random(-50, 50) + 'vh'],
        easing: 'linear',
        duration: ttl,
      }"
    ></div>
  </div>
</template>

<style scoped>
  .root {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .particle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 100%;
  }
</style>
