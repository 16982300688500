<script>
  const combinatorics = require("js-combinatorics");

  const getOperatorPermutationsOfLength = l => {
    return new combinatorics.BaseN("+-*/", l).toArray();
  }

  const convertNumbersAndOperatorsToEquation = (numbers, operators) => {
    let result = "";

    if (numbers.length == 1) {
      return String(numbers[0]);
    }

    operators.forEach((o, i) => {
      result += `${numbers[i]} ${o} `;
    });
    result += `${numbers[numbers.length - 1]}`;

    return result;
  }

  const operatorPermutationsByLength = {
    0: [undefined],
    1: getOperatorPermutationsOfLength(1),
    2: getOperatorPermutationsOfLength(2),
    3: getOperatorPermutationsOfLength(3),
    4: getOperatorPermutationsOfLength(4),
    5: getOperatorPermutationsOfLength(5),
  }

  export default {
    props: {
      numbers: {
        type: Array,
        default() {
          return [];
        },
      },

      target: {
        type: Number
      }
    },

    data() {
      return {
        powerSets: [],
        permutations: [],
        equations: [],
        solutions: [],
        solutionsPercent: 0,
        solutionsPercentTimeout: undefined,
        correctSolutions: [],
        examplesByDifference: {},
        countsByDifference: {},

        promise: undefined,
      };
    },

    methods: {
      compute() {
        this.promise = Promise.resolve()
          .then(() => {
            this.powerSets = [];
            this.permutations = [];
            this.equations = [];
            this.solutions = [];
            this.solutionsPercent = 0;
            this.solutionsPercentTimeout = clearTimeout(this.solutionsPercentTimeout);
            this.correctSolutions = [];
            this.examplesByDifference = {};
            this.countsByDifference = {};
          })
          .then(() => {
            return this.computePowerSets();
          })
          .then(() => {
            return this.computePermutations();
          })
          .then(() => {
            return this.computeEquations();
          })
          .then(() => {
            return this.computeSolutions();
          })
          .finally(() => {
            setTimeout(() => {
              this.promise = undefined;
            }, 100);
          });
      },

      computePowerSets() {
        this.powerSets = new combinatorics.PowerSet(this.numbers)
          .toArray()
          .filter(c => c.length > 0)
          .sort((a, b) => { return a.length - b.length; });
      },

      computePermutations() {
        return this.powerSets
          .reduce((p, ps) => {
            return p.then(() => {
              this.permutations = this.permutations.concat(new combinatorics.Permutation(ps).toArray());
            });
          }, Promise.resolve());
      },

      computeEquations() {
        return this.permutations
          .reduce((p, c) => {
            return p.then(() => {
              return new Promise(r => {
                operatorPermutationsByLength[c.length - 1].forEach(o => {
                  this.equations.push(convertNumbersAndOperatorsToEquation(c, o));
                });

                setTimeout(r, 10);
              });
            });
          }, Promise.resolve())
      },

      computeSolutions() {
        return this.equations
          .reduce((p, e, i) => {
            return p.then(() => {
              return new Promise(r => {
                let solution = {
                  label: e,
                  value: eval(e),
                };

                this.solutions.push(solution);

                if (solution.value == this.target) {
                  this.correctSolutions.push(solution);
                }

                let difference = Math.abs(solution.value - this.target);
                if (Number.isInteger(difference) && difference < 50) {
                  this.examplesByDifference[difference] = solution;
                  this.countsByDifference[difference] = this.countsByDifference[difference] || 0;
                  this.countsByDifference[difference]++;
                }

                this.solutionsPercentTimeout = this.solutionsPercentTimeout || setTimeout(() => {
                  this.solutionsPercent = Math.ceil(i / this.equations.length * 100);
                  this.solutionsPercentTimeout = undefined;
                }, 500);

                setTimeout(r, 0);
              });
            });
          }, Promise.resolve());
      }
    }
  }
</script>

<template>
  <div>
    <v-btn
      @click="compute"
      :loading="promise !== undefined"
      :disabled="promise !== undefined"
    >
      compute
    </v-btn>

    <div>
      PowerSets: {{ powerSets.length.toLocaleString() }}
    </div>

    <div>
      Permutations: {{ permutations.length.toLocaleString() }}
    </div>

    <div>
      Equations: {{ equations.length.toLocaleString() }}
    </div>

    <div>
      Correct Solutions: {{ correctSolutions.length.toLocaleString() }}
      ({{ solutionsPercent }}%)
    </div>

    <div class="text-center" v-if="correctSolutions.length > 0">
      <div
        v-for="(eq, i) in correctSolutions"
        :key="i"
        class="my-2"
      >
        {{ eq.label }}
      </div>
    </div>

    <pre>{{ examplesByDifference }}</pre>
    <pre>{{ countsByDifference }}</pre>
  </div>
</template>
