<script>
  export default {
    components: {
      letter: require("./letter").default,
    },

    props: {
      value: {
        type: Array,
        required: true,
      },

      history: {
        type: Boolean,
        default: false
      }
    },
  }
</script>

<template>
  <div
    style="display: grid; grid-gap: 6px;"
    :style="{
       'grid-template-columns': `repeat(${value.length}, 1fr)`,
    }"
  >
    <template v-for="(item, index) in value">
      <letter
        v-bind="{...item, history: history}"
        :animation-delay="index * 50"
        :key="index"
      ></letter>
    </template>
  </div>
</template>
