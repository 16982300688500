var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"ma-2",attrs:{"type":"number","outlined":"","label":"Seed","hide-details":"","disabled":_vm.running,"loading":_vm.running},model:{value:(_vm.seed),callback:function ($$v) {_vm.seed=$$v},expression:"seed"}}),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"secondary","disabled":_vm.running || _vm.completed || _vm.seed == 0},on:{"click":_vm.addStepsUntilComplete}},[_vm._v(" GO! ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error","disabled":_vm.running},on:{"click":_vm.reset}},[_vm._v(" reset ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-expand-transition',[(_vm.completed)?_c('div',{staticClass:"text-center text-h4 primary--text"},[_c('v-icon',{attrs:{"color":"primary","x-large":""}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" "+_vm._s(_vm.steps.length)+" steps ")],1):_vm._e()]),_vm._l((_vm.steps.map(function (s) { return s; }).reverse()),function(s){return [_c('div',{key:s.id,staticClass:"collatz-number"},[_c('div',{directives:[{name:"anime",rawName:"v-anime",value:({
              rotate: [_vm.$anime.random(-90, 90), 0],
              opacity: [0, 1],
              duration: 300,
              maxHeight: ['0px', '120px'],
              scale: [0, 1],
            }),expression:"{\n              rotate: [$anime.random(-90, 90), 0],\n              opacity: [0, 1],\n              duration: 300,\n              maxHeight: ['0px', '120px'],\n              scale: [0, 1],\n            }"}],staticClass:"ma-2 light-green--text"},[_vm._v(" "+_vm._s(s.number)+" "),_c('span',{directives:[{name:"anime",rawName:"v-anime",value:({
                opacity: [0, 1],
                scale: [0, 1],
                delay: 300,
                duration: 300,
              }),expression:"{\n                opacity: [0, 1],\n                scale: [0, 1],\n                delay: 300,\n                duration: 300,\n              }"}],staticClass:"ml-8",staticStyle:{"position":"absolute"}},[('02468'.split('').indexOf(String(s.number).substr(-1)) > -1)?[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-division")]),_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-numeric-2")])]:[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-close")]),_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-numeric-3")]),_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-plus")]),_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-numeric-1")])]],2)])])]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }