<script>
  export default {
    components: {
      highlighter: require("./highlighter").default,
    },

    props: {
      value: {
        type: Boolean,
        default: false
      },

      letters: {
        type: String,
        default: ""
      },

      results: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        input: false,

        highlight: "",
      };
    },

    computed: {
      formattedResults() {
        return this.results.map(w => {
          let remaining = this.letters;

          w.split("").forEach(l => {
            remaining = remaining.replace(l, "");
          });

          return {
            word: w,
            length: w.length,
            remaining: remaining.split("").join(", "),
          };
        });
      },

      lengths() {
        return this.formattedResults
          .map(r => r.length)
          .filter((l, i, a) => a.indexOf(l) == i)
          .sort((a, b) => b - a);
      },
    },

    watch: {
      value() {
        this.input = this.value;
      },

      input() {
        this.$emit("input", this.input);
      },
    },
  }
</script>

<template>
  <v-dialog
    v-model="input"
    fullscreen
    scrollable
  >
    <v-card tile>
      <v-card-title class="d-block text-center">
        <highlighter
          :text="letters"
          :highlight="highlight"
          class="text-h2 pt-2 pb-4"
        ></highlighter>
      </v-card-title>

      <v-card-text>
          <!--
          <div class="grey--text ma-2">
            {{ formattedResults.length }} result{{ formattedResults.length == 1 ? '' : 's' }}
          </div>
          -->

        <template>
          <div
            v-for="l in lengths"
            :key="l"
            class="mb-5"
          >
            <div class="d-flex align-center">
              <div class="grey--text">
                {{ l }} letter words
              </div>

              <v-divider class="ml-5"></v-divider>
            </div>

            <template v-for="r in formattedResults.filter(r => r.length == l)">
              <v-chip
                class="ma-2"
                :input-value="highlight == r.word"
                outlined
                large
                label
                @mouseenter="highlight = r.word"
                :key="r.word"
              >
                {{ r.word }}
              </v-chip>
            </template>
          </div>
        </template>
      </v-card-text>

      <v-card-actions class="">
        <v-spacer></v-spacer>

        <v-btn
          @click="input = false"
          fab
          small
          outlined
          class="my-2"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
