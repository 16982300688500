<script>
  export default {
    data() {
      return {
        seed: 27n,
        steps: [],
        running: false,
      };
    },

    computed: {
      completed() {
        return this.steps.slice(-3).map(s => s.number).join(",") == "4,2,1";
      },
    },

    watch: {
      seed() {
        this.steps = [];
      }
    },

    methods: {
      reset() {
        this.seed = this.$anime.random(10, 99999);
        this.steps = [];
      },

      addStep() {
        if (this.steps.length == 0) {
          this.steps.push({
            id: Math.random(),
            number: window.BigInt(this.seed),
          });

          return;
        }

        let number = this.steps.slice(-1)[0].number;

        if (number % 2n == 0n) {
          number = number / 2n;
        } else {
          number = (3n * number) + 1n;
        }

        this.steps.push({
          id: Math.random(),
          number: number,
        })
      },

      async addStepsUntilComplete() {
        if (this.running) {
          return;
        }

        this.running = true;

        let timeout = 400;

        while (!this.completed) {
          await new Promise(r => setTimeout(r, timeout));
          this.addStep();
          timeout = Math.max(timeout - 5, 100);
        }

        this.running = false;
      }
    }
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center"
      >
        <v-text-field
          v-model="seed"
          type="number"
          outlined
          label="Seed"
          hide-details
          :disabled="running"
          :loading="running"
          class="ma-2"
        ></v-text-field>

        <v-btn
          @click="addStepsUntilComplete"
          color="secondary"
          :disabled="running || completed || seed == 0"
          class="ma-2"
        >
          GO!
        </v-btn>

        <v-btn
          @click="reset"
          color="error"
          :disabled="running"
          class="ma-2"
        >
          reset
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-expand-transition>
          <div v-if="completed" class="text-center text-h4 primary--text">
            <v-icon color="primary" x-large>mdi-check-circle-outline</v-icon>
            {{ steps.length }} steps
          </div>
        </v-expand-transition>

        <template v-for="s in steps.map(s => s).reverse()">
          <div class="collatz-number" :key="s.id">
            <div
              class="ma-2 light-green--text"
              v-anime="{
                rotate: [$anime.random(-90, 90), 0],
                opacity: [0, 1],
                duration: 300,
                maxHeight: ['0px', '120px'],
                scale: [0, 1],
              }"
            >
              {{ s.number }}

              <span
                class="ml-8"
                style="position: absolute;"
                v-anime="{
                  opacity: [0, 1],
                  scale: [0, 1],
                  delay: 300,
                  duration: 300,
                }"
              >
                <template
                  v-if="'02468'.split('').indexOf(String(s.number).substr(-1)) > -1"
                >
                  <v-icon color="secondary">mdi-division</v-icon>
                  <v-icon color="secondary">mdi-numeric-2</v-icon>
                </template>

                <template v-else>
                  <v-icon color="secondary">mdi-close</v-icon>
                  <v-icon color="secondary">mdi-numeric-3</v-icon>
                  <v-icon color="secondary">mdi-plus</v-icon>
                  <v-icon color="secondary">mdi-numeric-1</v-icon>
                </template>
              </span>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .collatz-number {
    text-align: center;
    transition: all 0.2s;
    font-size: 16px;
    opacity: 0.6;
  }

  .collatz-number:nth-child(1) {
    font-size: 64px;
    opacity: 1;
  }

  .collatz-number:nth-child(2) {
    font-size: 48px;
    opacity: 0.9;
  }

  .collatz-number:nth-child(3) {
    font-size: 32px;
    opacity: 0.8;
  }

  .collatz-number:nth-child(4) {
    font-size: 24px;
    opacity: 0.7;
  }
</style>
