<script>
  export default {
    props: {
      code: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        opened: false,

        messages: [],
        unread: 0,

        thingsToSay: [
          { message: "Hmm...", emojis: ["🤔", "🙄", "🙃", "😰"] },
          { message: "Impossible!", emojis: ["🤷", "🤯"] },
          { message: "Oh duh", emojis: ["🤦", "🤪"] },
          { message: "Oops", emojis: ["😬", "🥴"] },
          { message: "Great Job!", emojis: ["🎉", "🥳", "👏", "👍", "💸", "💯", "💥", "🙌", "🏆", "🏅", "🧠", "💪"] },
        ]
      };
    },

    watch: {
      opened() {
        if (this.opened) {
          this.unread = 0;

          setTimeout(() => {
            this.scrollToBottom();
          }, 100);
        }
      },
    },

    methods: {
      say(thing) {
        let emoji = thing.emojis[Math.floor(Math.random() * thing.emojis.length)];

        this.$store.state.socket.emit("user:say", {
          code: this.code,
          message: `${thing.message} ${emoji}`.trim(),
        });
      },

      addMessage(data) {
        let duplicateMessage = this.messages
          .slice(-50)
          .find(m => m.created == data.created && m.user._id == data.user._id);

        if (duplicateMessage) {
          return;
        }

        this.messages.push(data);
        if (!this.opened) {
          this.unread++;
        }

        this.scrollToBottom();
      },

      scrollToBottom() {
        this.$nextTick(() => {
          this.$refs.scrollBottom && this.$refs.scrollBottom.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
          });
        });
      },
    },

    mounted() {
      this.$store.state.socket.on("user:say", data => {
        this.addMessage(data);
      });
    }
  };
</script>

<template>
  <div>
    <v-badge
      color="yellow darken-4"
      :value="unread"
      :content="unread"
      overlap
      bottom
    >
      <v-btn icon elevation="2" v-on:click="opened = true">
        <v-icon>mdi-chat</v-icon>
      </v-btn>
    </v-badge>

    <v-bottom-sheet v-model="opened">
      <v-sheet class="d-flex flex-column grey lighten-4" light>
        <div class="text-right">
          <v-btn icon color="grey darken-3" @click="opened = false">
            <v-icon>
              mdi-close-circle-outline
            </v-icon>
          </v-btn>
        </div>

        <div class="d-flex justify-center pa-2">
          <div style="max-width: 1024px; width: 100%;">
            <div style="height: 240px; max-height: 50vh; overflow: auto;">
              <div class="d-flex my-2" v-for="d in messages" :key="d.created">
                <div class="mr-4">
                  {{ d.user.username }}
                </div>

                <v-card
                  class="pa-3 rounded-tl-0 rounded-xl"
                  color="secondary lighten-4"
                  elevation="3"
                >
                  {{ d.message }}
                </v-card>
              </div>

              <div ref="scrollBottom"></div>
            </div>

            <v-divider class="ma-2"></v-divider>

            <div class="text-center">
              <v-btn
                v-for="(t, i) in thingsToSay"
                :key="i"
                @click="say(t)"
                class="ma-1"
                small
              >
                {{ t.message }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
