<script>
  export default {
    props: ["hourDeg", "minuteDeg", "secondDeg"],

    components: {
      face: require("./face").default,
      hand: require("./hand").default,
    },
  }
</script>

<template>
  <div class="analog-clock">
    <face></face>

    <hand :deg="secondDeg" class="second"></hand>

    <hand :deg="minuteDeg" class="minute"></hand>

    <hand :deg="hourDeg" class="hour"></hand>
  </div>
</template>

<style scoped>
  .analog-clock {
    aspect-ratio: 1;
    width: 50%;
    position: relative;
  }
</style>
