<script>
  // TODO: list of cognitive distortions
  // TODO: 3 column thing (5?)

  export default {
    components: {
      bdc: require("./bdc.vue").default,
    },

    data() {
      return {
      };
    },
  }
</script>

<template>
  <div>
    <bdc></bdc>
  </div>
</template>
