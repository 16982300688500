<script>
  export default {
    components: {
      "progress-button": require("./progress-button.vue").default,
    },

    data() {
      return {
        opened: false,
      };
    },
  }
</script>

<template>
  <div>
    <v-btn
      @click="opened = true"
      color="secondary"
      icon
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <v-dialog
      v-model="opened"
      max-width="550px"
      scrollable
    >
      <v-card>
        <v-card-title>
          Danger Zone
        </v-card-title>

        <v-card-text>
          <div class="mb-5">
            Data about your current word and previous guesses are saved in your browser.
            Deleting this data will reset your stats and may cause you to see repeated words that had previously been solved.
            This action cannot be undone.
          </div>

          <div class="text-center">
            <progress-button
              class="error"
              @confirm="$emit('reset')"
            >
              Delete Saved Data
            </progress-button>
          </div>
        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>

          <v-btn
            @click="opened = false"
            class="my-2"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
