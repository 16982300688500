<script>
  import moment from 'moment';

  export default {
    filters: {
      timeSince(time) {
        return new moment(time).fromNow();
      },
    },

    computed: {
      history() {
        return Object.keys(this.$store.state.routeHistory)
          .filter(k => k != "/")
          .filter(k => k.indexOf("/history") != 0)
          .map(k => {
            return {
              fullPath: k,
              time: this.$store.state.routeHistory[k]
            }
          })
          .sort((a, b) => {
            return b.time - a.time;
          });
      }
    }
  };
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <div v-if="history.length == 0" class="text-center">
          - no history available -
        </div>

        <v-scale-transition group>
          <v-card
            v-for="h in history"
            :key="h.time"
            class="pa-2 my-8"
          >
            <v-card-title>
              {{ h.fullPath }}
            </v-card-title>

            <v-card-subtitle>
              {{ h.time | timeSince }}
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="$router.push({path: h.fullPath})"
              >
                <v-icon>mdi-keyboard-return</v-icon>
                Go Here
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                color="error"
                text
                @click="$store.commit('removeFromRouteHistory', h.fullPath)"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-scale-transition>
      </v-col>
    </v-row>
  </v-container>
</template>
