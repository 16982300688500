<script>
  const Icons = require("./meta.json");

  let timeout = undefined;

  export default {
    components: {
      icon: require("./icon").default,
    },

    data() {
      return {
        filter: "",
        icons: Icons.map(i => {
          return {
            ...i,
            meta: (i.name + " " + i.tags.concat(i.aliases).join(" ")).toLowerCase(),
          };
        }),
        maxIcons: 100,
        copySuccessString: "",
      };
    },

    computed: {
      filteredIcons() {
        let filter = this.filter.trim().toLowerCase();

        return this.icons
          .filter(i => {
            return i.meta.indexOf(filter) > -1;
          })
          .map(i => i.name)
          .sort((a, b) => {
            if (a == filter) {
              return -1;
            }

            if (a < b) {
              return -1;
            }

            if (a > b) {
              return 1;
            }

            return 0;
          });
      },
    },

    watch: {
      filter() {
        if (this.filter === null) {
          this.filter = "";
        }

        this.maxIcons = 100;
      },

      copySuccessString() {
        if (!this.copySuccessString) {
          return;
        }

        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.copySuccessString = ""
        }, 2000);
      }
    },

    methods: {
      copyIcon(icon) {
        let text = "mdi-" + icon;

        this.copySuccessString = `"${text}" has been copied to your clipboard`;

        navigator.clipboard.writeText(text);
      }
    }
  }
</script>

<template>
  <v-row>
    <v-col>
      <v-card color="blue-grey darken-2">
        <v-card-title>
          <v-text-field
            v-model="filter"
            prepend-inner-icon="mdi-magnify"
            hide-details
            outlined
            clearable
            autofocus
            @keydown.esc="filter = ''"
          ></v-text-field>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <div
            class="d-flex flex-wrap"
          >
            <icon
              v-for="(icon, i) in filteredIcons.slice(0, maxIcons)"
              :key="icon + i"
              :value="icon"
              @click="copyIcon(icon)"
            ></icon>
          </div>

          <template v-if="filteredIcons.length > maxIcons">
            <v-divider></v-divider>

            <div class="text-center my-4">

              +{{ filteredIcons.length - maxIcons }} more

              <v-btn
                @click="maxIcons += 100"
                color="primary"
                x-large
              >
                Show {{ Math.min(filteredIcons.length - maxIcons, 100) }} More
              </v-btn>

              <v-btn
                v-if="filteredIcons.length - maxIcons > 100"
                x-large
                @click="maxIcons = filteredIcons.length"
              >
                Show All
              </v-btn>
            </div>
          </template>

          <template v-if="filteredIcons.length == 0">
            <div class="text-center text-h6 pa-5">
              - no icons found -
            </div>
          </template>
        </v-card-text>

        <v-slide-y-transition>
          <v-snackbar
            v-if="copySuccessString"
            :value="true"
            color="success"
            centered
          >
            <div class="d-flex align-center">
              <div class="text-body-1">
                {{ copySuccessString }}
              </div>
              <v-icon class="ml-5" large>mdi-thumb-up</v-icon>
            </div>
          </v-snackbar>
        </v-slide-y-transition>
      </v-card>
    </v-col>
  </v-row>
</template>
