<script>
  export default {
    props: {
      deg: {
        type: Number,
        default: 100,
      },
    },

    data() {
      return {
        rotations: 0,
        styles: {}
      };
    },

    watch: {
      deg() {
        this.updateStyles();
      }
    },

    methods: {
      updateStyles() {
        let deg = this.deg;

        if (deg == 0) {
          this.rotations++;
        }

        deg = deg + (this.rotations * 360);

        this.$set(this.styles, "transform", `rotate(${deg}deg)`);
      }
    },

    mounted() {
      this.updateStyles();
    }
  }
</script>

<template>
  <div class="hand" :style="styles">
    <div class="line grey darken-3">
    </div>
  </div>
</template>

<style scoped>
  .hand {
    height: 100%;
    width: 100%;
    position: absolute;
    transition: all 0.1s;
  }

  .line {
    position: absolute;
  }

  .second .line {
    width: 2px;
    height: 45%;
    top: calc(5% + 1px);
    left: calc(50% - 1px);
    background: #aaa;
    border-radius: 1px;
  }

  .minute .line {
    width: 4px;
    height: calc(35% - 2px);
    top: calc(15% + 2px);
    left: calc(50% - 2px);
    border-radius: 2px;
  }

  .hour .line {
    width: 6px;
    height: calc(25% - 3px);
    top: calc(25% + 3px);
    left: calc(50% - 3px);
    border-radius: 2px;
  }
</style>
