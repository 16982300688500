<script>
  const CARDS = require("../../cards.json");

  let CARD_PROPERTY_VALUES = {};

  CARDS.forEach(c => {
    Object.keys(c).forEach(k => {
      CARD_PROPERTY_VALUES[k] = CARD_PROPERTY_VALUES[k] || [];
      if (CARD_PROPERTY_VALUES[k].indexOf(c[k]) == -1) {
        CARD_PROPERTY_VALUES[k].push(c[k]);
      }
    })
  })

  export default {
    components: {
      user: require("./User").default,
    },

    props: ["users"],

    data() {
      return {
        property: "color",
      };
    },

    computed: {
      headers() {
        let headers = [
          { text: 'User', value: 'user', sortable: false, },
        ];

        if (this.property == "color") {
          headers = headers.concat([
            { text: 'Green', value: 'cardPropertyValues.color.green' },
            { text: 'Purple', value: 'cardPropertyValues.color.purple' },
            { text: 'Red', value: 'cardPropertyValues.color.red' },
          ]);
        }

        if (this.property == "number") {
          headers = headers.concat([
            { text: 'One', value: 'cardPropertyValues.number.1' },
            { text: 'Two', value: 'cardPropertyValues.number.2' },
            { text: 'Three', value: 'cardPropertyValues.number.3' },
          ]);
        }

        if (this.property == "symbol") {
          headers = headers.concat([
            { text: 'Diamond', value: 'cardPropertyValues.symbol.diamond' },
            { text: 'Oval', value: 'cardPropertyValues.symbol.oval' },
            { text: 'Squiggle', value: 'cardPropertyValues.symbol.squiggle' },
          ]);
        }

        if (this.property == "shading") {
          headers = headers.concat([
            { text: 'Striped', value: 'cardPropertyValues.shading.striped' },
            { text: 'Solid', value: 'cardPropertyValues.shading.solid' },
            { text: 'Open', value: 'cardPropertyValues.shading.open' },
          ]);
        }

        return headers;
      },

      items() {
        return this.users
          .map(u => {
            return {
              user: {
                _id: u._id,
              },
              cards: (u.correctSets || [])
                .map(s => s.cards)
                .flat()
                .map(c => CARDS[c])
            };
          })
          .map(d => {
            let cardPropertyValues = JSON.parse(JSON.stringify(CARD_PROPERTY_VALUES));

            Object.keys(cardPropertyValues).forEach(p => {
              let values = {};

              cardPropertyValues[p].forEach(v => {
                values[v] = 0;
              });

              cardPropertyValues[p] = values;
            });

            d.cards.forEach(c => {
              Object.keys(cardPropertyValues)
                .forEach(p => {
                  cardPropertyValues[p][c[p]] += 1;
                })
            });

            return {
              user: d.user,
              cardPropertyValues: cardPropertyValues,
              color: {
                green: d.cards.reduce((a, b) => a + (b.color == "green" ? 1 : 0), 0)
              }
            };
          });
      },
    }
  };
</script>

<template>
  <div>
    <div class="text-right my-4">
      <span class="text-caption">
        Show correct card counts by:
      </span>

      <v-btn-toggle v-model="property" color="primary">
        <v-btn value="color">
          Color
        </v-btn>

        <v-btn value="number">
          Number
        </v-btn>

        <v-btn value="symbol">
          Symbol
        </v-btn>

        <v-btn value="shading">
          Shading
        </v-btn>
      </v-btn-toggle>
    </div>

    <v-fade-transition mode="out-in">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        disable-pagination
        hide-default-footer
        :key="property"
      >
        <template v-slot:item.user="{ item }">
          <user v-bind="item.user"></user>
        </template>
      </v-data-table>
    </v-fade-transition>
  </div>
</template>
