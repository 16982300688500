<script>
  let timeout;

  const vibrate = (ms = 300) => {
    try {
      window.navigator.vibrate([ms]);
    } catch (e) {
      console.warn("error trying to vibrate", e);
    }
  }

  export default {
    components: {
      touch: require("./touch").default,
    },

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        targetTime: undefined,
        ghostTouches: {},
        touches: {},
      }
    },

    computed: {
      sortedTimes() {
        let times = [];

        Object.keys(this.ghostTouches)
          .forEach(id => {
            times.push(this.ghostTouches[id].msSinceTargetTime);
          });

        times.sort((a, b) => {
          if (a < b) {
            return -1;
          }

          if (a > b) {
            return 1;
          }

          return 0;
        });

        return times;
      }
    },

    methods: {
      reset() {
        this.$set(this, "ghostTouches", {});
        this.$set(this, "touches", {});
        this.targetTime = undefined;
      },

      setTouchesFromValue() {
        this.$set(this, 'touches', {
          ...this.touches,
          ...this.value
        });
      },

      setGhostTouchByID(id) {
        this.$set(this.ghostTouches, id, {
          ...this.touches[id],
          id: id,
          msSinceTargetTime: new Date().getTime() - this.targetTime,
        });
      },
    },

    beforeMount() {
      this.$watch(() => { return Object.keys(this.value).join(","); }, () => {
        clearTimeout(timeout);

        if (this.targetTime) {
          Object.keys(this.touches)
            .filter(id => !this.ghostTouches.hasOwnProperty(id))
            .filter(id => !this.value.hasOwnProperty(id))
            .forEach(id => {
              this.setGhostTouchByID(id);
            });
        }

        if (Object.keys(this.ghostTouches).length > 0) {
          if (Object.keys(this.value).length == 0) {
            setTimeout(this.reset, 6000);
          }

          return;
        }

        if (Object.keys(this.value).length < 2) {
          return;
        }

        timeout = setTimeout(() => {
          this.setTouchesFromValue();
          this.targetTime = new Date().getTime();

          vibrate();
        }, Math.floor(Math.random() * 5000) + 2000);
      });
    }
  }
</script>

<template>
  <div>
    <v-fade-transition mode="out-in">
      <div
        :key="Object.keys(value).length"
        v-if="Object.keys(value).length >= 2"
      >
        <template v-if="targetTime">
          <div class="text-center text-h3 pt-10 red--text">
            <v-icon color="red" class="text-h2">
              mdi-alert-octagon-outline
            </v-icon>

            LET GO!
          </div>
        </template>

        <template v-else>
          <div class="text-center headline pt-10">
            Don't let go
          </div>
        </template>
      </div>
    </v-fade-transition>

    <touch
      v-for="id in Object.keys(ghostTouches)"
      :key="id"
      :id="id"
      v-bind="ghostTouches[id]"
      style="transform: scale(1.2) !important"
      class="flex-column"
    >
      <div class="headline">
        #{{ sortedTimes.indexOf(ghostTouches[id].msSinceTargetTime) + 1 }}
      </div>

      <div class="caption text-center">
        {{ ghostTouches[id].msSinceTargetTime / 1000 }}

        <div>
          seconds
        </div>
      </div>
    </touch>
  </div>
</template>
