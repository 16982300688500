<script>
  export default {
    components: {
      "select-target": require("./select-target").default,
      "select-numbers": require("./select-numbers").default,
      "equations": require("./equations").default,
      "guess": require("./guess").default,
    },

    data() {
      return {
        numbers: [],
        target: undefined,
      };
    },
  }
</script>

<template>
  <div>
    <div class="text-center text-h3 mb-4">
      Numbers
    </div>

    <select-target
      v-model="target"
    ></select-target>

    <v-divider class="mx-8 my-3"></v-divider>

    <select-numbers
      v-model="numbers"
    ></select-numbers>

    <v-divider class="mx-8 my-3"></v-divider>

    <guess
      :numbers="numbers"
      :target="target"
    ></guess>

    <v-divider class="mx-8 my-3"></v-divider>

    <equations
      :numbers="numbers"
      :target="target"
    ></equations>
  </div>
</template>
