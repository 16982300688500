<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      },
    },

    data() {
      return {
        active: false,
      };
    },

    mounted() {
      setTimeout(() => {
        this.active = false;
      }, 1000);
    }
  }
</script>

<template>
  <v-expand-transition>
    <div v-if="active && show" class="text-center">
      <v-btn
        color="primary darken-2"
        large
        @click="$store.state.socket.emit('mastermind:join')"
      >
        Join
      </v-btn>
    </div>
  </v-expand-transition>
</template>
