<script>
  export default {
    name: "icon",

    props: ["value"],
  }
</script>

<template>
  <div
    class="ma-3 pa-3 d-flex flex-column blue-grey darken-3 rounded rd-icon"
    v-on="$listeners"
  >
    <v-icon
      x-large
      v-anime="{
        rotate: [$anime.random(-90, 90), 0],
        scale: [0, 1],
        opacity: [0, 1],
        duration: 200,
      }"
    >
      mdi-{{ value }}
    </v-icon>
    {{ value }}
  </div>
</template>

<style scoped>
  .rd-icon {
    transition: all 0.2s;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
    .rd-icon:hover {
      transform: scale(1.3);
      cursor: pointer;
      box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 1);
      z-index: 1;
    }
</style>
