<script>
  export default {
    components: {
      snacks: require("./snacks").default,
    },

    data() {
      return {
        target: 1668222600000,

        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined,
      }
    },

    methods: {
      update() {
        let remainingMilliseconds = this.target - new Date().getTime();

        this.days = Math.floor(remainingMilliseconds / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((remainingMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((remainingMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((remainingMilliseconds % (1000 * 60)) / 1000);

        setTimeout(this.update, 1000);
      },
    },

    beforeMount() {
      this.update();
    }
  }
</script>

<template>
  <v-container fill-height style="overflow: hidden; position: relative;">
    <v-row>
      <v-col
        cols="10"
        offset="1"
        class="green darken-3 py-5"
        style="border-radius: 20px; box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.5); opacity: 0.9; z-index: 100;"
      >
        <div style="display: grid; grid-template-columns: 1fr auto 1fr auto 1fr auto 1fr; justify-items: center;">
          <div class="d-flex flex-column align-center">
            <div class="text-h4">{{ days }}</div>
            <div class="text-caption grey--text text--lighten-2">
              DAY{{ days == 1 ? '' : 'S' }}
            </div>
          </div>

          <div class="text-h4 green--text text--darken-4">:</div>

          <div class="d-flex flex-column align-center">
            <div class="text-h4">{{ hours }}</div>
            <div class="text-caption grey--text text--lighten-2">
              HOUR{{ hours == 1 ? '' : 'S' }}
            </div>
          </div>

          <div class="text-h4 green--text text--darken-4">:</div>

          <div class="d-flex flex-column align-center">
            <div class="text-h4">{{ minutes }}</div>
            <div class="text-caption grey--text text--lighten-2">
              MINUTE{{ minutes == 1 ? '' : 'S' }}
            </div>
          </div>

          <div class="text-h4 green--text text--darken-4">:</div>

          <div class="d-flex flex-column align-center">
            <div class="text-h4">{{ seconds }}</div>
            <div class="text-caption grey--text text--lighten-2">
              SECOND{{ seconds == 1 ? '' : 'S' }}
            </div>
          </div>
        </div>
      </v-col>

      <v-col cols="12">
        <snacks></snacks>
      </v-col>
    </v-row>
  </v-container>
</template>


