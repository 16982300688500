<script>
  // TODO: maybe helpful? https://medium.com/dev-channel/the-gyroscope-65b02c4d23bd
  import VueQrcode from 'vue-qrcode';

  export default {
    components: {
      qr: VueQrcode,
    },

    props: ["state"],

    data() {
      return {
        socketid: undefined,

        dataFromClient: undefined,
        dataFromClientTimeout: undefined,
      };
    },

    computed: {
      clientURL() {
        let url = "https://"
          + window.location.host.replace("localhost", "192.168.1.152")
          + "/"
          + this.$router.resolve("phone-controller/client/" + this.socketid).href;

        if (this.state) {
          url += "/" + this.state;
        }

        return url;
      },
    },

    methods: {
      handleDataFromClient(data) {
        if (this.state && this.state != data.state) {
          return;
        }

        this.dataFromClient = {...this.dataFromClient, ...data};
        this.$emit("input", this.dataFromClient);

        clearTimeout(this.dataFromClientTimeout);
        this.dataFromClientTimeout = setTimeout(() => {
          this.dataFromClient = undefined;
        }, 30000);
      },
    },

    mounted() {
      this.$store.state.socket.on("socketid", socketid => {
        this.socketid = socketid;
      });

      this.$store.state.socket.on("socketid:client-to-host", this.handleDataFromClient);

      this.$store.state.socket.emit("socketid");
    }
  };
</script>

<template>
  <div>
    <div v-if="!Boolean(dataFromClient)" class="text-center">
      <template v-if="!socketid">
        - TODO: loading -
      </template>

      <template v-else>
        <qr :value="clientURL" style="width: 100%;"></qr>
      </template>
    </div>

    <div v-else>
      <slot>
      </slot>
    </div>
  </div>
</template>
