<script>
  export default {
    props: ["value"],

    data() {
      return {
        success: false,
        fail: false,
      };
    },

    watch: {
      success() {
        if (this.success) {
          setTimeout(() => {
            this.success = false;
          }, 5000);
        }
      },

      fail() {
        if (this.fail) {
          setTimeout(() => {
            this.fail = false;
          }, 5000);
        }
      }
    },

    methods: {
      onSuccess() {
        this.success = true;
      },

      onError() {
        this.fail = true;
        console.error(arguments);
      }
    }
  };
</script>

<template>
  <v-fade-transition mode="out-in">
    <div :key="'_' + success + fail">
      <template v-if="success">
        <span class="success--text">
          <v-icon color="success">mdi-check-circle-outline</v-icon>
          Copied
        </span>
      </template>

      <template v-else-if="fail">
        <span class="error--text">
          <v-icon color="error">mdi-close-circle-outline</v-icon>
          Error
        </span>
      </template>

      <template v-else>
        <v-btn
          outlined
          elevation="3"
          color="primary"
          v-clipboard:copy="value"
          v-clipboard:success="onSuccess"
          v-clipboard:error="onError"
        >
          <slot>
            <v-icon class="mr-1">mdi-content-copy</v-icon>
            Copy
          </slot>
        </v-btn>
      </template>
    </div>
  </v-fade-transition>
</template>
