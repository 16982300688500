<script>
  export default {
    data() {
      return {
        menu: false,
      };
    },
  }
</script>

<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    top
    left
    nudge-width="200"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="warning"
      >
        <v-icon
          left
        >
          mdi-alert
        </v-icon>

        Reset
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <p>
          Some players have not finished this round. Are you sure you want to reset?
        </p>
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          @click="menu = false"
        >
          Cancel
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="error"
          @click="[$store.state.socket.emit('mastermind:reset'), menu = false]"
        >
          Yes, Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
