<script>
  /* TODO
    - undo/redo? or something to solve the annoying state of overwriting a peg
    - better keyboard input?
      - copy/paste might be nice
      - when using spacebar to fill, (optionally?) auto-advance to next spot
        - some sort of "command prompt / magic input" might be cool...
          - start typing a command, autocomplete options, enter to submit
    - if any type of settings are added, store them in localstorage
  */
    

  export default {
    components: {
      guess: require("./guess").default,
      "code-peg": require("./code-peg").default,
      "peg-picker": require("./peg-picker").default,
    },

    props: {
      code: {
        type: Array,
        required: true,
      },

      state: {
        type: Object,
      },
    },

    data() {
      return {
        guesses: new Array(10)
          .fill("")
          .map(() => {
            return undefined;
          }),

        peg: 1,

        guessCluesSnapshot: undefined,
      };
    },

    computed: {
      activeGuessIndex() {
        return this.guesses.findLastIndex(g => {
          return g == undefined;
        });
      },

      correctGuessIndex() {
        return this.guessClues.findIndex(c => {
          return c?.correctPosition == 4;
        });
      },

      guessClues() {
        return this.guesses.map(this.getCluesForGuess);
      },

      gameOver() {
        return this.correctGuessIndex > -1 || this.activeGuessIndex == -1;
      },
    },

    watch: {
      guesses: {
        handler() {
          this.$emit("update", {
            guesses: JSON.parse(JSON.stringify(this.guesses)),
          });
        },
        deep: true
      },

      guessClues() {
        if (JSON.stringify(this.guessCluesSnapshot) == JSON.stringify(this.guessClues)) {
          return;
        }

        let guessClues = JSON.parse(JSON.stringify(this.guessClues));

        this.$emit("update", {
          guessClues: guessClues,
        });

        this.guessCluesSnapshot = guessClues;
      },

      gameOver() {
        this.$emit("update", {
          gameOver: this.gameOver
        });
      },
    },

    methods: {
      getCluesForGuess(guess) {
        if (!guess) {
          return;
        }

        let correctPosition = 0;
        let incorrectPosition = 0;

        let code = JSON.parse(JSON.stringify(this.code));
        guess = JSON.parse(JSON.stringify(guess));

        guess = guess.filter((v, i) => {
          if (code[i] == v) {
            correctPosition++;

            code[i] = undefined;

            return false;
          }

          return true;
        });

        guess.forEach(v => {
          if (code.indexOf(v) > -1) {
            incorrectPosition++;
            code[code.indexOf(v)] = undefined;
          }
        });

        return {
          correctPosition,
          incorrectPosition,
        };
      },
    },

    beforeMount() {
      if (this.state?.guesses) {
        this.guesses = JSON.parse(JSON.stringify(this.state.guesses));
      }
    }
  }
</script>

<template>
  <div>
    <div style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px;">
      <div class="d-flex align-center">
        <div>
          <peg-picker
            v-model="peg"
          ></peg-picker>
        </div>
      </div>

      <div class="board pa-3">
        <div class="board__row mt-0">
          <code-peg
            v-for="(p, i) in code"
            :value="p"
            :key="i"
            disabled
            :secret="!gameOver"
            prominent
          ></code-peg>
        </div>

        <template
          v-for="(g, i) in guesses"
        >
          <guess
            :value="g"
            @input="$set(guesses, i, $event)"
            :key="i"
            :clues="guessClues[i]"
            :peg="peg"
            :disabled="i != activeGuessIndex || correctGuessIndex > -1"
            class="board__row"
          ></guess>
        </template>
      </div>
    </div>

    <div v-if="gameOver" class="d-flex justify-space-between align-center">
      <template v-if="correctGuessIndex > -1">
        <div class="text-h6">
          Solved 🎉
        </div>
      </template>

      <template v-else-if="activeGuessIndex == -1">
        <div class="text-h6 grey--text">
          Not Solved 😕
        </div>
      </template>

      <slot name="reset">
        <v-btn @click="$emit('reset')">
          Play Again
        </v-btn>
      </slot>
    </div>
  </div>
</template>

<style>
  .board {
    display: inline-block;
  }

  .board__row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 12px;
    margin: 12px 0;
    align-items: center;
  }
</style>
