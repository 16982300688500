import Phaser from 'phaser';

export default class Laser extends Phaser.GameObjects.Line {
  #ttl
  #ship

  constructor(scene, options) {
    let config = {
      x1: 0,
      y1: 0,
      x2: 15,
      y2: 0,
      color: "#ff0000",
      ...options
    };

    super(
      scene,
      config.x,
      config.y,
      config.x1,
      config.y1,
      config.x2,
      config.y2,
      parseInt(config.color.replace(/^#/, ''), 16)
    );

    // TODO: this is pretty lame, but adding to the laserGroup seems to nuke some of this unless we delay it (maybe just the velocity?)
    setTimeout(() => {
      this
        .setDepth(-1)
        .setAngle(options.angle)
        .setLineWidth(2);

      scene.add.existing(this);
      scene.physics.add.existing(this, false).body
        .setVelocity(Math.cos(this.rotation) * 600, Math.sin(this.rotation) * 600);
    }, 0);

    this.#ttl = this.scene.time.now + 2200;
    this.#ship = options.ship;
  }

  preUpdate() {
    if (this.scene.time.now > this.#ttl) {
      this.destroy();
    }
  }
  
  getShip() {
    return this.#ship;
  }
}
