<script>
  export default {
    data() {
      return {
        zoom: 100,
        x: 0,
        y: 0,

        dragging: false,
        dragOrigin: undefined,
      };
    },

    computed: {
      frameStyles() {
        let scale = this.zoom / 100;
        return {
          transform: `matrix(${scale}, 0, 0, ${scale}, ${this.x}, ${this.y})`
        };
      },
    },

    methods: {
      startDragging(e) {
        this.dragging = true;

        this.dragOrigin = {
          mouseX: e.clientX,
          mouseY: e.clientY,
          x: this.x,
          y: this.y,
        };
      },

      stopDragging() {
        this.dragging = false;
        this.dragOrigin = undefined;
      },

      onMousemove(e) {
        if (!this.dragging) {
          return;
        }

        if (e.buttons != 1) {
          this.stopDragging();
          return;
        }

        this.x = this.dragOrigin.x + e.clientX - this.dragOrigin.mouseX;
        this.y = this.dragOrigin.y + e.clientY - this.dragOrigin.mouseY;
      },
    }
  }
</script>

<template>
  <div class="panzoom">
    <div
      class="panzoom-frame"
      @mousedown="startDragging"
      @mouseup="stopDragging"
      @mousemove="onMousemove"
    >
      <div class="panzoom-content" :style="frameStyles">
        <slot></slot>
      </div>
    </div>

    <div class="panzoom-controls">
      <v-slider
        v-model="zoom"
        :label="'Zoom'"
        :min="25"
        :max="250"
      ></v-slider>
    </div>
  </div>
</template>

<style scoped>
  .panzoom {
    overflow: hidden;
    border: 2px solid green;
  }

  .panzoom-frame {
    border: 2px solid pink;
  }

  .panzoom-content {
    transform-origin: 0px 0px 0px;
  }
</style>
