<script>
  export default {
    props: {
      value: {
        type: Boolean,
      }
    },

    data() {
      return {
        input: false
      };
    },

    watch: {
      value() {
        this.input = this.value;
      },

      input() {
        this.$emit("input", this.input);
      }
    },
  };
</script>

<template>
  <v-responsive
    :aspect-ratio="1"
    class="digital-button d-flex align-center justify-center text-h3 text-center"
    :class="input ? 'red darken-4' : 'red'"
    @pointerdown.prevent="input = true"
    @pointerup.prevent="input = false"
    @pointerout.prevent="input = false"
    @pointercancel.prevent="input = false"
  >
    <slot></slot>
  </v-responsive>
</template>

<style scoped>
  * {
    user-select: none;
  }

  .digital-button {
    width: 100%;
    max-width: 100px;
    border-radius: 100%;
  }
</style>
