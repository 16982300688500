<script>
  export default {
    components: {
      "super-drag": require("./super-drag").default,
    },

    props: {
      value: {
        type: Array,
        required: true
      },

      activeDrag: {},

      classes: {},

      max: {
        type: Number,
        default: -1
      }
    },

    data() {
      return {
        input: [],
        active: false,
        dragRects: [],

        debug: "",

        dragX: 0,
        dragY: 0,
      };
    },

    computed: {
      dragIndex() {
        if (!this.active) {
          return;
        }

        return this.dragRects.findIndex(dr => {
          return dr.x <= this.dragX
            && dr.y <= this.dragY
            && dr.x + dr.width >= this.dragX
            && dr.y + dr.height >= this.dragY
        })
      },
    },

    watch: {
      value: {
        handler() {
          this.input = JSON.parse(JSON.stringify(this.value));
        },
        immediate: true
      },

      active() {
        if (this.active) {
          /*
            TODO: this is no good...the $refs order doesn't seem to change along with value/input
          */
          this.dragRects = this.$refs.drags.map(vm => {
            return JSON.parse(JSON.stringify(vm.$el.getBoundingClientRect()));
          })
        }
      },
    },

    methods: {
      onTouchEvent(e) {
        e.preventDefault();

        if (!this.activeDrag) {
          this.active = false;
          return;
        }

        let touch = e.touches[0] || {};

        this.dragX = Math.floor(touch.clientX);
        this.dragY = Math.floor(touch.clientY);

        this.active = true;
      },

      onMousemove(e) {
        if (!this.activeDrag) {
          this.active = false;
          return;
        }

        this.dragX = e.clientX;
        this.dragY = e.clientY;

        this.active = true;
      },

      onDragDrop(data) {
        let input = this.input.filter((i, index) => index != data.index);
        input.splice(this.dragIndex, 0, data.item)
        this.$emit("input", input);
      }
    }
  }
</script>

<template>
  <div
    @touchmove="onTouchEvent"
    @touchstart="onTouchEvent"
    @touchend="onTouchEvent"
    @touchcancel="onTouchEvent"
    @mousemove="onMousemove"
  >
    <div
      class="super-drop"
      :class="classes"
    >
      <super-drag
        v-for="(item, index) in input"
        :key="JSON.stringify(item)"
        :active-drop="index == dragIndex"
        @drag:start="$emit('drag:start', {item, index})"
        @drag:stop="$emit('drag:stop', {item, index})"
        @drag:drop="onDragDrop({item, index})"
        ref="drags"
      >
        <slot name="drag" v-bind="{ ...item }"></slot>
      </super-drag>
    </div>

    {{ dragX }}, {{ dragY }} | {{ dragIndex }} | {{ debug }}
  </div>
</template>
