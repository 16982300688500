<script>
  export default {
    props: ["state"]
  };
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-fade-transition mode="out-in">
          <router-view :key="$router.currentRoute.fullPath" :state="state"></router-view>
        </v-fade-transition>
      </v-col>
    </v-row>
  </v-container>
</template>
