<script>
  export default {
    components: {
      "select-icon": require("./select-icon").default,
      "text-shadows": require("./text-shadows").default,
    },

    data() {
      return {
        icon: "mdi-pizza",
        fontSizeNumber: 40,
        fontSizeUnit: "vh",
        color: "#fff",
        deg: 0,
        textShadows: ["10px 10px 10px #f00", "-10px -10px 10px #ff0", "0px 0px 30px #00f"],

        snapshot: undefined,
      };
    },

    computed: {
      styles() {
        return {
          "font-size": `${this.fontSizeNumber}${this.fontSizeUnit}`,
          "color": this.color,
          "transform": `rotate(${this.deg}deg)`,
          "text-shadow": this.textShadows.filter(Boolean).join(", "),
        };
      }
    },

    methods: {
      reset() {
        Object.keys(this.snapshot)
          .forEach(k => {
            this.$set(this, k, this.snapshot[k]);
          });
      },
    },

    beforeMount() {
      let snapshot = JSON.parse(JSON.stringify(this.$data));
      delete snapshot.snapshot;
      this.snapshot = snapshot;
    }
  }
</script>

<template>
  <v-card color="blue-grey darken-2">
    <v-card-text>
      <v-row>
        <v-col
          class="d-flex justify-center align-center"
          cols="8"
        >
          <v-icon :style="styles">
            {{ icon }}
          </v-icon>
        </v-col>

        <v-col cols="4">
          <select-icon v-model="icon"></select-icon>

          <v-divider class="mb-5"></v-divider>

          <v-slider
            v-model="fontSizeNumber"
            label="Size"
            dense
          ></v-slider>

          <v-divider class="mb-5"></v-divider>

          <v-slider
            v-model="deg"
            label="Rotate"
            dense
            min="0"
            max="360"
          ></v-slider>

          <v-divider class="mb-5"></v-divider>

          <div class="text-center">
            <strong>Text Shadows</strong>
          </div>

          <text-shadows v-model="textShadows"></text-shadows>

          <v-divider class="my-5"></v-divider>

          <div class="d-flex flex-column align-center">
            <strong>Color</strong>

            <v-color-picker
              v-model="color"
              label="Color"
              class="mt-2 align-self-center"
            ></v-color-picker>
          </div>

          <v-divider class="my-5"></v-divider>

          <div class="text-center">
            <v-btn @click="reset">
              Reset
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
