<script>
  export default {
    components: {
      game: require("./game").default,
    },

    data() {
      return {
        code: [],
      };
    },

    methods: {
      newGame() {
        this.code = new Array(4)
          .fill("")
          .map(() => {
            return Math.floor(Math.random() * 6) + 1;
          });
      },
    },

    beforeMount() {
      this.newGame();
    }
  }
</script>

<template>
  <div class="d-flex justify-center">
    <game
      :code="code"
      :key="JSON.stringify(code)"
      @reset="newGame"
    ></game>
  </div>
</template>
