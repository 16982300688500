<script>
  export default {
    components: {
      game: require("./game").default,
      players: require("./players").default,
      "force-reset": require("./multi-player-force-reset").default,
      "join": require("./multi-player-join").default,
    },

    data() {
      return {
        users: [],
        gameDataByUserID: {},
        code: undefined,
        newGameCountdown: undefined,
      };
    },

    computed: {
      myServerGameData() {
        return this.gameDataByUserID[this.$store.state.me._id];
      },

      allActiveUsersGameOver() {
        return this.users
          .filter(u => u.active)
          .find(u => {
            return this.gameDataByUserID[u._id]?.gameOver !== true;
          }) == undefined;
      },

      friendlyOutcome() {
        if (!this.allActiveUsersGameOver || this.users.length < 2) {
          return;
        }

        let sortedPlayers = this.users
          .map(u => {
            let score = (this.gameDataByUserID[u._id]?.guessClues || [])
              .findIndex(c => {
                return c && c.correctPosition == 4;
              });

            return {
              ...u,
              score,
            };
          })
          .sort((a, b) => {
            if (a.score > b.score) {
              return -1;
            }

            if (a.score < b.score) {
              return 1;
            }

            return 0;
          });

        if (sortedPlayers[0]?.score == sortedPlayers[1]?.score) {
          return "Tie 😐";
        }

        return `${sortedPlayers[0].username} wins 🏆`;
      },
    },

    methods: {
      sync(data) {
        this.$store.state.socket.emit("mastermind:user-game-data", data);
      },
    },

    mounted() {
      this.$store.state.socket.on("mastermind:data", data => {

        Object.keys(data)
          .filter(k => this.$data.hasOwnProperty(k))
          .forEach(k => {
            this.$set(this, k, data[k]);
          });
      });

      this.$store.state.socket.emit("mastermind:join");
    },

    beforeDestroy() {
      this.$store.state.socket.emit("mastermind:leave");
    }
  }
</script>

<template>
  <div>
    <join
      :show="!users.find(u => u._id == $store.state.me._id)"
    ></join>

    <players
      :players="users.map(u => {
        return {
          ...u,
          game: this.gameDataByUserID[u._id]
        };
      })"
      :show-guesses="myServerGameData && myServerGameData.gameOver"
    ></players>

    <v-expand-transition>
      <div v-if="friendlyOutcome" class="text-h5 text-center">
        {{ friendlyOutcome }}
      </div>
    </v-expand-transition>

    <v-expand-transition>
      <!-- TODO: component -->
      <div v-if="newGameCountdown > 0" class="text-center blue-grey--text text-subtitle-1">
        New game in {{ newGameCountdown }}...

        <v-btn
          text
          outlined
          small
          color="error"
          @click="$store.state.socket.emit('mastermind:cancel-reset')"
        >
          Cancel
        </v-btn>
      </div>
    </v-expand-transition>

    <div class="d-flex justify-center">
      <template v-if="code">
        <game
          :code="code"
          :key="JSON.stringify(code)"
          :state="myServerGameData"
          @update="sync"
        >
          <template v-slot:reset>
            <template v-if="allActiveUsersGameOver">
              <v-btn
                @click="$store.state.socket.emit('mastermind:reset')"
                :loading="newGameCountdown > 0"
                :disabled="newGameCountdown > 0"
              >
                Play Again
              </v-btn>
            </template>

            <template v-else>
              <force-reset></force-reset>
            </template>
          </template>
        </game>
      </template>

      <template v-else>
        Please wait...
      </template>
    </div>
  </div>
</template>
