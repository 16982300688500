<script>
  export default {
    props: ["hour", "minute", "second"],
  }
</script>

<template>
  <div class="digital-clock red--text">
    {{ ('0' + hour).substr(-2) }}:{{ ('0' + minute).substr(-2) }}:{{ ('0' + second).substr(-2) }}
  </div>
</template>

<style scoped>
  @import url('https://fonts.cdnfonts.com/css/thegoodmonolith');

  .digital-clock,
  .digital-clock * {
    font-family: "TheGoodMonolith", sans-serif;
    font-size: 6vw;
    text-shadow: 2px 2px 10px;
  }
</style>
