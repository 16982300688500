<script>
  import doritos from "./doritos.png";
  import dots from "./dots.png";
  import yasso from "./yasso.png";
  import dietdrpepper from "./dietdrpepper.png";
  import pepsi from "./pepsi.png";

  const snacks = {
    doritos,
    dots,
    yasso,
    dietdrpepper,
    pepsi,
  };

  export default {
    components: {
      snack: require("./snack").default,
    },

    data() {
      return {
        snacks: {},

        selectedSnacks: {},

        friendlySnackNames: {
          doritos: "Doritos",
          dots: "Dots",
          yasso: "Yasso",
          dietdrpepper: "Diet Dr. Pepper",
          pepsi: "Pepsi",
        },

        interval: undefined,
      };
    },

    methods: {
      addSnacks() {
        let keys = Object.keys(snacks)
          .filter(key => this.selectedSnacks[key]);

        if (keys.length == 0) {
          return;
        }

        while (Object.keys(this.snacks).length < 50) {
          this.$set(this.snacks, Math.random(), snacks[keys[keys.length * Math.random() << 0]]);
        }
      }
    },

    beforeMount() {
      this.interval = setInterval(this.addSnacks, 250);

      Object.keys(snacks)
        .forEach(key => {
          this.selectedSnacks[key] = true;
        });
    },

    destroyed() {
      clearInterval(this.interval);
    }
  }
</script>

<template>
  <div class="d-flex justify-center">
    <v-card
      style="z-index: 100; opacity: 0.9;"
      min-width="300"
    >
      <v-card-title class="pb-0">
        Snacks
      </v-card-title>

      <v-card-text>
        <v-checkbox
          v-for="s in Object.keys(selectedSnacks)"
          :key="s"
          v-model="selectedSnacks[s]"
          :label="friendlySnackNames[s]"
          hide-details
        ></v-checkbox>
      </v-card-text>
    </v-card>

    <snack
      v-for="s in Object.keys(snacks)"
      :key="s"
      :image="snacks[s]"
      @remove="delete snacks[s]"
      class="snack"
    ></snack>
  </div>
</template>

<style scoped>
  .snack {
    position: absolute;
    z-index: 0;
  }
</style>
