<script>
  export default {
    components: {
      "nav-btn": require('./NavigationButton').default,
    },

    props: ["value"],

    data() {
      return {
        input: false,
        iconsVisible: false,
        search: "",
      };
    },

    computed: {
      filteredRoutes() {
        let search = this.search.toLowerCase();

        return this.$router.options.routes
          .filter(r => {
            return r.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
          })
          .sort((a, b) => {
            return a.name.indexOf(search) - b.name.indexOf(search);
          })
      }
    },

    watch: {
      value: {
        handler() {
          this.input = this.value;
        },
        immediate: true
      },

      input() {
        this.$emit("input", this.input);
        this.search = "";

        if (this.input) {
          setTimeout(() => {
            this.iconsVisible = true
          }, 200);
        } else {
          this.iconsVisible = false;
        }
      },
    },

    methods: {
      onKeydown(e) {
        if (e.key.toLowerCase() == "escape") {
          this.input = false
        }

        if (e.key == "o" && e.ctrlKey) {
          this.input = !this.input;
          e.preventDefault();
        }
      },

      selectFirstFilteredRoute() {
        let r = this.filteredRoutes[0];
        r && this.$router.push({path: r.pushPath || r.path});
      }
    },

    mounted() {
      document.addEventListener("keydown", this.onKeydown);
    },

    destroyed() {
      document.removeEventListener("keydown", this.onKeydown);
    }
  };
</script>

<template>
  <div class="nav-drawer">
    <template v-if="value">
      <div
        class="nav-drawer__bg grey darken-3"
        v-anime="{
          opacity: [0, 0.8],
          scale: [0, 100],
          duration: 1000,
          easing: 'easeOutCubic',
        }"
      ></div>

      <div
        class="nav-drawer__content pa-4 grey darken-3"
        v-anime="{
          opacity: [0, 1],
          delay: 200,
          duration: 200,
          easing: 'linear'
        }"
      >
        <v-btn
          class="pl-0 mr-1 text-none"
          @click="input = false"
          icon
        >
          <v-icon x-large>
            mdi-close-circle-outline
          </v-icon>
        </v-btn>

        <span class="text-caption grey--text text--lighten-1 d-none d-md-inline">
          [Escape] Close | [Ctrl+o] Toggle
        </span>

        <div class="d-none d-md-flex justify-center mt-5">
          <v-text-field
            v-model="search"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            style="max-width: 400px;"
            color="blue-grey"
            @keyup.enter="selectFirstFilteredRoute"
            outlined
            autofocus
          ></v-text-field>
        </div>

        <div
          :key="filteredRoutes.map(r => r.name).join('')"
          v-if="iconsVisible"
        >
          <div
            v-if="search.length > 0 && filteredRoutes.length > 0"
            class="text-center grey--text"
          >
            [Enter] {{ filteredRoutes[0].name }}
          </div>

          <div
            class="d-flex flex-wrap justify-center pb-8"
            st
          >
            <template v-for="(r, i) in filteredRoutes">
              <nav-btn
                v-if="(!r.meta.hidden || $route.name == r.name || Boolean($store.state.routeHistory[r.path]))"
                :key="i"
                :selected="$route.path.indexOf(r.path) == 0"
                v-bind="r.meta"
                @click="$router.push({path: r.pushPath || r.path})"
                v-anime="{
                  rotate: [$anime.random(-180, 180), 0],
                  scale: [0, 1],
                  opacity: [0, 1],
                  delay: i * 25,
                  duration: 300,
                }"
              >
                {{ r.name.toUpperCase() }}
              </nav-btn>
            </template>

            <template v-if="filteredRoutes.length == 0">
              <div class="text-h5 grey--text">
                lol wut?
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
  .nav-drawer__bg {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 50px;
    aspect-ratio: 1;
    z-index: 9998;
    border-radius: 100%;
  }

  .nav-drawer__content {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    overflow-x: hidden;
    overflow-y: scroll;
  }
</style>
