<script>
  export default {
    props: {
      image: {},
    },

    data() {
      return {
        styles: {
          opacity: 0,
          scale: 0.3,
        }
      };
    },

    beforeMount() {
      let x = Math.floor((Math.random() * 100) + 1);
      let y = Math.floor((Math.random() * 100) + 1);

      if (x < 50) {
        this.styles.left = x + "%";
      } else {
        this.styles.right = (100 - x) + "%";
      }

      if (y < 50) {
        this.styles.top = y + "%";
      } else {
        this.styles.bottom = (100 - y) + "%";
      }
    },

    mounted() {
      const targets = this.$el;

      let timeline = this
        .$anime
        .timeline()
        .add({
          targets,
          rotate: this.$anime.random(-360, 360),
          opacity: 1,
          duration: this.$anime.random(1500, 4000),
          scale: this.$anime.random(0.5, 1.5),
        })
        .add({
          targets,
          opacity: 0,
          scale: 0,
        });

      timeline.finished.then(() => {
        this.$emit("remove");
      });
    },
  }
</script>

<template>
  <img
    :src="image"
    :style="styles"
  >
</template>
