<script>
  export default {
    props: {
      selected: {
        type: Boolean,
        default: false
      },

      icon: {
        type: String,
      },

      color: {
        default: "green"
      },

      hidden: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<template>
  <div class="ma-8 text-center">
    <v-btn
      v-on="$listeners"
      class="nav-btn"
      icon
      x-large
      fab
      rounded
      :tile="!selected"
      :elevation="selected ? 8 : 1"
      :class="selected ? `${color}` : `grey lighten-2 ${color}--text text--darken-1`"
    >
      <v-icon x-large>
        {{ icon }}
      </v-icon>
    </v-btn>

    <div
      class="text-left mt-2 text-caption"
      :class="selected ? `${color}--text text--lighten-2` : ``"
      style="max-width: fit-content;"
    >
      <slot></slot>

      <v-icon
        v-if="hidden"
        color="grey"
        small
      >
        mdi-lock
      </v-icon>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .nav-btn {
    transition: all 0.2s;
  }
</style>
