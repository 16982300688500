<script>
  // TODO: look into ion-phaser https://vuedose.tips/build-a-game-in-vuejs-with-phaser/
  const Game = require("./game.js").default;
  let game;

  export default {
    components: {
      "controller": require("./controller").default,
    },

    data() {
      return {
        game: undefined,

        players: [
            "#ff0000",
            "#00ff00",
            "#0000ff",
            "#ffff00",
          ]
          .map(c => {
            return {
              controls: {},
              color: c,
              score: 0,
            };
          })
      };
    },

    mounted() {
      game = new Game({
        parent: this.$refs.phaser,
      });

      setTimeout(() => {
        this.players
          .forEach((p, i) => {
            game.addShip(`player${i}`, {
              x: 50,
              y: (i * 150) + 80,
              color: p.color,
              onChangeScore: score => {
                this.$set(p, "score", score);
              },
            })
          });
      }, 0);

      this.players.forEach((p, i) => {
        this.$watch(() => {
          return JSON.stringify(this.players[i].controls);
        }, () => {
          game.setControls(`player${i}`, this.players[i].controls);
        });
      });
    },
  };
</script>

<template>
  <div class="pt-8">
    <div class="d-flex justify-center">
      <div class="white" style="width: 150px;">
        <!-- TODO: move these to the top/bottom instead of the left side -->
        <div
          v-for="(p, i) in players"
          class="pa-3"
          :key="i"
          :style="`background: ${p.color}`"
        >
          <controller
            v-model="p.controls"
            :state="'player' + i"
          >
            <v-responsive
              :aspect-ratio="1"
              class="d-flex align-center text-center black"
            >
              Player {{ i + 1 }}

              <div>
                Score: {{ p.score }}
              </div>
            </v-responsive>
          </controller>
        </div>
      </div>

      <div ref="phaser" class="d-flex justify-center phaser-container"></div>
    </div>
  </div>
</template>

<style scoped>
  .phaser-container {
    box-shadow: 1px 0px 3px 1px #fff;
  }
</style>
