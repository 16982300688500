<script>
  export default {
    props: {
      orthogonal: {
        type: Boolean,
        default: false
      },

      els: {
        type: Array,
        default() {
          return [];
        }
      },
    },

    data() {
      return {
        left: 300,
        top: 300,
        width: 300,
        height: 300,
        dragging: false,
        emitUpdateTimeout: undefined,
        outlineX1: 0,
        outlineY1: 0,
        outlineX2: 0,
        outlineY2: 0
      };
    },

    computed: {
      x1() {
        if (this.orthogonal && this.orthogonalDirection == "vertical") {
          return this.left;
        }
        return Math.min(this.left + this.width, this.left);
      },

      y1() {
        if (this.orthogonal && this.orthogonalDirection == "horizontal") {
          return this.top;
        }
        return Math.min(this.top + this.height, this.top);
      },

      offsetX() {
        if (this.orthogonal && this.orthogonalDirection == "vertical") {
          return 0;
        }
        return Math.abs(this.width);
      },

      offsetY() {
        if (this.orthogonal && this.orthogonalDirection == "horizontal") {
          return 0;
        }
        return Math.abs(this.height);
      },

      orthogonalDirection() {
        return Math.abs(this.width) > Math.abs(this.height) ? "horizontal" : "vertical";
      },

      styles() {
        return {
          left: this.x1 + "px",
          top: this.y1 + "px",
          width: this.offsetX + "px",
          height: this.offsetY + "px",
        };
      },

      outlineStyles() {
        return {
          left: this.outlineX1 + "px",
          top: this.outlineY1 + "px",
          width: (this.outlineX2 - this.outlineX1) + "px",
          height: (this.outlineY2 - this.outlineY1) + "px",
        };
      },


      coordinates() {
        return {
          x1: this.x1,
          y1: this.y1,
          x2: this.x1 + this.offsetX,
          y2: this.y1 + this.offsetY,
        };
      },
    },

    watch: {
      dragging() {
        if (!this.dragging) {
          this.$emit("select", this.coordinates);
        }
      },

      coordinates() {
        if (this.emitUpdateTimeout) {
          return;
        }

        this.emitUpdateTimeout = setTimeout(() => {
          this.emitUpdateTimeout = undefined;
          this.$emit("update:coordinates", this.coordinates);
        }, 100);
      },

      els() {
        if (this.els.length == 0) {
          return;
        }

        let startRect = this.els[0].getBoundingClientRect();
        let endRect = this.els[this.els.length - 1].getBoundingClientRect();

        this.outlineX1 = startRect.left;
        this.outlineY1 = startRect.top;
        this.outlineX2 = endRect.left + endRect.width;
        this.outlineY2 = endRect.top + endRect.height;
      },
    },

    methods: {
      onDown(e) {
        this.dragging = true;
        this.left = e.clientX;
        this.top = e.clientY;
        this.width = 1;
        this.height = 1;
      },

      onMove(e) {
        if (!this.dragging) {
          return;
        }
        this.width = e.clientX - this.left;
        this.height = e.clientY - this.top;
      },

      onUp() {
        this.dragging = false;
      }
    },

    mounted() {
      document.addEventListener("mousedown", this.onDown, false);
      document.addEventListener("mousemove", this.onMove, false);
      document.addEventListener("mouseup", this.onUp, false);
    },

    destroyed() {
      document.removeEventListener("mousedown", this.onDown, false);
      document.removeEventListener("mousemove", this.onMove, false);
      document.removeEventListener("mouseup", this.onUp, false);
    }
  };
</script>

<template>
  <div
    class="chamber yellow"
    :style="styles"
  >
    <div
      class="chamber--outline d-flex align-center justify-center pink lighten-2"
      :style="outlineStyles"
      v-if="els.length > 1 && dragging"
    >
      {{ els.length }}
    </div>
  </div>
</template>

<style scoped>
  .chamber {
    position: fixed;
    pointer-events: none;
  }

  .chamber--outline {
    position: fixed;
    height: 10px;
    width: 10px;
    background-opacity: 0.5;
    z-index: 100;
    opacity: 0.7;
    text-shadow: 1px 1px 3px #000;
    font-size: 1.2em;
  }
</style>
