<script>
  export default {
    name: "Icons",

    components: {
      search: require("./search").default,
      styler: require("./styler").default,
    },

    data() {
      return {
        tab: 0, // TODO
      };
    },
  }
</script>

<template>
  <v-container fluid>
    <v-tabs v-model="tab" vertical fixed>
      <v-tab key="search">
        Search
      </v-tab>

      <v-tab key="style">
        Style
      </v-tab>

      <v-tab-item>
        <search></search>
      </v-tab-item>

      <v-tab-item>
        <styler></styler>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
