<script>
  /*
    drops
      sometimes you want to swap places...
      ...sometimes you want to move from a source to a target (pushing other items in the target)
      ...and sometimes you want to re-order items in a single source

      some items may not be valid to drop in certain spots

      some drops can have a maximum number of items...
      ...and some drops may need a fixed number of spots for items

      sometimes you want to let things get dropped anywhere...
        and either push or shift the item into the 

    drags
      some drags may have click (or other cursor related events) listeners (e.g. a button)...
      ...this could be solved with an optional drag handle?...

      sometimes you want a placeholder in the original spot...
      ...and sometimes you want to remove (from the flow) the item from the original spot...

      does it ever make sense for a drag to exist outside of a drop?
        I don't think so...

      elements/states
        - not dragging
          - original el
        - dragging
          - original el ("drag el")
          - draggable ghost ("ghost el")
          - preview in hovered drop ("preview el")
  */
  export default {
    components: {
      "super-drop": require("./super-drop").default,
    },

    data() {
      return {
        items: [],
        activeDrag: undefined,
      };
    },

    methods: {
      reset() {
        this.items = "abcdefgh".split("");
      }
    },

    beforeMount() {
      this.reset();
    }
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <super-drop
          v-model="items"
          :active-drag="activeDrag"
          classes="d-flex flex-wrap"
          @drag:start="activeDrag = $event.item"
          @drag:stop="activeDrag = undefined"
        >
          <template v-slot:drag="item">
            {{ item[0] }}
          </template>
        </super-drop>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
  .super-drag {
    width: 100px;
    height: 100px;
    border: 3px solid #fff;
    background: blue;
    border-radius: 5px;
    box-shadow: 0 0 0 1px #ccc;
    cursor: move;

    display: flex;
    justify-content: center;
    align-items: center;

    touch-action: none;
    user-select: none;
  }

  .super-drag--active {
    opacity: 0;
  }

  .super-drag--active-drop {
    animation: twist-right 0.2s linear forwards;
  }

  .super-drag__ghost {
    border-radius: 5px;
    cursor: move; /* TODO: why doesn't this work? */
    animation: ghost 0.2s linear forwards;
  }

  @keyframes twist-right {
    100% {
      transform: perspective(600px) rotateY(-45deg) translateX(60px);
      opacity: 0.7;
    }
  }

  @keyframes ghost {
    100% {
      transform: scale(0.7);
      opacity: 0.7;
    }
  }
</style>
