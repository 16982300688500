<script>
  /*
    TODO
      move the current state into an "anagrams" app
      turn this into a guessing game:
        - pick consonants and vowels (countdown style)
        - 30 seconds (or is it 60?) to find the longest anagram
          - any points for guessing the most anagrams regardless of length?
            - if so, maybe this isn't "countdown"...
        - maybe playing against some sort of ai/bot? or multiplayer?
  */

  // TODO: http://www.thecountdownpage.com/letters.htm
  const VOWELS = [
    'a'.repeat(15),
    'e'.repeat(21),
    'i'.repeat(13),
    'o'.repeat(13),
    'u'.repeat(5),
  ].join("").split("");

  const CONSONANTS = [
    'b'.repeat(2),
    'c'.repeat(3),
    'd'.repeat(6),
    'f'.repeat(2),
    'g'.repeat(3),
    'h'.repeat(2),
    'j'.repeat(1),
    'k'.repeat(1),
    'l'.repeat(5),
    'm'.repeat(4),
    'n'.repeat(8),
    'p'.repeat(4),
    'q'.repeat(1),
    'r'.repeat(9),
    's'.repeat(9),
    't'.repeat(9),
    'v'.repeat(1),
    'w'.repeat(1),
    'x'.repeat(1),
    'y'.repeat(1),
    'z'.repeat(1),
  ].join("").split("");

  const shuffle = (array) => {
    for (let i = array.length -1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]
    }

    return array;
  };

  export default {
    components: {
      results: require("./results").default,
    },

    data() {
      return {
        letters: "",

        resultLetters: "",
        results: [],

        showResults: false,

        loading: false,
      };
    },

    watch: {
      letters() {
        let letters = this.letters.toLowerCase().replace(/[^a-z]/g, "");

        if (letters != this.letters) {
          this.$nextTick(() => {
            this.letters = letters;
          });
        }
      }
    },

    methods: {
      getRandomLetters() {
        let v = shuffle(VOWELS);
        let c = shuffle(CONSONANTS.map(l => l));

        let result = v.splice(0, 3).concat(c.splice(0, 4));

        for (let i = 0; i < 2; i++) {
          result.push(Math.random() > 0.5 ? v.splice(0, 1) : c.splice(0, 1));
        }

        shuffle(result);

        return result.join("");
      },

      load() {
        this.results = [];

        this.loading = true;
        this.resultLetters = this.letters;

        return this.fetchAnagramsOfLetters(this.resultLetters)
          .then(() => {
            this.showResults = true;
          })
          .finally(() => {
            this.loading = false;
          });
      },

      fetchAnagramsOfLetters(letters) {
        return fetch(`/anagrams/${letters}`)
          .then(r => {
            return r.json();
          })
          .then(data => {
            this.results = this.results.concat(data)
              .filter((e, i, a) => a.indexOf(e) == i);
          });
      },
    },
  }
</script>

<template>
  <div>
    <div class="text-center text-h3 mb-4">
      Letters
    </div>

    <v-text-field
      v-model="letters"
      label="Letters"
      @keyup.enter="load"
      outlined
      hide-details
      maxlength="16"
      :disabled="loading"
    ></v-text-field>

    <v-divider class="mx-8 my-3"></v-divider>

    <div style="display: grid; grid-template-columns: 1fr 1fr;">
      <v-btn
        @click="[letters = getRandomLetters(), load()]"
        color="amber"
        tile x-large
        :disabled="loading || letters.length > 0"
      >
        <v-icon>mdi-dice-multiple</v-icon>
      </v-btn>

      <v-btn
        @click="load"
        color="primary"
        tile x-large
        :disabled="loading || letters.length == 0"
        :loading="loading"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </div>

    <results
      v-model="showResults"
      :letters="resultLetters"
      :results="results"
    ></results>
  </div>
</template>
