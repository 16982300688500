<script>
  export default {
    components: {
      "canvas-input": require("./canvas-input").default,
    },
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <canvas-input></canvas-input>
      </v-col>
    </v-row>
  </v-container>
</template>
