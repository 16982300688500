<script>
  // TODO: https://developer.mozilla.org/en-US/docs/Web/API/PointerEvent
  export default {
    components: {
      "joystick": require("./Joystick").default,
      "dpad": require("./DPad").default,
      "digital-button": require("./DigitalButton").default,
    },

    data() {
      return {
        joystick: undefined,
        dpad: {},
        a: false,
        b: false,
      };
    },

    watch: {
      joystick() {
        this.emit();
      },

      dpad() {
        this.emit();
      },

      a() {
        this.emit();
      },

      b() {
        this.emit();
      },
    },

    methods: {
      emit() {
        this.$emit("input", {
          ...this.joystick,
          ...this.dpad,
          a: this.a,
          b: this.b,
        });
      },

      toggleFullscreen() {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      },

      toggleJoystickAndDPad() {
        if (this.joystick) {
          this.joystick = undefined;
          this.dpad = {};
        } else {
          this.joystick = {};
          this.dpad = undefined;
        }
      }
    },
  };
</script>

<template>
  <div class="gamepad grey lighten-2">
    <div>
      <joystick v-model="joystick" v-if="Boolean(joystick)"></joystick>

      <dpad v-model="dpad" v-if="Boolean(dpad)" class="pa-7"></dpad>
    </div>

    <div style="display: grid; grid-template-columns: 1fr 1fr; justify-items: center;">
      <digital-button v-model="a">
        A
      </digital-button>

      <digital-button v-model="b">
        B
      </digital-button>
    </div>

    <div class="options-container">
      <v-btn
        @click="toggleJoystickAndDPad"
        color="black"
        icon
      >
        <v-icon>
          mdi-gamepad
        </v-icon>
      </v-btn>

      <v-btn
        @click="toggleFullscreen"
        color="black"
        icon
      >
        <v-icon>
          mdi-fullscreen
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style scoped>
  .gamepad {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
  }

  .options-container {
    position: absolute;
    top: 10px;
    text-align: center;
    width: 100%;
  }

  * {
    user-select: none;
    touch-action: none;
  }
</style>
