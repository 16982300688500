<script>
  /*
    TODO
      - incorporate images somehow...
        - maybe user uploaded, or that random stock photo site might useful here
        - when using an image, maybe allow for showing the numbers
        - showing the target image could also be optional
      - allow for mouse input
        - maybe some "swipe" area: click & drag & release, determine the general direction, then apply the move
      - keep track of number of moves
      - timer?
      - "solved" state
      - let users adjust the puzzle size
  */
  export default {
    data() {
      return {
        gridBase: 5,
        cells: [],
        tiles: [],

        shuffling: false,
      };
    },

    watch: {
      cells: {
        handler() {
          this.updateTiles();
        },
        deep: true,
      },

      gridBase: {
        handler() {
          let flatCells = [...Array(this.gridBase * this.gridBase)].map((e, i) => i + 1);
          flatCells.pop();
          flatCells.push(undefined);

          let cells = [];

          while (flatCells.length > 0) {
            cells.push(flatCells.splice(0, this.gridBase));
          }

          this.cells = cells;
        },
        immediate: true
      },
    },

    methods: {
      getEmptyCellRowAndColumn() {
        let emptyFlatIndex = this.cells.flat().indexOf(undefined);

        let row = Math.floor(emptyFlatIndex / this.gridBase);
        let column = emptyFlatIndex % this.gridBase;

        return [row, column];
      },

      updateTiles() {
        let tiles = [];

        this.cells.forEach((r, y) => {
          this.cells.forEach((c, x, a) => {
            let flatIndex = x + (y * a.length);

            let cellEl = this.$refs.cell && this.$refs.cell[flatIndex] || {};

            tiles.push({
              label: this.cells[y][x],
              style: {
                top: `${cellEl.offsetTop}px`,
                left: `${cellEl.offsetLeft}px`,
                width: `${cellEl.offsetWidth}px`
              }
            })
          })
        });

        // TODO: probs should be sorting by some sort of "index"
        tiles.sort((a, b) => {
          return String(a.label).localeCompare(String(b.label));
        });

        this.tiles = tiles;
      },

      onKeyDown(e) {
        if (["ArrowLeft", "a"].indexOf(e.key) > -1) {
          this.move("left");
        }

        if (["ArrowRight", "d"].indexOf(e.key) > -1) {
          this.move("right");
        }

        if (["ArrowDown", "s"].indexOf(e.key) > -1) {
          this.move("down");
        }

        if (["ArrowUp", "w"].indexOf(e.key) > -1) {
          this.move("up");
        }
      },

      move(direction) {
        let [row, column] = this.getEmptyCellRowAndColumn();

        let otherCell;

        try {
          if (direction == "right") {
            otherCell = this.cells[row][column - 1];
          } else if (direction == "left") {
            otherCell = this.cells[row][column + 1];
          } else if (direction == "down") {
            otherCell = this.cells[row - 1][column];
          } else if (direction == "up") {
            otherCell = this.cells[row + 1][column];
          }
        } catch (e) {
          e;
          return;
        }

        if (otherCell == undefined) {
          return;
        }

        this.cells[row][column] = otherCell;

        if (direction == "right") {
          this.cells[row][column - 1] = undefined;
        } else if (direction == "left") {
          this.cells[row][column + 1] = undefined;
        } else if (direction == "down") {
          this.cells[row - 1][column] = undefined;
        } else if (direction == "up") {
          this.cells[row + 1][column] = undefined;
        }

        this.updateTiles();
      },

      shuffle() {
        let reverseMoves = {
          up: "down",
          left: "right",
          down: "up",
          right: "left"
        };

        let reversePreviousMove;

        [...Array(Math.min(this.gridBase * 200, 3000))]
          .reduce(p => {
            return p.then(() => {
              return new Promise(r => {
                let directions = "up,left,down,right"
                  .split(",")
                  .filter(d => d != reversePreviousMove)
                  .filter(d => {
                    let [row, column] = this.getEmptyCellRowAndColumn();

                    if (row == 0) {
                      return d != "down";
                    }

                    if (row == this.gridBase - 1) {
                      return d != "up";
                    }

                    if (column == 0) {
                      return d != "right";
                    }

                    if (column == this.gridBase - 1) {
                      return d != "left";
                    }

                    return true;
                  });

                let direction = directions[Math.floor(Math.random() * directions.length)];

                this.move(direction);

                reversePreviousMove = reverseMoves[direction];

                setTimeout(r, 0);
              });
            })
          }, Promise.resolve());
      },
    },

    mounted() {
      this.updateTiles();
    },

    beforeMount() {
      document.addEventListener("keydown", this.onKeyDown);
    },

    destroyed() {
      document.removeEventListener("keydown", this.onKeyDown);
    },
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="slide-puzzle" :style="`grid-template-columns: repeat(${gridBase}, 1fr);`">
          <div
            v-for="(c, i) in cells.flat()"
            :key="i"
            class="cell"
            ref="cell"
          ></div>

          <div
            v-for="(t, i) in tiles.filter(t => t.label != undefined)"
            :key="'tile' + i"
            class="tile secondary d-flex align-center justify-center"
            :style="t.style"
          >
            {{ t.label }}
          </div>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-6"></v-divider>

    <v-btn
      v-for="d in 'right,left,up,down'.split(',')"
      @click="move(d)"
      :key="d"
    >
      {{ d }}
    </v-btn>

    <v-btn
      @click="shuffle"
    >
      shuffle
    </v-btn>
  </v-container>
</template>

<style scoped>
  .slide-puzzle {
    display: grid;
    max-width: 30vw;

    position: relative;
  }

  .cell {
    aspect-ratio: 1;
    border: 1px solid white;
  }

  .tile {
    aspect-ratio: 1;
    position: absolute;
    border-radius: 3px;

    transition: 0.1s all;
    transform: scale(0.8);
  }
</style>
