<script>
  export default {
    props: {
      value: {
        type: Array,
        default() {
          return [
            [0, 1, 0],
            [1, 1, 1],
            [0, 1, 0],
          ];
        },
      }
    },
  }
</script>

<template>
  <div
    style="display: grid; width: 30vw;"
    :style="`grid-template-columns: repeat(${value[0].length}, 1fr);`"
  >
    <template v-for="(row, rIndex) in value">
      <template v-for="(column, cIndex) in row">
        <v-card
          class="led"
          :class="column == 1 ? 'green' : 'dark-grey'"
          :key="`${rIndex}_${cIndex}`"
          tile
        >
          <v-responsive
            aspect-ratio="1"
          >
          </v-responsive>
        </v-card>
      </template>
    </template>
  </div>
</template>

<style scoped>
  .led {
    transition: all 0.2s;
  }
</style>
