var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root"},_vm._l((Object.keys(_vm.particles)),function(k){return _c('div',{directives:[{name:"anime",rawName:"v-anime",value:({
      scale: [1, _vm.$anime.random(0,4)],
      opacity: [1, 0],
      rotate: [_vm.$anime.random(-360, 360), _vm.$anime.random(-360, 360)],
      translateX: [0, _vm.$anime.random(-50, 50) + 'vw'],
      translateY: [0, _vm.$anime.random(-50, 50) + 'vh'],
      easing: 'linear',
      duration: _vm.ttl,
    }),expression:"{\n      scale: [1, $anime.random(0,4)],\n      opacity: [1, 0],\n      rotate: [$anime.random(-360, 360), $anime.random(-360, 360)],\n      translateX: [0, $anime.random(-50, 50) + 'vw'],\n      translateY: [0, $anime.random(-50, 50) + 'vh'],\n      easing: 'linear',\n      duration: ttl,\n    }"}],key:k,staticClass:"particle",class:_vm.particles[k].classes})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }