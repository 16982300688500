<script>
  // TODO: force orientation? https://stackoverflow.com/questions/14360581/force-landscape-orientation-mode

  /*
    TODO
      - buttons
      - settings
        - motion controls vs virtual stick
        - sensitivity
        - dead zone
  */
  export default {
    components: {
      "gamepad": require("./Client/GamePad").default,
    },

    data() {
      return {
        gamepad: {},
        state: undefined,
      };
    },

    watch: {
      gamepad() {
        this.emitToHost({gamepad: this.gamepad});
      },
    },

    methods: {
      emitToHost(data) {
        this.$store.state.socket.emit("socketid:client-to-host", {
          socketid: this.$route.params.socketid,
          state: this.$route.params.state,
          time: new Date().getTime(),
          ...data,
        });
      },
    },
  };
</script>

<template>
  <div touchless>
    <gamepad v-on:input="gamepad = $event"></gamepad>
  </div>
</template>
