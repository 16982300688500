<script>
  export default {
    props: ["icon"],

    computed: {
      classes() {
        return ["beep"];
      }
    },
  }
</script>

<template>
  <div class="robot">
    <v-icon
      color="green"
      :class="classes"
    >
      mdi-{{ icon }}
    </v-icon>
  </div>
</template>

<style scoped>
  .robot {
    aspect-ratio: 2;
    display: grid;
  }

  .robot * {
    font-size: 40vh;
  }
</style>
