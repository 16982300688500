<script>
  // TODO: transitions (https://stackoverflow.com/questions/29447306/transition-on-fillstyle-color)
  const average = arr => arr.reduce((a, b) => a + b, 0) / arr.length;

  export default {
    components: {
      "anime-output": require("./anime-output").default,
    },

    data() {
      return {
        inputs: {
          gridSize: 10,
          canvasSize: 300,
          circles: true,
          color: false
        },

        inputSummary: undefined,
      };
    },

    computed: {
      gridSize() {
        let result = Number(this.inputs.gridSize) || 0;

        result = Math.max(result, 5);
        result = Math.min(result, 50);

        return result;
      },

      canvasSize() {
        let result = Number(this.inputs.canvasSize) || 0;

        result = Math.max(result, 100);
        result = Math.min(result, 600);

        return result;
      }
    },

    watch: {
      gridSize() {
        this.renderOutput();
      },

      "inputs.circles"() {
        this.renderOutput();
      },

      "inputs.color"() {
        this.renderOutput();
      },
    },

    methods: {
      loadImage() {
        let _this = this;

        let contexts = [
          this.$refs["canvas-input"].getContext("2d"),
          //this.$refs["canvas-output"].getContext("2d"),
        ];

        let img = new Image();
        img.onload = function() {
          contexts.forEach(c => {
            c.drawImage(img, 0, 0);
            _this.renderOutput();
          });
        };
        img.crossOrigin = "Anonymous";
        img.src = `https://picsum.photos/${this.canvasSize}?t=${new Date().getTime()}`;
      },

      updateInputSummary() {
        let context = this.$refs["canvas-input"].getContext("2d");

        let result = {};

        let x = 0;
        let y = 0;

        while (y < context.canvas.width) {
          while (x < context.canvas.width) {
            let imageData = context.getImageData(x, y, this.gridSize, this.gridSize);

            result[`${x},${y}`] = getAveragesFromArray(imageData.data);

            x += this.gridSize;
          }

          x = 0;

          y += this.gridSize;
        }

        this.inputSummary = result;

        function getAveragesFromArray(array) {
          let reds = [];
          let greens = [];
          let blues = [];
          let alphas = [];
          let lumins = [];

          let a = Array.prototype.slice.call(array);

          while (a.length > 0) {
            let rIndex = reds.push(a.shift()) - 1;
            let gIndex = greens.push(a.shift()) - 1;
            let bIndex = blues.push(a.shift()) - 1;
            alphas.push(a.shift());

            lumins.push(0.3 * reds[rIndex] + 0.59 * greens[gIndex] + 0.11 * bIndex);

            if (alphas[alphas.length - 1] == 0) {
              reds.pop();
              greens.pop();
              blues.pop();
              alphas.pop();
            }
          }

          return {
            red: Number.parseInt(average(reds)),
            green: Number.parseInt(average(greens)),
            blue: Number.parseInt(average(blues)),
            alpha: Number.parseInt(average(alphas)),
            lumins: Number.parseInt(average(lumins)),
          }
        }
      },

      renderOutput() {
        let _this = this;
        let context = this.$refs["canvas-output"].getContext("2d");
        let x = 0;
        let y = 0;

        this.updateInputSummary();

        context.fillStyle = "black";
        context.fillRect(0, 0, context.canvas.width, context.canvas.height);

        while (y < context.canvas.height) {
          while (x < context.canvas.width) {
            drawShape();

            x += this.gridSize;
          }

          x = 0;
          y += this.gridSize;
        }

        function drawShape() {
          let inputColorData = _this.inputSummary[`${x},${y}`];

          let fillStyle = "rgba(255, 0, 0, 255)"; // TODO
          if (_this.inputs.color) {
            if (inputColorData) {
              fillStyle = `rgba(${inputColorData.red}, ${inputColorData.green}, ${inputColorData.blue}, ${inputColorData.alpha})`;
            }
          } else {
            fillStyle = `rgba(255, 255, 255, 255)`;
          }

          context.fillStyle = fillStyle;

          if (_this.inputs.circles) {
            if (x / _this.gridSize % 2 == 1) {
              return;
            }

            if (y / _this.gridSize % 2 == 1) {
              return;
            }

            let radius = _this.gridSize;
            if (inputColorData) {
              let percent = inputColorData.lumins / 255;
              radius = _this.gridSize * percent;
            }

            context.beginPath();
            context.arc(x + _this.gridSize, y + _this.gridSize, radius, 0, 2 * Math.PI, false);
            context.fill();
          } else {
            let offset = 0;
            let size = _this.gridSize;

            if (inputColorData) {
              let percent = inputColorData.lumins / 255;
              offset = (_this.gridSize - (_this.gridSize * percent)) / 2; // TODO: is this right?
              size = _this.gridSize * percent;
            }

            context.fillRect(x + offset, y + offset, size, size);
          }
        }
      },
    },

    mounted() {
      this.loadImage();
    }
  }
</script>

<template>
  <div>
    <div class="mt-4">
      <v-text-field
        v-model="inputs.canvasSize"
        type="number"
        label="Canvas Size"
        outlined
      ></v-text-field>

      <v-text-field
        v-model="inputs.gridSize"
        type="number"
        label="Grid Size"
        outlined
        hide-details
      ></v-text-field>

      <v-checkbox
        v-model="inputs.circles"
        label="Circles"
        hide-details
      ></v-checkbox>

      <v-checkbox
        v-model="inputs.color"
        label="Color"
        hide-details
      ></v-checkbox>
    </div>

    <div class="mt-4 text-center">
      <v-btn @click="loadImage">
        Load random image
      </v-btn>
    </div>

    <div class="d-flex justify-center mt-8">
      <canvas
        :width="canvasSize"
        :height="canvasSize"
        ref="canvas-input"
      ></canvas>

      <canvas
        :width="canvasSize"
        :height="canvasSize"
        ref="canvas-output"
      ></canvas>

      <anime-output
        v-bind="{...inputs, gridSize, canvasSize, inputSummary}"
      ></anime-output>
    </div>
  </div>
</template>
