<script>
  export default {
    inheritAttrs: false,

    props: {
      activeDrop: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        active: false,
        dragX: 0,
        dragY: 0,
        dragOffsetX: 0,
        dragOffsetY: 0,

        classes: [],

        ghostHTML: "",
      };
    },

    computed: {
      ghostStyles() {
        if (!this.active) {
          return {};
        }

        return {
          top: `${this.dragY - this.dragOffsetY}px`,
          left: `${this.dragX - this.dragOffsetX}px`,
          position: "fixed",
        };
      },
    },

    watch: {
      active() {
        this.ghostHTML = this.active ? this.$refs.drag.outerHTML : "";

        if (this.active) {
          let boundingClientRect = this.$refs.drag.getBoundingClientRect();
          let originX = boundingClientRect.x;
          let originY = boundingClientRect.y;

          this.dragOffsetX = this.dragX - originX;
          this.dragOffsetY = this.dragY - originY;

          this.classes = ["super-drag--active"];

          window.addEventListener("mousemove", this.onMousemove);
          window.addEventListener("mouseup", this.onMouseup);

          this.$emit("drag:start");
        } else {
          this.classes = [];

          window.removeEventListener("mousemove", this.onMousemove);
          window.removeEventListener("mouseup", this.onMouseup);

          this.$emit("drag:stop");
        }
      },

      activeDrop() {
        if (this.activeDrop && !this.active) {
          this.classes.push("super-drag--active-drop");
        } else {
          this.classes = this.classes.filter(c => c != "super-drag--active-drop");
        }
      }
    },

    methods: {
      onTouchEvent(e) {
        e.preventDefault();

        let touch = e.touches[0] || {};

        this.active = touch.identifier != undefined;
        this.dragX = Math.floor(touch.clientX);
        this.dragY = Math.floor(touch.clientY);
      },

      // TODO: is there a "left" button qualifier of some kind in vue?
      onMousedown(e) {
        e.preventDefault();

        this.active = true;
        this.dragX = Math.floor(e.clientX);
        this.dragY = Math.floor(e.clientY);
      },

      onMousemove(e) {
        // TODO: there's probably a better way to do this
        if (e.buttons == 0) {
          this.active = false;
          return;
        }

        this.dragX = Math.floor(e.clientX);
        this.dragY = Math.floor(e.clientY);
      },

      onMouseup() {
        if (this.active) {
          this.$emit("drag:drop");
          this.active = false;
        }
      }
    },
  }
</script>

<template>
  <div>
    <div
      class="super-drag"
      :class="classes"
      @touchmove="onTouchEvent"
      @touchstart="onTouchEvent"
      @touchend="onTouchEvent"
      @touchcancel="onTouchEvent"
      @mousedown="onMousedown"
      v-bind="$attrs"
      v-on="$listeners"
      ref="drag"
    >
      <slot></slot>
    </div>

    <span
      v-if="ghostHTML"
      :style="ghostStyles"
      class="super-drag__ghost"
      v-html="ghostHTML"
    ></span>
  </div>
</template>
