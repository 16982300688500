<script>
  const CARDS = require("../../cards.json");

  export default {
    props: {
      index: {
        type: Number,
        required: true
      },

      selected: {
        type: Boolean,
        default: false
      },

      clickable: {
        type: Boolean,
        default: false
      },

      small: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        color: "",
        number: "",
        symbol: "",
        shading: ""
      };
    },

    computed: {
      d() {
        return {
          diamond: "M25 0 L50 50 L25 100 L0 50 Z",
          squiggle: "M38.4,63.4c0,16.1,11,19.9,10.6,28.3c-0.5,9.2-21.1,12.2-33.4,3.8s-15.8-21.2-9.3-38c3.7-7.5,4.9-14,4.8-20 c0-16.1-11-19.9-10.6-28.3C1,0.1,21.6-3,33.9,5.5s15.8,21.2,9.3,38C40.4,50.6,38.5,57.4,38.4,63.4z",
          oval: "M25,99.5C14.2,99.5,5.5,90.8,5.5,80V20C5.5,9.2,14.2,0.5,25,0.5S44.5,9.2,44.5,20v60 C44.5,90.8,35.8,99.5,25,99.5z",
        }[this.symbol];
      },

      stroke() {
        return {
          red: '#e74c3c',
          green: '#27ae60',
          purple: '#8e44ad'
        }[this.color];
      },

      fill() {
        if (this.shading == "striped") {
          return `url(#striped-${this.color})`;
        }
        if (this.shading == "solid") {
          return this.stroke;
        }
        return "none";
      },
    },

    beforeMount() {
      let card = CARDS[this.index];

      Object.keys(this.$data)
        .filter(k => card.hasOwnProperty(k))
        .forEach(k => {
          this.$set(this, k, card[k]);
        })
    }
  }
</script>

<template>
  <div
    class="card"
    :class="{
      'card--selected' : selected,
      'card--clickable' : clickable,
      'card--small' : small,
    }"
    v-on:click="$emit('toggle')"
  >
    <svg viewBox="-2 -2 54 104" v-for="(c, i) in number" :key="i">
      <path :d="d" :stroke="stroke" :fill="fill"></path>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
  .card {
    width: 100%;
    max-width: 250px;
    background: #000;
    padding: 20px 10px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
  }

  .card--small {
    max-width: 130px;
    padding: 15px 5px;
  }
    .card--small svg {
      height: 30px;
    }

  .card--clickable {
    cursor: pointer;
  }

  .card--selected {
    background: #ccc;
    box-shadow: 2px 2px 3px 2px #000;
  }

  svg {
    height: 50px;
    margin: 0 1px;
  }

  path {
    stroke-width: 5px;
  }
</style>
