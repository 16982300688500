<script>
  export default {
    props: {
      correctPosition: {
        type: Number,
      },

      incorrectPosition: {
        type: Number,
      },
    },

    computed: {
      classes() {
        let classes = [];

        if (this.correctPosition == undefined) {
          classes.push("key-pegs--invalid");
        } else if (this.correctPosition == 4) {
          classes.push("blue-grey lighten-3");
        } else {
          classes.push("blue-grey darken-2");
        }

        return classes;
      }
    },
  }
</script>

<template>
  <div
    class="key-pegs"
    :class="classes"
  >
    <div
      class="key-peg key-peg--black"
      v-for="i in correctPosition"
      :key="'c' + i"
    ></div>

    <div
      class="key-peg key-peg--white"
      v-for="i in incorrectPosition"
      :key="'i' + i"
    ></div>
  </div>
</template>

<style scoped>
  .key-pegs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px;
    padding: 6px;

    height: 100%;
    border-radius: 2px;
    aspect-ratio: 1;
  }

    .key-pegs--invalid {
      border: 1px solid #333;;
    }

  .key-peg {
    aspect-ratio: 1;
    border-radius: 40%;
  }

  .key-peg--black {
    background: #000;
  }

  .key-peg--white {
    background: #fff;
  }
</style>
