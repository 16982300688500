<script>
  export default {
    name: "Wordle",

    components: {
      guess: require("./guess").default,
      keyboard: require("./keyboard").default,
      help: require("./help").default,
      stats: require("./stats").default,
      settings: require("./settings").default,
    },

    data() {
      return {
        guess: "",
        word: "",
        guesses: [],

        errorMessage: "",
        loading: false,
      };
    },

    computed: {
      formattedGuess() {
        let result = this.guess
          .split('')
          .map((l, index) => {
            let positionCorrect = Boolean(this.formattedGuesses.find(g => {
              return g[index].letter == l && g[index].positionCorrect == true;
            }));

            let letterCorrect = Boolean(this.formattedGuesses.find(g => {
              return g[index].letter == l && g[index].letterCorrect == true;
            }));

            return {
              letter: l,
              positionCorrect: positionCorrect,
              letterCorrect: letterCorrect,
              letterIncorrect: this.incorrectLetters.indexOf(l) > -1,
            };
          });

        while (result.length < this.word.length) {
          let positionCorrect = Boolean(this.formattedGuesses.find(g => {
            return g[result.length].positionCorrect == true;
          }));

          result.push({
            letter: "",
            positionCorrect: positionCorrect,
          });
        }

        return result;
      },

      incorrectLetters() {
        let letters = {};

        this.formattedGuesses.forEach(g => {
          g
            .filter(l => l.letterIncorrect)
            .map(l => l.letter)
            .forEach(l => {
              letters[l] = true;
            });
        });

        return Object.keys(letters);
      },

      formattedGuesses() {
        return this.guesses.map(g => {
          let result = g.split("")
            .map(l => {
              return {
                letter: l,
              };
            })
            .map((l, i) => {
              return {
                ...l,
                positionCorrect: this.word[i] == l.letter,
                letterIncorrect: this.word.indexOf(l.letter) == -1
              };
            })

          let wordLetters = this.word.split("");

          result.forEach((l, i) => {
            if (l.positionCorrect) {
              wordLetters[i] = "";
            }
          });

          return result
            .map(l => {
              let letterCorrect = l.positionCorrect;

              if (!letterCorrect) {
                let index = wordLetters.indexOf(l.letter);

                if (index > -1) {
                  letterCorrect = true;

                  wordLetters[index] = "";
                }
              }

              return {
                ...l,
                letterCorrect: letterCorrect,
              };
            });
        });
      },

      keyColors() {
        let result = {};

        this.formattedGuesses.flat().forEach(l => {
          result[l.letter] = result[l.letter] || "error";

          if (l.letterCorrect) {
            result[l.letter] = "warning";
          }
        });

        this.formattedGuesses.flat().forEach(l => {
          if (l.positionCorrect) {
            result[l.letter] = "success";
          }
        });

        return result;
      },

      won() {
        return this.guesses.indexOf(this.word) > -1;
      },
    },

    watch: {
      word() {
        this.saveCurrentState();
      },

      guess() {
        this.errorMessage = "";
      },

      guesses() {
        this.saveCurrentState();
      },

      won() {
        if (this.won) {
          if (this.$store.state.wordle.history.slice(-1)[0]?.word == this.word) {
            return;
          }

          this.$store.commit("wordle/addToHistory", {
            word: this.word,
            guesses: this.guesses,
            time: new Date().getTime(),
          });
        }
      },

      "$store.state.wordle.history"() {
        window.localStorage.setItem("wordle:history", JSON.stringify(this.$store.state.wordle.history));
      }
    },

    methods: {
      reset() {
        let wordPool = this.$store.getters["wordle/unusedEligibleWords"];

        this.guesses = [];
        this.guess = "";

        if (wordPool.length == 0) {
          this.errorMessage = "You guessed all the words!";
        } else {
          this.word = wordPool[Math.floor(Math.random() * wordPool.length)];
        }
      },

      hardReset() {
        window.localStorage.removeItem("wordle:current");
        window.localStorage.removeItem("wordle:history");

        this.$store.commit("wordle/clearHistory");

        this.reset();
      },

      load() {
        return fetch(`/wordle/words`)
          .then(r => {
            return r.json();
          })
          .then(r => {
            let words = r.allWords.map(w => {
              return {
                word: w,
                eligible: r.wordleWords.indexOf(w) > -1
              };
            });

            this.$store.commit("wordle/setWords", words);
          });
      },

      defineWord() {
        window.open(`https://www.google.com/search?q=define+${this.word.toLowerCase()}`, "_blank");
      },

      loadHistory() {
        this.$store.commit("wordle/setHistory", JSON.parse(window.localStorage.getItem("wordle:history")) || []);
      },

      loadSavedState() {
        let state = JSON.parse(window.localStorage.getItem("wordle:current"));

        if (!state.word || !state.guesses) {
          throw("error loading current state");
        }

        this.word = state.word;
        this.guesses = state.guesses;
      },

      saveCurrentState() {
        window.localStorage.setItem("wordle:current", JSON.stringify({
          word: this.word,
          guesses: this.guesses
        }));
      },

      onKeydown(key) {
        if (this.won) {
          if (key.toUpperCase() == "P") {
            this.reset();
          } else if (key.toUpperCase() == "D") {
            this.defineWord();
          }

          return;
        }

        if (key == "Backspace") {
          this.guess = this.guess.substr(0, this.guess.length - 1);
          return;
        }

        if (
          key == "Enter"
          && this.guess.length == this.word.length
        ) {
          if (this.$store.state.wordle.words.map(w => w.word).indexOf(this.guess) == -1) {
            this.errorMessage = `"${this.guess}" is not a word`;
            return;
          }

          if (this.guesses.indexOf(this.guess) > -1) {
            this.errorMessage = `"${this.guess}" has already been guessed`;
            return;
          }

          this.guesses = this.guesses.concat(this.guess);
          this.guess = "";
          return;
        }

        if (this.guess.length == this.word.length) {
          return;
        }

        key = key.toUpperCase().replace(/[^A-Z]/g, "");

        if (key.length != 1) {
          return;
        }

        this.guess += key;
      },

    },

    beforeMount() {
      this.loading = true;

      this.load()
        .then(this.loadHistory)
        .then(this.loadSavedState)
        .catch(this.reset)
        .finally(() => {
          this.loading = false;
        });
    },
  }
</script>

<template>
  <div>
    <v-container
      class="pt-5"
      style="padding-bottom: 250px;"
    >

      <v-fade-transition mode="out-in">
        <v-row :key="loading">
          <template v-if="loading">
            <v-col cols="4" offset="4">
              <v-progress-linear
                indeterminate
                rounded
                striped
                color="primary"
                height="100"
              ></v-progress-linear>
            </v-col>
          </template>

          <template v-else>
            <v-col
              cols="8" offset="2"
              md="6" offset-md="3"
              lg="4" offset-lg="4"
            >
              <template v-if="won">
                <div
                  class="justify-center"
                  style="display: grid; grid-gap: 12px;"
                >
                  <div class="text-h3">
                    You win!
                  </div>

                  <div class="grey--text text-center">
                    Guesses: {{ guesses.length }}
                  </div>

                  <v-btn
                    color="primary"
                    @click="reset"
                  >
                    [P]lay again
                  </v-btn>

                  <v-btn
                    color="secondary"
                    @click="defineWord"
                  >
                    [D]efine {{ word }}
                  </v-btn>
                </div>
              </template>

              <template v-else>
                <guess
                  :value="formattedGuess"
                ></guess>
              </template>

              <template v-if="guesses.length > 0">
                <v-divider class="my-3"></v-divider>
              </template>

              <template v-for="g in formattedGuesses.map(g => g).reverse()">
                <guess
                  :value="g"
                  :key="g.map(d => d.letter).join('')"
                  class="my-4"
                  history
                ></guess>
              </template>
            </v-col>

            <v-col cols="1">
              <help></help>

              <settings
                @reset="hardReset"
              ></settings>

              <stats></stats>
            </v-col>
          </template>
        </v-row>
      </v-fade-transition>
    </v-container>

    <v-snackbar
      color="primary"
      :value="errorMessage.length > 0"
      @input="errorMessage = ''"
      :timeout="-1"
      content-class="text-h6"
      app
      centered
    >
      {{ errorMessage }}
    </v-snackbar>

    <v-footer
      color="grey darken-4"
      fixed
      width="100%"
    >
      <keyboard
        @key:down="onKeydown"
        :key-colors="keyColors"
        class="py-5"
        style="width: 100%;"
        ref="keyboard"
      ></keyboard>
    </v-footer>
  </div>
</template>
