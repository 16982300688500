<script>
  import User from "./User";
  import UserCard from "./UserCard";
  import Table from "./Table";
  import GameOver from "./GameOver";
  import Countdown from "./Countdown";
  import VueQrcode from 'vue-qrcode';
  import Chat from './Chat';
  import HowToPlay from './HowToPlay';
  import Debug from './Debug';
  import CopyButton from '../CopyButton';

  export default {
    components: {
      user: User,
      "user-card": UserCard,
      tbl: Table,
      "game-over": GameOver,
      qr: VueQrcode,
      countdown: Countdown,
      chat: Chat,
      "how-to-play": HowToPlay,
      debug: Debug,
      "copy-button": CopyButton,
    },

    data() {
      return {
        code: "",
        started: false,
        completed: false,
        locked: false,
        cards: [],
        table: [],
        users: [],
        selectedTableIndeces: [],
        deckLength: "",
        friendlyStatus: "",
        friendlyStatusSuccess: false,
        setsAvailable: "",
        serverTime: "",

        usernameCorrect: "",
        usernameIncorrect: "",

        playersExpanded: false,
        starting: false,
      };
    },

    computed: {
      lockedByUser() {
        return this.users.find(u => Boolean(u.lockedUntil));
      },

      lockedBySomeoneElse() {
        return this.locked
          && Boolean(this.lockedByUser)
          && (this.lockedByUser || {})._id != this.$store.state.me._id;
      },

      lockedByMe() {
        let lockingUser = this.lockedByUser || {};

        if (lockingUser._id == this.$store.state.me._id) {
          return true;
        }

        return false;
      },

      lockedUntil() {
        return (this.lockedByUser || {}).lockedUntil;
      },

      gameLink() {
        return window.location.href;
      },

      appBarColor() {
        if (this.lockedByMe) {
          return "cyan darken-4";
        }

        if (this.started && this.locked) {
          if (this.friendlyStatusSuccess) {
            return "success";
          }
          return "error darken-3";
        }

        return "blue-grey";
      }
    },

    watch: {
      started: {
        handler() {
          this.playersExpanded = !this.started;
        },
        immediate: true
      },
    },

    methods: {
      update(data) {
        if (this.code && this.code != data.code) {
          console.warn("received update from another game"); // TODO: let's try to make this never happen
        }

        Object.keys(data)
          .filter(k => this.$data.hasOwnProperty(k))
          .filter(k => JSON.stringify(data[k]) != JSON.stringify(this.$data[k]))
          .forEach(k => {
            this.$set(this, k, data[k]);
          });
      },

      start() {
        this.starting = true;

        this.$store.state.socket.emit("set:start", {
          code: this.code
        });
      },

      updateSelectedIndeces(indeces) {
        if (indeces.length == 0) {
          return;
        }
        this.$store.state.socket.emit("set:select", {
          code: this.code,
          indeces: indeces
        });
      },

      onUserCorrect(data) {
        this.usernameCorrect = data.username;
      },

      onUserIncorrect(data) {
        this.usernameIncorrect = data.username;
      },
    },

    beforeMount() {
      this.$store.state.socket.on("set:load", data => {
        data = typeof data == "string" ? JSON.parse(data) : data;
        this.update(data);
      });

      setTimeout(() => {
        this.$store.state.socket.emit("set:join", {
          code: this.$route.query.code
        });
      }, 1000);
    },

    destroyed() {
      this.$store.state.socket.off("set:load");
    },
  };
</script>

<template>
  <div>
    <template v-if="!Boolean(code)">
      <div class="pa-5 text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </template>

    <template v-else>
      <v-app-bar
        :color="appBarColor"
        :extended="started && !completed"
        extension-height="32"
        style="position: sticky; top: 46px; z-index: 3;"
        rounded
      >
        <v-btn icon v-on:click="playersExpanded = !playersExpanded" v-if="!completed">
          <v-icon :key="playersExpanded">
            {{ playersExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>

        <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
          <template v-if="!started">
            Waiting for players to join...
          </template>

          <template v-else-if="completed">
            Game Finished
          </template>

          <template v-else-if="lockedBySomeoneElse">
            <user v-bind="lockedByUser"></user> is selecting cards
          </template>

          <template v-else-if="lockedByMe && this.$refs.table && this.$refs.table.selectedIndeces.length != 3">
            Select {{ 3 - this.$refs.table.selectedIndeces.length }} more cards
          </template>

          <template v-else-if="Boolean(friendlyStatus)">
            {{ friendlyStatus }}
          </template>

          <template v-else-if="locked">
            <v-progress-circular
              indeterminate
              color="grey lighten-2"
            ></v-progress-circular>
          </template>

          <template v-else>
            Select 3 cards that form a set
          </template>
        </div>

        <v-spacer></v-spacer>

        <template v-if="lockedBySomeoneElse || lockedByMe">
          <countdown
            class="mx-2"
            v-bind="{serverTime: serverTime, expirationTime: lockedUntil}"
          ></countdown>
        </template>

        <chat :code="code"></chat>

        <template v-slot:extension v-if="started && !completed">
          <div class="text-caption" style="display: flex; justify-content: space-between; width: 100%;">
            <div>Sets: {{ locked && !Boolean(lockedByUser) ? '-' : setsAvailable }}</div>
            <div>Cards Remaining: {{ deckLength }}</div>
            <div>Game Code: {{ code }}</div>
          </div>
        </template>
      </v-app-bar>

      <v-expand-transition mode="out-in" v-if="!completed">
        <v-sheet v-show="playersExpanded" elevation="2" color="blue-grey darken-3">
          <v-container>
            <v-row>
              <v-col>
                <div class="d-flex justify-space-between">
                  <strong>Players</strong>

                  <how-to-play></how-to-play>
                </div>

                <div class="d-flex flex-wrap">
                  <div v-for="u in users" :key="u._id" class="ma-2">
                    <user-card
                      v-bind="u"
                      :locking="(lockedByUser || {})._id == u._id"
                      v-on:correct="onUserCorrect"
                      v-on:incorrect="onUserIncorrect"
                      ref="userCard"
                    ></user-card>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-expand-transition>

      <v-container v-if="!started">
        <v-row>
          <v-col cols="12" class="text-center">
            <div class="text-h5 mb-4">
              Invite your friends to this game!
            </div>

            <div>
              There are 3 ways to join:
            </div>
          </v-col>
        </v-row>

        <v-row class="px-6">
          <v-col class="text-center">
            <v-card class="pa-5" color="grey darken-3 fill-height">
              <div class="text-h6 mb-1">
                Enter Game Code
              </div>

             <code class="text-h4">{{ code }}</code>
            </v-card>
          </v-col>

          <v-col class="text-center">
            <v-card class="pa-5" color="grey darken-3 fill-height">
              <div class="text-h6">
                Scan QR Code
              </div>

              <div>
                <qr :value="gameLink"></qr>
              </div>
            </v-card>
          </v-col>

          <v-col class="text-center">
            <v-card class="pa-5" color="grey darken-3 fill-height">
              <div class="text-h6">
                Direct link
              </div>

              <code style="display: inline-block; text-overflow: ellipsis; width: 100%; white-space: nowrap; overflow: hidden">{{ gameLink }}</code>

              <copy-button v-model="gameLink" class="my-2"></copy-button>
            </v-card>
          </v-col>
        </v-row>

        <v-divider class="ma-8"></v-divider>

        <v-row>
          <v-col cols="12" class="text-h6 text-center">
            <div>
              When everyone is ready:
            </div>
            <v-btn color="secondary" x-large v-on:click="start" :loading="starting">Start Game</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <template v-else-if="!completed">
        <tbl
          v-bind="{
            cards: table,
            disabled: locked && !lockedByMe,
            lockedSelectedIndeces: locked && !lockedByMe ? selectedTableIndeces : []
          }"
          :disabled="locked && !lockedByMe"
          v-on:update:selected="updateSelectedIndeces"
          ref="table"
        ></tbl>
      </template>

      <template v-if="completed">
        <game-over :users="users"></game-over>
      </template>
    </template>

    <debug :cards="table" v-if="false"></debug>

    <v-snackbar
      :value="Boolean(usernameCorrect)"
      v-on:input="usernameCorrect = ''"
      :timeout="1500"
      :key="'correct' + usernameCorrect"
      content-class="text-center"
      style="opacity: 0.7;"
      light
      centered
    >
      <template v-if="Boolean(usernameCorrect)">
        {{ usernameCorrect }}
        <v-icon color="success">mdi-plus</v-icon>
        <v-icon color="success">mdi-numeric-1-circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar
      :value="Boolean(usernameIncorrect)"
      v-on:input="usernameIncorrect = ''"
      :timeout="1500"
      :key="'incorrect' + usernameCorrect"
      content-class="text-center"
      style="opacity: 0.7;"
      light
      centered
    >
      <template v-if="Boolean(usernameIncorrect)">
        {{ usernameIncorrect }}
        <v-icon color="error">mdi-minus</v-icon>
        <v-icon color="error">mdi-numeric-1-circle</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<style>
  .v-snack__wrapper {
    margin-bottom: 50px;
  }
</style>
