import { render, staticRenderFns } from "./cell.vue?vue&type=template&id=249ab13a&scoped=true&"
import script from "./cell.vue?vue&type=script&lang=js&"
export * from "./cell.vue?vue&type=script&lang=js&"
import style0 from "./cell.vue?vue&type=style&index=0&id=249ab13a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249ab13a",
  null
  
)

export default component.exports